import parse from 'date-fns/parse';
import { DateTime } from 'luxon';
import { create } from 'zustand';

import DebugConsole from '@helper/functions/console';
import {
  getCashierTerminalExceptions,
  putCashierTerminalExceptions,
} from '@helper/service/invoice';

export interface ExceptionData {
  PK: string;
  SK: string;
  amount: string;
  cashierFullName: string;
  errorCode?: string;
  errorMessage?: string;
  terminalComments?: string;
  terminalID?: string;
  terminalReceiptDateTimeFrom?: string;
  terminalReceiptDateTimeTo?: string;
  terminalSequence?: string;
  isBulk?: boolean;
}
export interface CashierExceptionStore {
  isLoading: boolean;
  data: ExceptionData[];
  getExceptions: () => Promise<void>;
  updateException: (data: unknown) => Promise<void>;
  sortBy: string;
  sortOrder: 'ASC' | 'DESC';
  setSortBy: (colName: string) => void;
  setSortOrder: (order: 'ASC' | 'DESC') => void;
  filters: Partial<{
    fromDate: string;
    toDate: string;
    cashier: string;
    terminal: string;
    amountFrom: string;
    amountTo: string;
    isBulk: boolean;
    bulk?: boolean;
  }>;
  applyFilter: (values: CashierExceptionStore['filters']) => void;
  filteredItems: ExceptionData[];
}

export const useCashierExceptionStore = create<CashierExceptionStore>((set, get) => ({
  isLoading: false,
  data: [],
  getExceptions: async () => {
    set({ isLoading: true });
    let response;
    const { data } = get();
    if (data.length === 0) {
      response = await getCashierTerminalExceptions();
    }
    if (response) {
      set({ data: response });
      get().applyFilter(null);
    }
    set({ isLoading: false });
  },
  updateException: async (data) => {
    set({ isLoading: true });
    if (typeof data === 'object' && data !== null && 'terminalComments' in data) {
      const typedData = data as { terminalComments?: string }; 
      if (typedData.terminalComments) {
          typedData.terminalComments = typedData.terminalComments.replace(/,/g, ' ');
      }
  }

    console.log(data);
    await putCashierTerminalExceptions(data);
    const response = await getCashierTerminalExceptions();
    if (response) {
      set({ data: response });
      useCashierExceptionTableStore.setState({ activeEditRowPk: '' });
      get().applyFilter(null);
    }
    set({ isLoading: false });
  },
  sortBy: 'terminalReceiptDateTimeFrom',
  sortOrder: 'ASC',
  setSortBy: (colName) => {
    if (!colName) return;

    set({
      sortBy: colName,
    });
  },
  setSortOrder: (order) => {
    if (!order) return;

    set({
      sortOrder: order,
    });
  },
  filters: {},
  filteredItems: [],
  applyFilter: (filterConfig) => {
    const { data: items, filters: oldFilter, sortBy, sortOrder } = get();

    if (['terminalReceiptDateTimeFrom', 'terminalReceiptDateTimeTo'].includes(sortBy)) {
      if (sortOrder === 'DESC') {
        items.sort((a, b) => -compareDateString(a[sortBy], b[sortBy]));
      } else {
        items.sort((a, b) => compareDateString(a[sortBy], b[sortBy]));
      }
    }

    let filterData = filterConfig;

    if (!filterConfig) {
      filterData = oldFilter;
    } else {
      set({ filters: filterConfig });
    }

    if (!filterData) {
      set({ filteredItems: items });
      return;
    }

    DebugConsole.debug('Appling filter:', filterData);

    const fromDate = filterData.fromDate ? createDateFromFilterString(filterData.fromDate) : null;
    const toDate = filterData.toDate ? createDateFromFilterString(filterData.toDate) : null;

    const filteredItems = items.filter((item) => {
      const dateFrom = item.terminalReceiptDateTimeFrom
        ? createDateFromString(item.terminalReceiptDateTimeFrom)
        : null;
      const dateTo = item.terminalReceiptDateTimeTo
        ? createDateFromString(item.terminalReceiptDateTimeTo)
        : null;

      const isDateApplied =
        (!fromDate || (dateFrom && dateFrom >= fromDate)) &&
        (!toDate || (dateTo && dateTo <= toDate));

      const amount = parseFloat(item.amount ?? '0');
      const amountFrom = filterData.amountFrom ? parseFloat(filterData.amountFrom) : null;
      const amountTo = filterData.amountTo ? parseFloat(filterData.amountTo) : null;

      const isAmountApplied =
        (!amountFrom || amount >= amountFrom) && (!amountTo || amount <= amountTo);

      const isBulkApplied =
        filterData.isBulk !== undefined ? item.isBulk === filterData.isBulk : true;
      const isCashierApplied = filterData.cashier
        ? item.cashierFullName.toLowerCase() === filterData.cashier.toLowerCase()
        : true;
      const isTerminalApplied = filterData.terminal
        ? item.terminalID.toLowerCase() === filterData.terminal.toLowerCase()
        : true;

      return (
        isDateApplied && isAmountApplied && isBulkApplied && isCashierApplied && isTerminalApplied
      );
    });

    set({ filteredItems });
  },
}));

interface CashierExceptionTableStore {
  activeEditRowPk?: string;
  setActiveEditRowPk: (pk: string) => void;
}

export const useCashierExceptionTableStore = create<CashierExceptionTableStore>((set, get) => ({
  activeEditRowPk: '',
  setActiveEditRowPk: (pk) =>
    set((state) => ({
      activeEditRowPk: state.activeEditRowPk === pk ? null : pk,
    })),
}));

function createDateFromString(dateString: string): Date {
  const date = parse(dateString, 'dd-MM-yyyy HH:mm:ss', new Date());
  return date;
}

function createDateFromFilterString(dateString: string): Date {
  const date = parse(dateString, 'dd-MM-yyyy', new Date());
  return date;
}

function compareDateString(x, y) {
  if (!x && !y) return 0;
  if (!x) return -1;
  if (!y) return 1;

  const dateTimeFormat = 'dd-MM-yyyy HH:mm:ss';
  const a = DateTime.fromFormat(x, dateTimeFormat);
  const b = DateTime.fromFormat(y, dateTimeFormat);

  if (!a.isValid && !b.isValid) return 0;
  if (!a.isValid) return -1;
  if (!b.isValid) return 1;

  return a < b ? -1 : a > b ? 1 : 0;
}
