import clsx from 'clsx';

import Tooltip from '@components/Tooltip/PopperTooltip';

type InputLabelProps = {
  name?: string;
  formRowName?: string;
  label: string | React.ReactNode;
  smallLabel?: boolean;
  className?: string;
  children?: any;
  required?: boolean;
  hoverHint?: any;
  hoverHintMode?: 'hover' | 'click';
  style?: any;
};

export default function InputLabel({
  name = '',
  formRowName = '',
  label,
  smallLabel = false,
  className = '',
  children = undefined,
  required = false,
  hoverHint = null,
  hoverHintMode = 'hover',
  style = {},
}: InputLabelProps) {
  return (
    <div
      className={clsx({
        'nsw-form__label tw-flex group-[.dynamic-fields]:[&:has(+_input)]:tw-mb-1': 1,
      })}
    >
      <label
        className={clsx({
          'nsw-form__label': 1,
          'nsw-form__label--small': smallLabel,
          'nsw-form__required': label && required,
          'tw-flex': children,
          'tw-sr-only': formRowName,
          [className]: 1,
        })}
        htmlFor={name || null}
        id={name ? `${name}-label` : null}
        style={style}
      >
        {formRowName && `${formRowName} `}
        {label}
        {children && <span>{children}</span>}
      </label>
      {hoverHint && (
        <Tooltip buttonClassName="tw-ml-2" mode={hoverHintMode}>
          {hoverHint}
        </Tooltip>
      )}
    </div>
  );
}
