import clsx from 'clsx';
import { cloneDeep as _cloneDeep } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { Controller, FieldValues, Validate } from 'react-hook-form';

import InputHelper from './InputHelper';
import InputLabel from './InputLabel';

// import DebugConsole from '@helper/functions/console';

export default function FormTextInput(props) {
  const {
    name,
    formRowName = '',
    label = '',
    helper = '',
    hoverHint = null,
    hoverHintMode = 'hover',
    smallLabel = false,
    formGroupClassName = '',
    helperBelowInput = false,
    rules = {},
  } = props;

  return (
    <div
      className={clsx({
        'nsw-form__group tw-w-full': 1,
        'group-[.dynamic-fields]:tw-h-full group-[.dynamic-fields]:tw-flex group-[.dynamic-fields]:tw-flex-col': 1,
        [formGroupClassName]: formGroupClassName,
      })}
    >
      <InputLabel
        name={name}
        formRowName={formRowName}
        label={label}
        smallLabel={smallLabel}
        required={rules?.required}
        hoverHint={hoverHint}
        hoverHintMode={hoverHintMode}
      />
      {!helperBelowInput && <InputHelper name={name} formRowName={formRowName} helper={helper} />}
      <TextInputComponent {...props} />
    </div>
  );
}

type RuleProps<T> =
  | T
  | {
      value: T;
      message: string;
    };

type TextInputProps = {
  rules: {
    required?: boolean;
    minLength?: RuleProps<number>;
    maxLength?: RuleProps<number>;
    pattern?: {
      value: RegExp;
      message: string;
    };
    allowedCharSet?: string;
    allowedCharSets?: string[];
    validate?: Validate<any, FieldValues> | Record<string, Validate<any, FieldValues>>;
  };
} & Record<string, any>;

function TextInputComponent({
  hookForm,
  name,
  type = 'text',
  label = '',
  placeholder = '',
  helper = '',
  defaultValue = '',
  rules = {},
  smallLabel = false,
  errorMessageHandler = undefined,
  helperBelowInput = false,
  disabled = false,
  validateOnBlur = false,
  textFieldClassName = '',
  errorClassName = '',
  formGroupClassName = '',
  onChange: customOnChange = (e) => {},
  onBlur: customOnBlur = () => {},
  hideError = false,
  autoComplete = '',
  ...others
}: TextInputProps) {
  const [autocompleteToken] = useState(Date.now().toString());

  const { control, trigger, clearErrors } = hookForm;

  const getRegExpPattern = useCallback((charSet: string) => {
    switch (charSet) {
      case 'all':
        return {
          value: /.*/g,
          message: 'Field data is invalid',
        };
      case 'alpha':
        return {
          value: /^[A-Za-z ]+$/g,
          message: 'Field data is invalid. Allowed characters: A-Z, a-z, Space',
        };
      case 'numeric':
        return {
          value: /^[0-9]+$/g,
          message: 'Field data is invalid. Allowed characters: 0-9',
        };
      case 'alphanumeric':
        return {
          value: /^[A-Za-z0-9 ]+$/g,
          message: 'Field data is invalid. Allowed characters: A-Z, a-z, 0-9, Space',
        };
      case 'firstLastName':
        return {
          value: /^[^\d,]+$/g,
          message: 'Field data is invalid',
        };
      case 'csvSafe':
        return {
          value: /^[^,]*$/g,
          message: 'Comma (,) is not allowed',
        };
      default:
        return {
          value: /.*/g,
          message: 'Field data is invalid',
        };
    }
  }, []);

  const validationPatterns = useMemo<{ value: RegExp; message: string }[]>(() => {
    const patterns = [];

    if (rules.allowedCharSet) {
      patterns.push(getRegExpPattern(rules.allowedCharSet));
    }

    if (rules.allowedCharSets) {
      rules.allowedCharSets.forEach((charset) => {
        patterns.push(getRegExpPattern(charset));
      });
    }

    if (rules.pattern) {
      patterns.push(rules.pattern);
    }

    return patterns;
  }, [rules.pattern, rules.allowedCharSet, rules.allowedCharSets, getRegExpPattern]);

  const validations = useMemo<Record<string, Validate<any, FieldValues>>>(() => {
    let validateAll = {
      charsetsValidation: (v) => {
        if (!rules?.required && (v === undefined || v === null || v === '')) return true;

        for (let i = 0; i < validationPatterns.length; i++) {
          const pattern = validationPatterns[i];

          const pass = new RegExp(pattern.value).test(v);
          if (!pass) {
            return pattern.message;
          }
        }

        return true;
      },
    };

    if (rules?.validate) {
      if (typeof rules.validate === 'function') {
        validateAll['_'] = rules.validate;
      } else if (typeof rules.validate === 'object') {
        validateAll = Object.assign({}, rules.validate, validateAll);
      }
    }

    return validateAll;
  }, [rules?.validate, rules?.required, validationPatterns]);

  const remappedRules = useMemo(() => {
    if (!rules) return {};

    const rulesCopy = _cloneDeep(rules);

    delete rulesCopy.pattern;
    rulesCopy.validate = validations;

    if (rules.minLength !== undefined) {
      const lengthStr =
        typeof rules.minLength === 'string' || typeof rules.minLength === 'number'
          ? rules.minLength.toString()
          : rules.minLength.value.toString();
      rulesCopy.minLength = {
        value: parseInt(lengthStr),
        message:
          typeof rules.minLength === 'object' && rules.minLength.message
            ? rules.minLength.message
            : `Minimum length is ${lengthStr} characters`,
      };
    }

    if (rules.maxLength !== undefined) {
      const lengthStr =
        typeof rules.maxLength === 'string' || typeof rules.maxLength === 'number'
          ? rules.maxLength.toString()
          : rules.maxLength.value.toString();
      rulesCopy.maxLength = {
        value: parseInt(lengthStr),
        message:
          typeof rules.maxLength === 'object' && rules.maxLength.message
            ? rules.maxLength.message
            : `Maximum length is ${lengthStr} characters`,
      };
    }

    return rulesCopy;
  }, [rules, validations]);

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        ...remappedRules,
      }}
      defaultValue={defaultValue}
      {...others}
      render={({ field, fieldState: { invalid, error, isDirty } }) => {
        return (
          <>
            <input
              {...field}
              id={field.name}
              className={clsx({
                'nsw-form__input group-[.dynamic-fields]:tw-mt-auto': 1,
                [textFieldClassName]: textFieldClassName,
              })}
              type={type}
              autoComplete={autoComplete || `${name}-${autocompleteToken}`}
              placeholder={placeholder}
              aria-invalid={invalid}
              aria-describedby={helper ? `${field.name}-helper-text` : null}
              disabled={disabled}
              onChange={(e) => {
                if (invalid && isDirty) {
                  clearErrors(field.name);
                }
                field.onChange(e);
                if (customOnChange) customOnChange(e);
              }}
              onBlur={() => {
                field.onBlur();
                if (validateOnBlur) trigger(field.name);
                if (customOnBlur) customOnBlur();
              }}
            />
            {helperBelowInput && <InputHelper name={field.name} helper={helper} className="mt-2" />}
            {!hideError && error && (
              <span
                className={clsx({
                  'nsw-form__helper nsw-form__helper--error': 1,
                  [errorClassName]: errorClassName,
                })}
                id={`${field.name}-error-text`}
              >
                <i className="material-icons nsw-material-icons" tabIndex={-1} aria-hidden="true">
                  cancel
                </i>
                {errorMessageHandler
                  ? errorMessageHandler({
                      target: {
                        value: field.value,
                        error: error.message,
                      },
                    })
                  : error.message ||
                    (field.value ? 'Field data is invalid' : 'This field is required')}
              </span>
            )}
          </>
        );
      }}
    />
  );
}
