import { uniqBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { FilterContainer, FilterItem, ResetButton, SearchButton } from '@components/Filter';
import FormSelectSearchable from '@components/Form/FormSelectSearchable';
import FormTextInput from '@components/Form/FormTextInput';

import { compareBy, matchStrings } from '@helper/functions/utility';

import { useInternationalAccessStore } from '@store/admin/international-access';

const defaultFilters = {
  healthEntity: '',
  hospital: '',
  service: '',
};

export default function SearchFilters(props) {
  const hookForm = useForm({
    defaultValues: defaultFilters,
  });

  const { reset, handleSubmit } = hookForm;


  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const resetFilters = () => {
    reset();
    store.resetFilter();
  };

  const store = useInternationalAccessStore((s) => s);

  const submitFilter = (params) => {
    console.log(params);
    store.searchRecords(params.healthEntity, params.hospital, params.service)
  };

  return (
    <>
      <FilterContainer mobileOpen={isFilterOpen}>
        <div className="row">
          <div className="col col-12">
            <FilterItem>
              <FormTextInput
                hookForm={hookForm}
                name="healthEntity"
                label="Health Entity Search"
                placeholder="Form name"
              />
            </FilterItem>
            <FilterItem>
              <FormTextInput
                hookForm={hookForm}
                name="hospital"
                label="Hospital Search"
                placeholder="Form name"
              />
            </FilterItem>
            <FilterItem>
              <FormTextInput
                hookForm={hookForm}
                name="service"
                label="Service Search"
                placeholder="Form name"
              />
            </FilterItem>
          </div>
        </div>

        <div className="row">
          <div className="col col-12">
            <SearchButton handleSearch={handleSubmit(submitFilter)} />
            <ResetButton
              handleReset={(e) => {
                resetFilters();
              }}
            />
          </div>
        </div>
      </FilterContainer>
    </>
  );
}
