import styled from "@emotion/styled";

export const WelcomeContainer = styled("div")(
  ({ theme }) => `

  .hero-section {
    width: 100%;
    position: relative;
    color: ${theme.colors.white1};

    .content-card {
      padding: 2.5rem 2rem;
      background-color: var(--nsw-brand-dark);
      position: absolute;
      bottom: 10%;
      left: 10%;

      p {
        font-weight: 500;
        font-size: 1.4rem;
        h1 { font-weight: 700; margin: 0; font-size: 1.65rem };
      }
    }

    .img-container {
      overflow: hidden;
    }
    img {
      width: 100%;
      transform: scale(1.1);
      aspect-ratio: 1600/400;
    }
  }

  section.main-content {
    padding: 2.25rem 0 4rem 4rem;
    max-width: 1250px;
    margin-inline: auto;

    .service-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 3rem;
    }

    .service-card-item {
      outline: 2px solid var(--nsw-brand-dark);
      width: 80%;

      .content {
        padding: 0.5rem 1rem 1rem 1rem;
        color: var(--nsw-brand-dark);
      }

      .img-container {
        overflow: hidden;
      }
      img {
        width: 100%;
        aspect-ratio: 16/9;
        object-fit: cover;
        height: 150px;
      }

      h3.title {
        font-size: 1rem;
        margin-top: 0.2rem;
        margin-bottom: 0.4rem;
      }
      p.description {
        font-size: 0.9rem;
        margin-bottom: 1rem;
      }

      .nsw-button.nsw-button--primary {
        padding: 0.425rem 1rem;
        margin: 0 auto;
        font-size: 0.8rem !important;
      }
    }
  }

  h2.section-title {
    color:  var(--nsw-brand-dark);
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    
    &.spacing-top {
      margin-top: 4rem;
    }
  }

  @media only screen and (max-width: ${theme.screens.lg}) {
    section.main-content {
      padding: 2.25rem 1rem 4rem 1rem;

      .service-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
      }
    }

  }


  @media only screen and (max-width: ${theme.screens.md}) {
    section.main-content {
      .service-card-item {
        width: 100%;
        .content {
          padding: 0.45rem 1rem 1rem 1rem;
        }

        img {
          height: 150px;
        }

        h3.title {
          font-size: 1rem;
          margin-top: 0.25rem;
          margin-bottom: 0.5rem;
        }
        p.description {
          font-size: 0.9rem;
        }

        .nsw-button.nsw-button--primary {
          padding: 0.25rem 1.5rem !important;
          font-size: 0.85rem !important;
        }
      }
    }

  }

  @media only screen and (max-width: ${theme.screens.sm}) {
    .hero-section {
      .content-card {
        padding: 1.75rem;
        position: static;
        width: 100%;
  
        p {
          font-size: 1rem;
          h1 { 
            font-size: 1.15rem 
          };
        }
      }
  
      img {
        transform: scale(1.15);
      }
    }

    section.main-content {
      .service-grid {
        grid-template-columns: 1fr;
        gap: 1.75rem;
      }
    }
  }
  `
);
