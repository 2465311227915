import { WelcomeContainer } from './styles';
import FormSelectSearchable from '@components/Form/FormSelectSearchable';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useInternationalAccessStore } from '@src/store/admin/international-access';
import SortedTable from './components/SortedTable';
import { ComponentLoading } from '@components/Loading';
import SearchFilters from './components/SearchFilters';
import Pagination from './components/Pagination';

const defaultSearch = {
  paymentType: ""
};

export const tableHeaders= [
  {
    key: 'healthEntityCode',
    name: 'Health Entity',
  },
  {
    key: 'healthEntity',
    name: 'Health Organisation',
  },
  {
    key: 'hospital',
    name: 'Hospital/Facility',
  },
  {
    key: 'activityNameDisplay',
    name: 'Service',
  },
];

const InternationalAccess = () => {
  const hookForm = useForm({
    defaultValues: defaultSearch,
  });

  const store = useInternationalAccessStore((s) => s);

  const onchange = async (value, id) => {
    const res = await store.updateInternationalAccess(value, id);
    return res;
  }

  return (
    <WelcomeContainer>
      <section className="main-content">
        <div className="row">
          <div className="col col-12 col-lg-4">
            <FormSelectSearchable
              hookForm={hookForm}
              name="paymentType"
              label="Service"
              getOptions={store.getPaymentTypeOptions}
              onChange={(value)=> store.fetchReceivableActivities(value?.value)}
            />
          </div>
        </div>
        <div className="row">
          {store.isLoading && (
            <div className="tw-flex tw-justify-center tw-items-center tw-w-full tw-h-40">
              <ComponentLoading />
            </div>
          )}
        </div>
        {store.showRecActivities &&
          <>
          <div className="row">
            <div className="col col-12 col-lg-12">
            <Pagination
            totalRecords={store.totalRecords}
            onPageChange={store.setCurrentRecordPage}
            onRecordsPerPageChange={store.setRecordsPerPage}
            currentPage={store.currentRecordPage}
            recordsPerPage={store.recordsPerPage}
          />
            </div>
            
            </div> 
          <div className="row">
            <div className="col col-12 col-lg-2">
              <SearchFilters />
            </div>
            <div className="col col-12 col-lg-10">
              <SortedTable
                title="Health Entities List"
                data={store.paginatedReceivableActivities}
                headers={tableHeaders}
                isLoading={false}
                placeholder={'Sorry, we could not find any available records.'}
                onchange={onchange}
                cellAlignMiddle
              />
            </div>
          </div>
          </>
        }
      </section>
    </WelcomeContainer>
  );
};

export default InternationalAccess;
