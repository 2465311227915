import styled from '@emotion/styled';
import type { Placement } from '@popperjs/core';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MdInfo } from 'react-icons/md';
import { usePopper } from 'react-popper';

const StyledDivArrow = styled.div`
  &,
  &::before {
    position: absolute;
    width: 18px;
    height: 18px;
  }

  & {
    visibility: hidden;
  }

  &::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  [data-popper-placement^='top'] > & {
    bottom: -4px;
    &::before {
      background: linear-gradient(to bottom right, transparent, white, white);
    }
  }

  [data-popper-placement^='bottom'] > & {
    top: -4px;
    &::before {
      background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.3) 10%, white, transparent);
    }
  }

  [data-popper-placement^='left'] > & {
    right: -4px;
    &::before {
      background: linear-gradient(to top right, transparent, white, white);
    }
  }

  [data-popper-placement^='right'] > & {
    left: -4px;
    &::before {
      background: linear-gradient(to top right, white, white, transparent);
    }
  }
`;

export default function Tooltip({
  icon = <MdInfo className="tw-text-lg tw-text-blue1" aria-hidden />,
  iconId = undefined,
  children,
  description = '',
  buttonClassName = '',
  popperClassName = '',
  mode = 'hover',
  placement = 'top',
}) {
  const [iconElement, setIconElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const { styles, attributes, update } = usePopper(iconElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
    ],
    placement: placement as Placement,
  });

  const [isPopperVisible, setIsPopperVisible] = useState(false);

  const isHovering = useRef(false);

  const clickMode = useMemo(() => mode.includes('click'), [mode]);
  const hoverMode = useMemo(() => mode.includes('hover'), [mode]);

  useEffect(() => {
    if (!isPopperVisible || !update) return;
    update();
  }, [isPopperVisible, update]);

  const handleOpenPopper = useCallback(() => {
    setIsPopperVisible(true);
  }, []);

  const handleClosePopper = useCallback(() => {
    setIsPopperVisible(false);
  }, []);

  const handleOnMouseOver = useCallback(() => {
    isHovering.current = true;
    if (hoverMode) return handleOpenPopper();
  }, [handleOpenPopper, hoverMode]);

  const handleOnMouseLeave = useCallback(
    (e) => {
      isHovering.current = false;
      if (hoverMode) {
        setTimeout(() => {
          if (!isHovering.current) return handleClosePopper();
        }, 250);
      }
    },
    [handleClosePopper, hoverMode],
  );

  const handleOnFocus = useCallback(() => {
    if (hoverMode) handleOpenPopper();
  }, [handleOpenPopper, hoverMode]);

  const handleOnBlur = useCallback(
    (e) => {
      if (!iconElement || !popperElement) return;

      if (
        !e.relatedTarget ||
        iconElement.contains(e.relatedTarget) ||
        popperElement.contains(e.relatedTarget)
      )
        return;
      handleClosePopper();
    },
    [handleClosePopper, iconElement, popperElement],
  );

  const handleOnClick = useCallback(() => {
    handleOpenPopper();
  }, [handleOpenPopper]);

  const handleClickEvent = useCallback(
    (e) => {
      if (!iconElement || !popperElement) return;

      if (!isPopperVisible) return;

      if (iconElement.contains(e.target) || popperElement.contains(e.target)) {
        return;
      }

      setIsPopperVisible(false);
    },
    [iconElement, isPopperVisible, popperElement],
  );

  useEffect(() => {
    window.addEventListener('click', handleClickEvent);
    return () => window.removeEventListener('click', handleClickEvent);
  }, [handleClickEvent]);

  const handlePopperOnBlur = useCallback(
    (e) => {
      if (!iconElement || !popperElement) return;

      if (!e.relatedTarget) return handleClosePopper();

      if (iconElement.contains(e.relatedTarget) || popperElement.contains(e.relatedTarget)) {
        return;
      }

      handleClosePopper();
    },
    [handleClosePopper, iconElement, popperElement],
  );

  return (
    <>
      <span
        ref={setIconElement}
        id={iconId}
        role="tooltip"
        tabIndex={-1}
        className={clsx({
          'tw-inline tw-m-0 tw-bg-none tw-bg-transparent tw-border-0': 1,
          [buttonClassName]: buttonClassName,
        })}
        onMouseOver={handleOnMouseOver}
        onMouseLeave={handleOnMouseLeave}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onClick={handleOnClick}
      >
        {icon}
        {hoverMode && !clickMode && <span className="tw-sr-only">{children}</span>}
        {clickMode && <span className="tw-sr-only">Click to open tooltip. {description}</span>}
      </span>

      <div
        ref={setPopperElement}
        style={styles.popper}
        tabIndex={-1}
        className={clsx({
          'tw-p-4 tw-text-base tw-font-normal tw-not-italic tw-bg-white1 tw-shadow-lg tw-shadow-gray-400 tw-rounded tw-outline tw-outline-1 tw-outline-gray-300 tw-transition-opacity': 1,
          'tw-z-[9999]': 1,
          'tw-invisible tw-opacity-0': !isPopperVisible,
          [popperClassName]: popperClassName,
        })}
        onMouseOver={handleOnMouseOver}
        onMouseLeave={handleOnMouseLeave}
        onBlur={handlePopperOnBlur}
        {...attributes.popper}
      >
        {children}

        <StyledDivArrow
          ref={setArrowElement}
          style={styles.arrow}
          className={clsx({
            'tw-transition-opacity': 1,
            '[&::before]:tw-invisible tw-opacity-0': !isPopperVisible,
          })}
          aria-hidden
          data-popper-arrow
        />
      </div>
    </>
  );
}
