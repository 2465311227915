import clsx from 'clsx';

export default function InputHelper({ name, formRowName = '', helper, className = '' }) {
  return helper ? (
    <span
      className={clsx({
        'nsw-form__helper': 1,
        [className]: 1,
      })}
      id={name ? `${name}-helper-text` : null}
    >
      {formRowName && <span className="tw-sr-only">{formRowName}: </span>}
      {helper}
    </span>
  ) : null;
}
