import { useState } from 'react';
import { ComponentLoading } from '@components/Loading';
import {
    StyledLoadingContainer,
  } from './style';

const LoadingContainer = (props) => {
  return (
    <StyledLoadingContainer className="p-4 d-flex justify-content-center align-items-center">
      {props.children}
    </StyledLoadingContainer>
  );
};

function ToggleButton({
    defaultValue,
    onChange,
    id
  }) {
    const [value, setValue] = useState(defaultValue);
    const [loading, setLoading] = useState(false);
  
    const handleChange = async (newValue, id) => {
      setLoading(true);
			try{
				const res = await onChange(newValue, id);
        console.log(res);
				if (res?.message === "Record updated" || res?.message === "Record created") {
					setValue(newValue);
				} else {
					console.error("API update failed", res);
				}

			}catch(error){
				console.error("Error updating value:", error);
			}finally{
				setLoading(false)
			}
      ;
    };
  
    return (
      <>
        {loading ? (
          <LoadingContainer>
            <ComponentLoading />
          </LoadingContainer>
        ) : (
          <div className="nsw-form__group tw-w-full">
            {value ? (
              <button
                type="button"
                className="nsw-button nsw-button--dark"
                onClick={() => handleChange(false, id)}
              >
                Enabled
              </button>
            ) : (
              <button
                type="button"
                className="nsw-button nsw-button--danger"
                onClick={() => handleChange(true, id)}
              >
                Disabled
              </button>
            )}
          </div>
        )}
      </>
    );
  }
  

export default ToggleButton;
