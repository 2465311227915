import styled from '@emotion/styled';
import clsx from 'clsx';
import { debounce as _debounce } from 'lodash';
import { Button } from 'nsw-ds-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';

import DebugConsole from '@helper/functions/console';

import InputHelper from './InputHelper';
import InputLabel from './InputLabel';

const StyledListItem = styled.li`
  padding: 8px 8px !important;
`;

const StyledListButton = styled((props) => <Button as="secondary" {...props} />)`
  text-align: left !important;
  margin: 0 !important;
  width: 100%;
  border: 0;
  border-radius: var(--nsw-border-radius) !important;
  padding: 10px 10px !important;
  justify-content: start !important;
`;

type OptionEntry = {
  text: string;
  value: string;
  description?: string;
  [key: string]: any;
};

type OptionList = Array<OptionEntry>;

type FormSelectSearchableProps = {
  hookForm: UseFormReturn<any>;
  name: string;
  label?: string;
  helper?: string;
  hoverHint?: string | React.ReactNode;
  hoverHintMode?: 'hover' | 'click';
  rules?: Record<string, any>;
  defaultValue?: string;
  getOptions: (e: any) => Promise<any[] | undefined>;
  searchParams?: Record<string, any>;
  onChange?: (e: any) => void;
  disabled?: boolean;
  valueAsText?: boolean;
  smallLabel?: boolean;
  loading?: boolean;
  hideError?: boolean;
  errorClassName?: string;
  containerClassName?: string;
  textFieldClassName?: string;
  listClassName?: string;
  showOptionCategory?: boolean;
  showDescription?:boolean;
  errorMessageHandler?: (e: { target: { value: any; error: any } }) => null | string;
  [key: string]: any;
};

const FormSelectSearchable = ({
  hookForm,
  name,
  label = '',
  helper = '',
  hoverHint = '',
  hoverHintMode = 'hover',
  rules = {},
  defaultValue = '',
  getOptions = async (e) => [],
  searchParams = {},
  onChange = (e) => {},
  disabled = false,
  valueAsText = false,
  smallLabel = false,
  loading = false,
  hideError = false,
  errorClassName = '',
  containerClassName = '',
  textFieldClassName = '',
  listClassName = '',
  showOptionCategory = false,
  showDescription = true,
  errorMessageHandler = undefined,
  ...others
}: FormSelectSearchableProps) => {
  // Abort signal controller to drop async requests after unmount
  const abortControl = useRef(null);
  if (abortControl.current === null) {
    abortControl.current = new AbortController();
  }

  // Element refs
  const inputRef = useRef(null);
  const optionListRef = useRef(null);
  const inputGroupRef = useRef(null);

  // Component states
  // Keep a list of options
  const [options, setOptions] = useState([]);
  // Is predictive list visible
  const [listVisible, setListVisible] = useState(false);
  // Value of search text
  const [searchText, setSearchText] = useState('');
  // Field description
  const [fieldValueDescription, setFieldValueDescription] = useState('');
  // Is fetching options
  const [isFetching, setIsFetching] = useState(true);

  // Has an option be selected
  const isSelected = useRef(false);
  // Is the search text field on focus
  const isInputFocus = useRef(false);
  // Is the field touched by the user
  const isTouched = useRef(false);

  // Form controller and values
  const { control, setValue } = hookForm;

  const formValue: undefined | string = useWatch({ control, name });
  const formDisplayValue = useWatch({ control, name: valueAsText ? name : `${name}Display` });

  // Monitor if the component has been unmounted
  useEffect(
    () => () => {
      abortControl.current.abort();
    },
    [],
  );

  // Update options list asynchronously
  const fetchOptions: (searchText: string) => Promise<OptionList | undefined> = useCallback(
    async (searchText) => {
      if (abortControl.current.signal.aborted) return;

      // DebugConsole.debug(`[${name}] Searching "${searchText}"`);

      setIsFetching(true);

      return new Promise((r: (val: OptionList) => void) =>
        getOptions({ searchText, ...searchParams }).then((res) => {
          if (abortControl.current.signal.aborted || !res) {
            r(undefined);
            return;
          }
          setOptions(res);
          setIsFetching(false);
          r(res);
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getOptions],
  );

  // Fetch options on search text change
  useEffect(() => {
    // Do nothing if not in focus
    if (!isInputFocus.current || searchText === null) return;

    // DebugConsole.debug(`[${name}] Refresh options list with new search text: ${searchText}`);

    fetchOptions(searchText);
  }, [fetchOptions, name, searchText]);

  // Reset selection. If the hook form value is not empty, revert back to the hook form value.
  const resetField = useCallback(() => {
    if (!inputRef) return;

    if (formDisplayValue === undefined) return;

    // DebugConsole.debug(`[${name}] Resetting field to ${formDisplayValue}`);

    inputRef.current.value = formDisplayValue;
    setSearchText(null);

    if (formValue) {
      isSelected.current = true;
      return;
    }

    isSelected.current = false;
  }, [formDisplayValue, formValue]);

  // Update field value when form value has been updated externally
  useEffect(() => {
    if (!inputRef.current) return;
    if (isInputFocus.current) return;

    // DebugConsole.debug(`[${name}] Value updated externally: ${formValue}`);

    // If the new value is empty, reset field state
    if (!formValue) {
      setFieldValueDescription('');
      resetField();
      return;
    }

    // DebugConsole.debug(`[${name}] Validating new value: ${formValue}`);

    // Update options list and update field state
    fetchOptions(formValue).then((res) => {
      if (abortControl.current.signal.aborted) return;

      let hasOption = res?.find((x) => `${x.value}` === formValue);

      if (hasOption) {
        // DebugConsole.debug(`[${name}] found value:`, hasOption);

        inputRef.current.value = valueAsText ? hasOption.value : hasOption.text;
        setSearchText(null);
        setFieldValueDescription(hasOption.description || '');
        isSelected.current = true;
      } else {
        // DebugConsole.debug(`[${name}] no value found`);

        // Reset form value if not found
        inputRef.current.value = '';
        setSearchText(null);
        setValue(name, '');
        if (!valueAsText) setValue(`${name}Display`, '');
        setFieldValueDescription('');
        isSelected.current = false;
      }
    });
  }, [fetchOptions, formValue, name, resetField, setValue, valueAsText]);

  // Handle when the user is leaving the field
  const handleOnLeaveInput = useCallback(
    (e, fieldOnBlur?: (...e: any[]) => void) => {
      // DebugConsole.debug(`[${name}] Handle on leave field`);

      setListVisible(false);
      isInputFocus.current = false;

      if (inputRef.current.value === '') {
        // DebugConsole.debug(`[${name}] Clear input`);

        setSearchText('');
        setValue(name, '', { shouldValidate: true });
        setValue(`${name}Display`, '');

        if (onChange) {
          onChange(undefined);
        }
      } else {
        resetField();
      }

      if (fieldOnBlur) fieldOnBlur();
    },
    [name, onChange, resetField, setValue],
  );

  // Handle when the input field is focused
  const handleInputOnFocus = useCallback(
    (e) => {
      e.preventDefault();

      if (!inputRef.current) return;

      isInputFocus.current = true;
      isTouched.current = true;

      // If a valid value was already selected, show all available search results
      if (isSelected.current) {
        // DebugConsole.debug(`[${name}] Was selected`);
        setSearchText('');
      } else {
        // DebugConsole.debug(`[${name}] Was not selected`);

        if (inputRef.current.value === null || inputRef.current.value === undefined) {
          setSearchText('');
        } else {
          setSearchText(inputRef.current.value?.toLowerCase());

          if (inputRef.current.value === '') {
            fetchOptions(searchText);
          }
        }
      }
      setListVisible(true);
    },
    [fetchOptions, searchText],
  );

  const handleInputOnClick = useCallback(
    (e) => {
      e.preventDefault();
      handleInputOnFocus(e);
    },
    [handleInputOnFocus],
  );

  const handleInputOnBlur = useCallback(
    (e, fieldOnBlur) => {
      if (!optionListRef) return;

      // Do nothing when the target is part of the option list, such as when selecting a list item
      const isChildTarget = e.relatedTarget && optionListRef.current?.contains(e.relatedTarget);

      if (isChildTarget) {
        return;
      }

      // DebugConsole.debug(`[${name}] Handle input on blur`);

      // User has touched the input
      isTouched.current = true;

      // User is leaving the input field
      // Reset the field to the state before
      handleOnLeaveInput(e, fieldOnBlur);
    },
    [handleOnLeaveInput],
  );

  const handleInputOnKeyUp = useCallback(
    (e) => {
      // DebugConsole.debug(`[${name}] Handle input on key up`);

      isInputFocus.current = true;

      if (e.key === 'Escape') {
        handleOnLeaveInput(e);
      }
    },
    [handleOnLeaveInput],
  );

  const handleSelect = useCallback(
    (e) => {
      if (disabled) return;
      if (!inputRef) return;

      isTouched.current = true;

      // DebugConsole.debug(`[${name}] Handle select: `, e.target);

      const value = `${e.target.value}`;
      const valueDisplay = `${e.target.dataset.displayText}`;

      // DebugConsole.debug(value, valueDisplay);

      // Update form value
      setValue(name, value, { shouldValidate: true });
      if (!valueAsText) setValue(`${name}Display`, valueDisplay);

      // Update value description
      setFieldValueDescription(e.target.dataset.description || '');

      // Update field state
      inputRef.current.value = valueAsText ? value : valueDisplay;
      inputRef.current.focus();
      isSelected.current = true;
      // isInputFocus.current = false;
      setListVisible(false);

      setSearchText(null);

      // Call on change if provided
      onChange({ value, text: valueDisplay });
    },
    [disabled, name, onChange, setValue, valueAsText],
  );

  const handleButtonOnBlur = useCallback(
    (e, fieldOnBlur) => {
      if (!optionListRef) return;

      // Do nothing when the target is search input
      const isChildTarget = e.relatedTarget && inputRef.current?.contains(e.relatedTarget);

      if (isChildTarget) {
        return;
      }

      // DebugConsole.debug(`[${name}] Handle button on blur`);

      // User has touched the input
      isTouched.current = true;

      // User is leaving the input field
      // Reset the field to the state before
      handleOnLeaveInput(e, fieldOnBlur);
    },
    [handleOnLeaveInput],
  );

  const handleButtonOnKeyUp = useCallback(
    (e) => {
      // DebugConsole.debug(`[${name}] Handle button on key up`);

      if (e.key === 'Escape') {
        handleOnLeaveInput(e);
      }

      if (e.key === 'Enter') {
        handleSelect(e);
      }
    },
    [handleOnLeaveInput, handleSelect],
  );

  const handleInputChange = useCallback(() => {
    if (!inputRef || !isInputFocus.current) return;
    // DebugConsole.debug(`[${name}] Handle input change`);

    setListVisible(true);

    setSearchText(inputRef.current?.value.toLowerCase());
  }, []);

  return (
    <Controller
      render={({ field, fieldState: { invalid, error } }) => {
        return (
          <div className="nsw-form__group">
            <InputLabel
              name={field.name}
              label={label}
              smallLabel={smallLabel}
              // @ts-ignore
              required={rules?.required}
              hoverHint={hoverHint}
              hoverHintMode={hoverHintMode}
            />
            <InputHelper name={field.name} helper={helper} />
            <div
              className={clsx({
                'nsw-form__predictive tw-mt-1': 1,
                [containerClassName]: containerClassName,
              })}
            >
              <div className="nsw-form__input-group" ref={inputGroupRef}>
                <input
                  ref={(node) => {
                    inputRef.current = node;
                    field.ref(node);
                  }}
                  id={field.name}
                  className={clsx({
                    'nsw-form__select': 1,
                    [textFieldClassName]: textFieldClassName,
                  })}
                  type="text"
                  aria-autocomplete="list"
                  autoComplete="one-time-code"
                  aria-invalid={invalid}
                  aria-describedby={helper ? `${field.name}-helper-text` : null}
                  onFocus={handleInputOnFocus}
                  onBlur={(e) => {
                    handleInputOnBlur(e, field.onBlur);
                  }}
                  onChange={_debounce(handleInputChange, 300, {
                    maxWait: 500,
                  })}
                  onClick={handleInputOnClick}
                  onKeyUp={handleInputOnKeyUp}
                  placeholder="Search and Select"
                  disabled={disabled}
                  tabIndex={0}
                />

                {!disabled && listVisible && (
                  <ul
                    className={clsx({
                      'nsw-form__predictive-list tw-max-h-[400px] tw-overflow-scroll': 1,
                      [listClassName]: listClassName,
                    })}
                    aria-describedby={`${name}-options-description`}
                    ref={optionListRef}
                  >
                    {(loading || isFetching) && <StyledListItem>Loading...</StyledListItem>}

                    {!(loading || isFetching) && (
                      <>
                        {options && options.length ? (
                          options.map((x, idx) => (
                            <StyledListItem key={idx} tabIndex={1}>
                              <StyledListButton
                                value={x.value}
                                onClick={handleSelect}
                                onBlur={handleButtonOnBlur}
                                onKeyUp={handleButtonOnKeyUp}
                                tabIndex={0}
                                data-description={x.description || undefined}
                                data-display-text={x.text}
                              >
                                {x.text}
                                {showOptionCategory && ` (${x.category})`}
                              </StyledListButton>
                            </StyledListItem>
                          ))
                        ) : (
                          <StyledListItem>No records found</StyledListItem>
                        )}
                      </>
                    )}
                  </ul>
                )}
              </div>
            </div>
            {fieldValueDescription && showDescription && (
              <span className="nsw-form__helper tw-mt-2">{fieldValueDescription}</span>
            )}
            {!hideError && error && (
              <span
                className={clsx({
                  'nsw-form__helper nsw-form__helper--error': 1,
                  [errorClassName]: errorClassName,
                })}
                id={`${field.name}-error-text`}
              >
                <i className="material-icons nsw-material-icons" tabIndex={-1} aria-hidden="true">
                  cancel
                </i>
                {errorMessageHandler
                  ? errorMessageHandler({
                      target: {
                        value: field.value,
                        error: error.message,
                      },
                    })
                  : error.message ||
                    (field.value ? 'Field data is invalid' : 'This field is required')}
              </span>
            )}
          </div>
        );
      }}
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      {...others}
    />
  );
};

export default FormSelectSearchable;
