import { create } from 'zustand';
import { getReceivableActivitiesInternationalList, updateReceivableAcitiviesInternationalList } from '@helper/service/invoice';
import { data } from './temp';
import { matchStrings } from '@helper/functions/utility';

interface InternationalAccessStore {
  receivableActivities: any[];
	filteredReceivableActivities: any[];
	paginatedReceivableActivities: any[];
	paymentType: string;
  isLoading: boolean;
  showRecActivities: boolean;
  page: string;
  fetchReceivableActivities: (type: string) => Promise<any[]>;
	getPaymentTypeOptions: () => Promise<any[]>;

	totalRecords: number;
	currentRecordPage: number;
	recordsPerPage: number;
	lastSearchFormName : string;
  
	setTotalRecords: (total: number) => void;
	setRecordsPerPage: (total: number) => void;
	setCurrentRecordPage: (page: number) => void;

	searchRecords: (healthEntity: string, hospital: string, service: string) => any[]
	resetFilter: () => void;

	updateInternationalAccess: (value: string, id: string) => void;
}

const updateActivitiesState = (set, stateKey, id, international) => {
	set((state) => ({
			[stateKey]: state[stateKey].map((record) =>
					record.SK === id ? { ...record, international } : record
			)
	}));
};

export const useInternationalAccessStore = create<InternationalAccessStore>()((set, get) => ({
  receivableActivities: [],
	paginatedReceivableActivities: [],
	filteredReceivableActivities: [],
	paymentType: "",
  isLoading: false,
  showRecActivities: false,
  page: "",
  fetchReceivableActivities: async (type) => {
    if(!type){
      return
    }
    set({isLoading: true});
		let recActivitiesList;
		try {
			recActivitiesList = await getReceivableActivitiesInternationalList(type);
			

			if (!Array.isArray(recActivitiesList)) {
				recActivitiesList = data;
			}
		} catch (error) {
			console.error("Error fetching receivable activities:", error);
			recActivitiesList = data;
		}

		if (!recActivitiesList || !recActivitiesList.length) {
      set({ receivableActivities: [], paginatedReceivableActivities: [], filteredReceivableActivities: [], currentRecordPage: 1, totalRecords: 0 });
      return undefined;
    }

    const { recordsPerPage, currentRecordPage } = get();

    const startIndex = (currentRecordPage - 1) * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;

		const paginatedList = recActivitiesList.slice(startIndex, endIndex);
			
		set({ receivableActivities: [...recActivitiesList], paginatedReceivableActivities:[...paginatedList], filteredReceivableActivities: [], isLoading: false, showRecActivities: true, totalRecords: recActivitiesList.length, currentRecordPage: 1, page: type});
		return [...paginatedList];
  },
	getPaymentTypeOptions: () => {
		return Promise.resolve([
			{
				value: "Misc",
				text: "Other Payments"
			}
		])
	},
	totalRecords: 0,
  recordsPerPage: 25,
  currentRecordPage: 1,
  lastSearchFormName: '',

	setTotalRecords: (total) => {
    set({totalRecords: total})
  },
  setRecordsPerPage: (total) => {
		const { currentRecordPage, receivableActivities } = get();

    const startIndex = (currentRecordPage - 1) * total;
    const endIndex = startIndex + total;

		const paginatedList = receivableActivities.slice(startIndex, endIndex);

    set({recordsPerPage: total, paginatedReceivableActivities: [...paginatedList]});
		
  },
  setCurrentRecordPage: (page) => {
		const { recordsPerPage, receivableActivities } = get();

    const startIndex = (page - 1) * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;

		const paginatedList = receivableActivities.slice(startIndex, endIndex);

    set({currentRecordPage: page, paginatedReceivableActivities: [...paginatedList]});

  },
	searchRecords: (healthEntity, hospital, service) => {
		set({isLoading: true});
		const { receivableActivities } = get();

		let list = receivableActivities.filter(
			(x) =>
				matchStrings(x.healthEntityCode, healthEntity?? '') ||
				matchStrings(x.healthEntity, healthEntity?? '') 
		);

		list = list.filter(
			(x) =>
				matchStrings(x.hospital, hospital?? '') 
		);

		list = list.filter(
			(x) =>
				matchStrings(x.activityName, service?? '') ||
				matchStrings(x.activityNameDisplay, service?? '') 
		);

		console.log(list);

		const { recordsPerPage, currentRecordPage } = get();

    const startIndex = (currentRecordPage - 1) * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;

		const paginatedList = list.slice(startIndex, endIndex);

		set({ filteredReceivableActivities: [...list], paginatedReceivableActivities:[...paginatedList], isLoading: false, showRecActivities: true, totalRecords: list.length, currentRecordPage: 1});
		return [...paginatedList];
	},
	resetFilter: () => {
		const { recordsPerPage, currentRecordPage, receivableActivities } = get();

    const startIndex = (currentRecordPage - 1) * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;

		const paginatedList = receivableActivities.slice(startIndex, endIndex);

		set({filteredReceivableActivities: receivableActivities, paginatedReceivableActivities: paginatedList, totalRecords: receivableActivities.length, currentRecordPage: 1})
	},
	updateInternationalAccess: async (value, id) => {
		console.log("Store function")
		const { page } = get();
		const data = await updateReceivableAcitiviesInternationalList(page, id);

		if(data.international){
			updateActivitiesState(set, "receivableActivities", id, data.international)
			updateActivitiesState(set, "paginatedReceivableActivities", id, data.international)
			updateActivitiesState(set, "filteredReceivableActivities", id, data.international)
		}

		return data;
	}
}));
