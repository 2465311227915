import React, { useState, useEffect } from "react";

const Pagination = ({ totalRecords, onPageChange, onRecordsPerPageChange, currentPage, recordsPerPage }) => {
    
  const totalPages = Math.ceil(totalRecords / recordsPerPage);

  return (
    <div style={styles.container}>
      {/* Records Per Page Dropdown */}
      <label style={styles.label}>
        Records per page:
        <select
          value={recordsPerPage}
          onChange={(e) => {
                        console.log("On Per Page changes")
            onRecordsPerPageChange(Number(e.target.value));
            onPageChange(1); // Reset to first page when changing records per page
          }}
          style={styles.select}
        >
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </label>

      {/* Page Selection Dropdown */}
      <label style={styles.label}>
        Page:
        <select
          value={currentPage}
          onChange={(e) => {
                        console.log("On Page changes")
                        onPageChange(Number(e.target.value))
                    }}
          style={styles.select}
        >
          {Array.from({ length: totalPages }, (_, i) => (
            <option key={i + 1} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>
      </label>

      {/* Page Info */}
      <span style={styles.info}>
        Showing {recordsPerPage} records per page | Page {currentPage} of {totalPages}
      </span>
    </div>
  );
};

// Basic styling
const styles = {
  container: { display: "flex", alignItems: "center", gap: "10px", margin: "10px 0" },
  label: { fontSize: "14px", fontWeight: "bold" },
  select: { marginLeft: "5px", padding: "5px", fontSize: "14px" },
  info: { marginLeft: "10px", fontSize: "14px", fontWeight: "bold" },
};

export default Pagination;