export const data = [
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Agency for Clinical Innovation",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "15cde259-c7b7-421b-b143-31f302ac6341",
      "postalCode": "2065",
      "address1": "Locked Bag 2030",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "Miscellaneous",
      "receivableActivityID": 4001,
      "healthEntityCode": "ACI",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "89 809 648 636",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Ambulance Service of NSW",
      "state": "NSW",
      "city": "ROZELLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "014a0128-c3b1-49dc-a633-58e0438d10f1",
      "postalCode": "2039",
      "address1": "Locked Bag 14",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "GIPA application fee for access to information held by the Health Organisation, excluding health records managed by facilities",
      "prefix": "GF",
      "priority": null,
      "source": "stafflink",
      "activityName": "AMB GIPA Processing Fees",
      "receivableActivityID": 434092,
      "healthEntityCode": "AMB",
      "activityNameDisplay": "GIPA - Government Information (Public Access)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "69 291 930 156",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Ambulance Service of NSW",
      "state": "NSW",
      "city": "ROZELLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "985d8be2-ef8b-42cb-a1fc-c078280c962c",
      "postalCode": "2039",
      "address1": "Locked Bag 14",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "AMB Medical Records",
      "receivableActivityID": 434095,
      "healthEntityCode": "AMB",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "69 291 930 156",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Ambulance Service of NSW",
      "state": "NSW",
      "city": "ROZELLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "30a8642b-5bf7-487f-a18c-77b936268fe9",
      "postalCode": "2039",
      "address1": "Locked Bag 14",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "AMB Subpeona",
      "receivableActivityID": 434097,
      "healthEntityCode": "AMB",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "69 291 930 156",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Ambulance Service of NSW",
      "state": "NSW",
      "city": "ROZELLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "8351f37e-8285-4e15-963b-237bcd2dcf62",
      "postalCode": "2039",
      "address1": "Locked Bag 14",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for merchandise",
      "prefix": "SM",
      "priority": null,
      "source": "stafflink",
      "activityName": "AMB Merchandise",
      "receivableActivityID": 462020,
      "healthEntityCode": "AMB",
      "activityNameDisplay": "Sales - Merchandise",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "69 291 930 156",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Ambulance Service of NSW",
      "state": "NSW",
      "city": "ROZELLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "6f7b0c12-d780-474c-a008-d2e8a5ad22f6",
      "postalCode": "2039",
      "address1": "Locked Bag 14",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AP",
      "priority": null,
      "source": "stafflink",
      "activityName": "Aeromedical prepayment",
      "receivableActivityID": 492026,
      "healthEntityCode": "AMB",
      "activityNameDisplay": "Aeromedical Prepayment",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "69 291 930 156",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Ambulance Service of NSW",
      "state": "NSW",
      "city": "ROZELLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "2d42bb66-f893-4868-a556-adcf01c18650",
      "postalCode": "2039",
      "address1": "Locked Bag 14",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AI",
      "priority": null,
      "source": "stafflink",
      "activityName": "Ambulance Invoice payment",
      "receivableActivityID": 492027,
      "healthEntityCode": "AMB",
      "activityNameDisplay": "Ambulance Invoice Payment",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "69 291 930 156",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Bureau of Health Information",
      "state": "NSW",
      "city": "CHATSWOOD",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "77bc52fa-391f-4d07-9656-34e99b190217",
      "postalCode": "2057",
      "address1": "Level 11",
      "address2": "67 Albert Avenue",
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "Miscellaneous",
      "receivableActivityID": 383011,
      "healthEntityCode": "BHI",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "36 675 085 258",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Woy Woy Hospital",
      "page": "Misc",
      "ID": "269079de-7e8c-4702-8192-efb26f9f8b11",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "WWH Pharmacy",
      "receivableActivityID": 113008,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Woy Woy Hospital",
      "page": "Misc",
      "ID": "0967b941-95ae-4011-a154-015dba6212be",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for use of the hydrotherapy pool including group classes, private bookings and treatments.",
      "prefix": "HY",
      "priority": null,
      "source": "stafflink",
      "activityName": "WWH Aquatic Physio",
      "receivableActivityID": 113009,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Hydrotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Woy Woy Hospital",
      "page": "Misc",
      "ID": "a0df9970-5757-4d91-b60d-5bde31eedc29",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AU",
      "priority": null,
      "source": "stafflink",
      "activityName": "WWH Ladies Auxiliary",
      "receivableActivityID": 113011,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Auxiliary",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Woy Woy Hospital",
      "page": "Misc",
      "ID": "24329e28-5164-4d46-9f91-abaccaa9d468",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SG",
      "priority": null,
      "source": "stafflink",
      "activityName": "WWH Sale Other",
      "receivableActivityID": 113012,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Sales - Other GST",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Gosford Hospital",
      "page": "Misc",
      "ID": "47512642-43bf-427d-a970-6ed78f7620e4",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for parking fees and services",
      "prefix": "PF",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOS Multistorey Parking",
      "receivableActivityID": 113019,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Parking Fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Gosford Hospital",
      "page": "Misc",
      "ID": "4cfa83eb-d192-4784-9949-c5a381589e40",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medical imaging USB",
      "prefix": "MI",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOS MRI",
      "receivableActivityID": 113024,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Medical Imaging USB",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Wyong Hospital",
      "page": "Misc",
      "ID": "c27df651-02b7-4c67-ac1b-04d0815b5e37",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "WYG UCC Equipment",
      "receivableActivityID": 229009,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Wyong Hospital",
      "page": "Misc",
      "ID": "0eba1e46-8d24-4743-b998-b1893b3e2491",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CT",
      "priority": null,
      "source": "stafflink",
      "activityName": "WYG Theatre Course",
      "receivableActivityID": 229010,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Course - Theatre",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Wyong Hospital",
      "page": "Misc",
      "ID": "cd5eb24c-9eed-4341-8f2a-d2749b1691d2",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training courses",
      "prefix": "WB",
      "priority": null,
      "source": "stafflink",
      "activityName": "WYG WBA Program",
      "receivableActivityID": 229011,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Education & Training - WBA",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Wyong Hospital",
      "page": "Misc",
      "ID": "ebe29206-6e68-4d06-b3bc-268070c6c93a",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "IP",
      "priority": null,
      "source": "stafflink",
      "activityName": "WYG PBRC Ineligible",
      "receivableActivityID": 240009,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Ineligible Payment",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Gosford Hospital",
      "page": "Misc",
      "ID": "6693e596-4a73-4b0e-ac07-675af0398b00",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOS Outpatient Equipment",
      "receivableActivityID": 246011,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Equipment Sales - Outpatients",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Gosford Hospital",
      "page": "Misc",
      "ID": "f326fbb4-e051-4a0a-8364-0f02a61d416d",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOS ED Equipment",
      "receivableActivityID": 246012,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Gosford Hospital",
      "page": "Misc",
      "ID": "ee004fc9-d35e-4cc0-9e1d-2085c7b35d90",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "IP",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOS PBRC Ineligible",
      "receivableActivityID": 248009,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Ineligible Payment",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Gosford Hospital",
      "page": "Misc",
      "ID": "335b3c2e-32c2-4885-9f53-9c60f7ab3c9b",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOS Accommodation",
      "receivableActivityID": 274017,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Wyong Hospital",
      "page": "Misc",
      "ID": "bb9b2399-2d09-40e7-a7b1-36f03138d805",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "WYG Medical Records",
      "receivableActivityID": 4007,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Wyong Hospital",
      "page": "Misc",
      "ID": "2d32da3b-342c-4a0c-8984-b3438813c7f8",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "WYG Pharmacy",
      "receivableActivityID": 4009,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Gosford Hospital",
      "page": "Misc",
      "ID": "d76e69a6-d70c-4b9f-83d9-d6d92eef79c1",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOS Pharmacy",
      "receivableActivityID": 4012,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Gosford Hospital",
      "page": "Misc",
      "ID": "997f0771-4bba-4c8a-a1ae-d9d8d10ef265",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SG",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOS Sale Other",
      "receivableActivityID": 4014,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Sales - Other GST",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Wyong Hospital",
      "page": "Misc",
      "ID": "6591aa2e-01ba-481b-a295-83000ecb07bb",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SG",
      "priority": null,
      "source": "stafflink",
      "activityName": "WYG Sale Other",
      "receivableActivityID": 4015,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Sales - Other GST",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "718bc785-aed6-4b6f-b784-9a6337207165",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "GIPA application fee for access to information held by the Health Organisation, excluding health records managed by facilities",
      "prefix": "GF",
      "priority": null,
      "source": "stafflink",
      "activityName": "CCLHD GIPA Processing Fees",
      "receivableActivityID": 415021,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "GIPA - Government Information (Public Access)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Gosford Hospital",
      "page": "Misc",
      "ID": "45a1d7bc-7d98-4a02-b798-1a6f1f7be6f0",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOS Sale of Old Wares",
      "receivableActivityID": 427012,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Gosford Hospital",
      "page": "Misc",
      "ID": "c4313cdc-51ce-4ff6-8717-e22918cf844e",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOS Birthing Parenting Classes",
      "receivableActivityID": 434281,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Wyong Hospital",
      "page": "Misc",
      "ID": "2c915c6c-5edb-44e1-8f9c-243031d650e3",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "WYG Subpoena",
      "receivableActivityID": 448013,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Gosford Hospital",
      "page": "Misc",
      "ID": "1f6a25b5-7b4e-437a-a649-1e50f88cbb6c",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training courses",
      "prefix": "CG",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOS BASIC Course",
      "receivableActivityID": 461037,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Course - Basic Course",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Gosford Hospital",
      "page": "Misc",
      "ID": "b35ae1f8-057c-4bca-833d-6c622dad89dd",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training courses",
      "prefix": "CH",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOS BEST Course",
      "receivableActivityID": 461038,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Course - BEST Course",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Long Jetty Health Centre",
      "page": "Misc",
      "ID": "aacd085d-2176-43e3-a2a6-0edf6b6dbf0d",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "LJH Urgent Care Centre Equip",
      "receivableActivityID": 461039,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Gosford Hospital",
      "page": "Misc",
      "ID": "a9c36a3a-d17c-4fcf-b4f5-c3373394e446",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase of rehabiliation equipment",
      "prefix": "EG",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOS Homecraft Cutlery",
      "receivableActivityID": 461040,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Equipment Sales - Cutlery",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Gosford Hospital",
      "page": "Misc",
      "ID": "c8950a46-e5fe-48ac-953d-10e01f74e573",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training course",
      "prefix": "EJ",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOS Mock Vivas",
      "receivableActivityID": 461041,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Education & Training - Mock Vivas",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Wyong Hospital",
      "page": "Misc",
      "ID": "50637759-7d9d-4f9e-a35c-580f2eca91a6",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "WYG Outpatient Physio Equip",
      "receivableActivityID": 462014,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Gosford Hospital",
      "page": "Misc",
      "ID": "6f9b584f-31ce-48e1-b117-4a7e0ee6ea11",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training courses",
      "prefix": "WB",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOS WBA Program",
      "receivableActivityID": 462015,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Education & Training - WBA",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Wyong Hospital",
      "page": "Misc",
      "ID": "932a64ea-b8b2-4f3e-9149-f243471fd0c3",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medical imaging USB",
      "prefix": "MI",
      "priority": null,
      "source": "stafflink",
      "activityName": "WYG MRI USB",
      "receivableActivityID": 498065,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Medical Imaging USB",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "6dfc4c51-5085-49eb-b1c4-331a72e79935",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "CCLHD Miscellaneous Activity",
      "receivableActivityID": 499023,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Gosford Hospital",
      "page": "Misc",
      "ID": "4332ca64-815b-4586-86d6-5cf140586d98",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for MRI services for Outpatients",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOS MRI Pre-Payment",
      "receivableActivityID": 500023,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "MRI Services",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Wyong Hospital",
      "page": "Misc",
      "ID": "03451508-224c-4e16-9535-906aa3847846",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for MRI services for Outpatients",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "WYG MRI Pre-Payment",
      "receivableActivityID": 500024,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "MRI Services",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Wyong Hospital",
      "page": "Misc",
      "ID": "8debba51-b052-4207-9477-d7fc6da506a9",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for use of the hydrotherapy pool including group classes, private bookings and treatments.",
      "prefix": "HY",
      "priority": null,
      "source": "stafflink",
      "activityName": "WYG Aquatic Physio",
      "receivableActivityID": 62002,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Hydrotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Wyong Hospital",
      "page": "Misc",
      "ID": "e305a098-8606-4394-9e0e-0fc1b4b77040",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AR",
      "priority": null,
      "source": "stafflink",
      "activityName": "WYG Rotary Lodge",
      "receivableActivityID": 62003,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Accom - Patient/relative/contractor - Rotary Lodge",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Central Coast Local Health District",
      "state": "NSW",
      "city": "GOSFORD",
      "hospital": "Gosford Hospital",
      "page": "Misc",
      "ID": "1ccb3f4f-48e9-40bc-b441-4a3cd2205207",
      "postalCode": "2250",
      "address1": "Locked Bag 50",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training courses",
      "prefix": "CI",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOS Theatre Course",
      "receivableActivityID": 62005,
      "healthEntityCode": "CCLHD",
      "activityNameDisplay": "Course - Anaesthetics Department",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "88 523 389 096",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Clinical Excellence Commission",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "59950f4a-dde3-47f0-afca-cf03d3c9f3f0",
      "postalCode": "2065",
      "address1": "Locked Bag 2030",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "Miscellaneous",
      "receivableActivityID": 56002,
      "healthEntityCode": "CEC",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "79 172 068 820",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Cancer Institute NSW",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "0223799c-c64d-4eab-b436-0a498366051f",
      "postalCode": "1590",
      "address1": "Locked Bag 2030",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "GIPA application fee for access to information held by the Health Organisation, excluding health records managed by facilities",
      "prefix": "GF",
      "priority": null,
      "source": "stafflink",
      "activityName": "CI GIPA Processing Fees",
      "receivableActivityID": 388013,
      "healthEntityCode": "CINSW",
      "activityNameDisplay": "GIPA - Government Information (Public Access)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 538 442 594",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Cancer Institute NSW",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "cd6711b5-972d-4ec2-abbb-bfcbe2883d56",
      "postalCode": "1590",
      "address1": "Locked Bag 2030",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CI Staff Reimbursement",
      "receivableActivityID": 458025,
      "healthEntityCode": "CINSW",
      "activityNameDisplay": "Staff Payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 538 442 594",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Cancer Institute NSW",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "8779011e-35f6-4cd5-927b-83d7d4925c84",
      "postalCode": "1590",
      "address1": "Locked Bag 2030",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "Miscellaneous",
      "receivableActivityID": 65007,
      "healthEntityCode": "CINSW",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 538 442 594",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "eHealth NSW",
      "state": "NSW",
      "city": "PARRAMATTA",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "d28b013d-1796-4700-85be-ab529cffa474",
      "postalCode": "2124",
      "address1": "Locked Bag 5270",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "Miscellaneous",
      "receivableActivityID": 51001,
      "healthEntityCode": "EH",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "90 365 793 470",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Far West Local Health District",
      "state": "NSW",
      "city": "BROKEN HILL",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "d31a6a57-7ce3-4e0b-b040-f5b8d5e2ed2c",
      "postalCode": "2880",
      "address1": "PO Box 457",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "Miscellaneous",
      "receivableActivityID": 14000,
      "healthEntityCode": "FWLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 030 541 064",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Far West Local Health District",
      "state": "NSW",
      "city": "BROKEN HILL",
      "hospital": "Broken Hill Base Hospital",
      "page": "Misc",
      "ID": "4734294a-c125-4b3b-9a57-f01783b4fb6d",
      "postalCode": "2880",
      "address1": "PO Box 457",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "GIPA application fee for access to information held by the Health Organisation, excluding health records managed by facilities",
      "prefix": "GF",
      "priority": null,
      "source": "stafflink",
      "activityName": "FWLHD GIPA Processing Fees",
      "receivableActivityID": 415018,
      "healthEntityCode": "FWLHD",
      "activityNameDisplay": "GIPA - Government Information (Public Access)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 030 541 064",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Far West Local Health District",
      "state": "NSW",
      "city": "BROKEN HILL",
      "hospital": "Broken Hill Base Hospital",
      "page": "Misc",
      "ID": "107ca6c7-3f90-4bbd-b69b-3746615d5b01",
      "postalCode": "2880",
      "address1": "PO Box 457",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "FWLHD Subpoena (OOS GST)",
      "receivableActivityID": 415019,
      "healthEntityCode": "FWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 030 541 064",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Far West Local Health District",
      "state": "NSW",
      "city": "BROKEN HILL",
      "hospital": "Broken Hill Base Hospital",
      "page": "Misc",
      "ID": "26cf3419-dd2e-419e-ae43-79638065a6ac",
      "postalCode": "2880",
      "address1": "PO Box 457",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "FWLHD Medical Records (GST)",
      "receivableActivityID": 415020,
      "healthEntityCode": "FWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 030 541 064",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Far West Local Health District",
      "state": "NSW",
      "city": "BROKEN HILL",
      "hospital": "Broken Hill Base Hospital",
      "page": "Misc",
      "ID": "6f9295f4-c9b8-4407-8e4e-3d1ad10b13b3",
      "postalCode": "2880",
      "address1": "PO Box 457",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a specialist",
      "prefix": "SP",
      "priority": null,
      "source": "stafflink",
      "activityName": "Eye Clinic Medication Payment",
      "receivableActivityID": 484056,
      "healthEntityCode": "FWLHD",
      "activityNameDisplay": "Specialist Medication Payment",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 030 541 064",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Far West Local Health District",
      "state": "NSW",
      "city": "BROKEN HILL",
      "hospital": "Broken Hill Base Hospital",
      "page": "Misc",
      "ID": "a536fa0d-9ea7-4ea0-b012-c9b9ee6a2d2d",
      "postalCode": "2880",
      "address1": "PO Box 457",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HO",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRH OT Equipment Hire",
      "receivableActivityID": 484057,
      "healthEntityCode": "FWLHD",
      "activityNameDisplay": "Equipment Hire - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 030 541 064",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Far West Local Health District",
      "state": "NSW",
      "city": "BROKEN HILL",
      "hospital": "Broken Hill Base Hospital",
      "page": "Misc",
      "ID": "73504cb4-3bcb-412e-9cf5-05885e267c37",
      "postalCode": "2880",
      "address1": "PO Box 457",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRH Physio Equipment Hire",
      "receivableActivityID": 484058,
      "healthEntityCode": "FWLHD",
      "activityNameDisplay": "Equipment Hire - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 030 541 064",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Far West Local Health District",
      "state": "NSW",
      "city": "BROKEN HILL",
      "hospital": "Broken Hill Base Hospital",
      "page": "Misc",
      "ID": "159ef630-504e-4769-bace-54844edcfe4f",
      "postalCode": "2880",
      "address1": "PO Box 457",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EO",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRH OT Equipment Sales",
      "receivableActivityID": 484059,
      "healthEntityCode": "FWLHD",
      "activityNameDisplay": "Equipment Sales - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 030 541 064",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Far West Local Health District",
      "state": "NSW",
      "city": "BROKEN HILL",
      "hospital": "Broken Hill Base Hospital",
      "page": "Misc",
      "ID": "16ddbbbe-b5f3-4aa4-b558-377b369dcefc",
      "postalCode": "2880",
      "address1": "PO Box 457",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRH Physio Equipment Sales",
      "receivableActivityID": 484060,
      "healthEntityCode": "FWLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 030 541 064",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Far West Local Health District",
      "state": "NSW",
      "city": "BROKEN HILL",
      "hospital": "Balranald District Hospital",
      "page": "Misc",
      "ID": "40a39f3b-c774-4515-bb56-22fdeacf3dca",
      "postalCode": "2880",
      "address1": "PO Box 457",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EO",
      "priority": null,
      "source": "stafflink",
      "activityName": "BLD OT/Physio Equipment Sales",
      "receivableActivityID": 484061,
      "healthEntityCode": "FWLHD",
      "activityNameDisplay": "Equipment Sales - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 030 541 064",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Far West Local Health District",
      "state": "NSW",
      "city": "BROKEN HILL",
      "hospital": "Broken Hill Base Hospital",
      "page": "Misc",
      "ID": "5a68060f-5a61-461c-8607-238932f13825",
      "postalCode": "2880",
      "address1": "PO Box 457",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "IC",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRH Integrated Care Sales",
      "receivableActivityID": 484062,
      "healthEntityCode": "FWLHD",
      "activityNameDisplay": "Equipment Sales - Integrated Care",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 030 541 064",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Far West Local Health District",
      "state": "NSW",
      "city": "BROKEN HILL",
      "hospital": "Broken Hill Base Hospital",
      "page": "Misc",
      "ID": "0ff94636-2b0a-4dd1-b4e6-4560066e5294",
      "postalCode": "2880",
      "address1": "PO Box 457",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRH Pharmaceutical Payment",
      "receivableActivityID": 484063,
      "healthEntityCode": "FWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 030 541 064",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Far West Local Health District",
      "state": "NSW",
      "city": "BROKEN HILL",
      "hospital": "Broken Hill Base Hospital",
      "page": "Misc",
      "ID": "ad5721e8-cb45-4004-8ee9-b27c821c21df",
      "postalCode": "2880",
      "address1": "PO Box 457",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRH Staff Meal Tickets",
      "receivableActivityID": 484064,
      "healthEntityCode": "FWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 030 541 064",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Far West Local Health District",
      "state": "NSW",
      "city": "BROKEN HILL",
      "hospital": "Wilcannia Multi-Purpose Service",
      "page": "Misc",
      "ID": "aa9702f4-a1f9-42d2-a6be-a171fb3036b9",
      "postalCode": "2880",
      "address1": "PO Box 457",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "WLC Staff Meal Tickets",
      "receivableActivityID": 484065,
      "healthEntityCode": "FWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 030 541 064",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Far West Local Health District",
      "state": "NSW",
      "city": "BROKEN HILL",
      "hospital": "Balranald District Hospital",
      "page": "Misc",
      "ID": "f4f3c3e7-f15b-43b4-84b6-914cedaf0b18",
      "postalCode": "2880",
      "address1": "PO Box 457",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "BLD Staff Meal Tickets",
      "receivableActivityID": 484066,
      "healthEntityCode": "FWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 030 541 064",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Health Education and Training Institute",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "6aebad4f-91a0-4057-ac12-895871485a05",
      "postalCode": "2065",
      "address1": "Locked Bag 2030",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "GIPA application fee for access to information held by the Health Organisation, excluding health records managed by facilities",
      "prefix": "GF",
      "priority": null,
      "source": "stafflink",
      "activityName": "HETI GIPA Processing Fees",
      "receivableActivityID": 461034,
      "healthEntityCode": "HETI",
      "activityNameDisplay": "GIPA - Government Information (Public Access)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "82 914 196 002",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Health Education and Training Institute",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "e4d8624e-27ce-47cf-aff3-e6138e9e2f07",
      "postalCode": "2065",
      "address1": "Locked Bag 2030",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SS",
      "priority": null,
      "source": "stafflink",
      "activityName": "HETI Scholarships/Study Subsidy Return",
      "receivableActivityID": 503025,
      "healthEntityCode": "HETI",
      "activityNameDisplay": "Scholarships / Study Subsidy Return",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "82 914 196 002",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Health Infrastructure",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "447eda40-964f-496d-b0f5-30eca68df2f0",
      "postalCode": "1590",
      "address1": "Locked Bag 2030",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "Miscellaneous",
      "receivableActivityID": 99002,
      "healthEntityCode": "HI",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "89 600 377 397",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "994f0201-d87f-45c1-ba74-7a09f4e08da5",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH Pharmacy",
      "receivableActivityID": 12000,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "ff823fd0-1515-43f2-ac15-57ec134bfb90",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EO",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH OCCUPATIONAL THERAPY - RNC",
      "receivableActivityID": 126003,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "39d55e51-7d1c-449d-9582-b8945363f2c5",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EF",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH PHYSIO. PLASTER SALES",
      "receivableActivityID": 126005,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Fracture Clinic",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "e7e00c1f-e868-4b66-9fcf-b31561de4045",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH CRUTCHES SALES",
      "receivableActivityID": 126007,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Inverell District Hospital",
      "page": "Misc",
      "ID": "497a5018-fa27-418f-a843-e49c3c468883",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "INV MEDICAL RECORDS REPORTS",
      "receivableActivityID": 126011,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Inverell District Hospital",
      "page": "Misc",
      "ID": "76b4511d-f124-4d31-8a38-ed11b5771f10",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "INV MEDICAL RECORDS SUBPOENA",
      "receivableActivityID": 126012,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Quirindi Community Hospital",
      "page": "Misc",
      "ID": "83669d35-be17-47aa-8876-ffa712241d25",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "QDI MEALS",
      "receivableActivityID": 126014,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Quirindi Community Hospital",
      "page": "Misc",
      "ID": "33a59a09-a245-4fd4-8fd5-baa8455f3008",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SC",
      "priority": null,
      "source": "stafflink",
      "activityName": "QDI STAFF CAFETERIA",
      "receivableActivityID": 126017,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Staff Cafeteria",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Quirindi Community Hospital",
      "page": "Misc",
      "ID": "15f21dca-7dbd-4210-b6ac-198b84b37f7a",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "QDI MEDICAL RECORDS REPORTS",
      "receivableActivityID": 126019,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Quirindi Community Hospital",
      "page": "Misc",
      "ID": "b8f45f29-9bb0-496c-b299-129e2c0f368e",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "QDI MEDICAL RECORDS SUBPOENA",
      "receivableActivityID": 126020,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Belmont Hospital",
      "page": "Misc",
      "ID": "1c28104e-6a0b-409b-8dfd-c5ce25eb65be",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SC",
      "priority": null,
      "source": "stafflink",
      "activityName": "BEL STAFF CAFETERIA",
      "receivableActivityID": 127002,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Staff Cafeteria",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manilla District Hospital",
      "page": "Misc",
      "ID": "53f82b75-a5c8-4ecc-8f18-ae782cf9f518",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAN MEDICAL RECORDS REPORT",
      "receivableActivityID": 127004,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manilla District Hospital",
      "page": "Misc",
      "ID": "b8196aba-6e32-45d4-8ba5-8254be7fc2a2",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAN MEDICAL RECORDS SUBPOENA",
      "receivableActivityID": 127005,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manilla District Hospital",
      "page": "Misc",
      "ID": "9459f5ea-a97d-4087-bfc6-adfe11fa57d7",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAN MEALS",
      "receivableActivityID": 127006,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manilla District Hospital",
      "page": "Misc",
      "ID": "735d444b-0d2c-4380-838a-e9eaf80f6f38",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SC",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAN STAFF CAFETERIA",
      "receivableActivityID": 127008,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Staff Cafeteria",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manilla District Hospital",
      "page": "Misc",
      "ID": "a08056c3-b22f-466c-b682-d78bd38df379",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "MW",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAN Meals on Wheels",
      "receivableActivityID": 127009,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Meals on wheels",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manilla District Hospital",
      "page": "Misc",
      "ID": "90552335-ebf0-433a-87f3-81ad7d132fc7",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAN PHYSIO SALES",
      "receivableActivityID": 127010,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manilla District Hospital",
      "page": "Misc",
      "ID": "6a368fbe-cc2b-495f-b25d-1aa0f4801b63",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAN CRUTCHES SALES",
      "receivableActivityID": 127011,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Scott Memorial Hospital, Scone",
      "page": "Misc",
      "ID": "bcd41fd3-5d9a-4883-83a1-3d9f580fde8d",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "SCONE PHARMACY",
      "receivableActivityID": 127013,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Scott Memorial Hospital, Scone",
      "page": "Misc",
      "ID": "6f069ee7-163e-4b3c-94a9-5af9d36d236f",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "SCONE MED RECORDS REPORTS",
      "receivableActivityID": 127014,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Scott Memorial Hospital, Scone",
      "page": "Misc",
      "ID": "b80693cd-9ae4-4f34-a274-02889b640dd2",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "SCONE MED RECORDS SUBPOENAS",
      "receivableActivityID": 127015,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Scott Memorial Hospital, Scone",
      "page": "Misc",
      "ID": "1dcd1d97-89c4-4b18-a381-e5d99d990888",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SC",
      "priority": null,
      "source": "stafflink",
      "activityName": "SCONE STAFF CAFETERIA",
      "receivableActivityID": 127016,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Staff Cafeteria",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Scott Memorial Hospital, Scone",
      "page": "Misc",
      "ID": "3fbc1289-5726-48a7-acdf-8676d7baa283",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "SCONE CRUTCHES DEPOSIT",
      "receivableActivityID": 127017,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Moree District Hospital",
      "page": "Misc",
      "ID": "38555c04-249c-4f17-9519-8e5f75cac6b4",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "MOR MEALS",
      "receivableActivityID": 127018,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Moree District Hospital",
      "page": "Misc",
      "ID": "0576670a-c3cd-47eb-93df-9aca551f2a5c",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "MOR MED RECORDS REPORTS",
      "receivableActivityID": 127019,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Moree District Hospital",
      "page": "Misc",
      "ID": "8f346f95-bb3b-4595-976c-7b45125aa032",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "MOR MEDICAL RECORDS SUBPOENA",
      "receivableActivityID": 127020,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Prince Albert Memorial, Tenterfield",
      "page": "Misc",
      "ID": "b6b26689-e7f7-46ab-81e0-0eb1a9effd12",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "TEN STAFF ACCOM",
      "receivableActivityID": 127021,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Prince Albert Memorial, Tenterfield",
      "page": "Misc",
      "ID": "6cd1ddde-e2fd-4d7d-83ab-3d0977e8d009",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "TEN RELATIVES ACCOM",
      "receivableActivityID": 127022,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Prince Albert Memorial, Tenterfield",
      "page": "Misc",
      "ID": "fdd5710b-f6de-4ee8-8700-27c10e2ff846",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SC",
      "priority": null,
      "source": "stafflink",
      "activityName": "TEN STAFF CAFE",
      "receivableActivityID": 127024,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Staff Cafeteria",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Prince Albert Memorial, Tenterfield",
      "page": "Misc",
      "ID": "f6964592-743f-41a3-b2f0-69ba16d65df5",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "TEN MEDICAL RECORDS REPORTS",
      "receivableActivityID": 127025,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Prince Albert Memorial, Tenterfield",
      "page": "Misc",
      "ID": "224103f4-5de2-41a7-acae-f6f356a7cd58",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "TEN PHYSIO SALES",
      "receivableActivityID": 127027,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Prince Albert Memorial, Tenterfield",
      "page": "Misc",
      "ID": "8a938925-a5cf-4f21-b470-4c78b99f2aab",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "TEN MEDICAL RECORDS SUBPOENA",
      "receivableActivityID": 127028,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Prince Albert Memorial, Tenterfield",
      "page": "Misc",
      "ID": "5e4fc773-d51f-490b-81a0-b039a789652c",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "TEN SALE OF OLD WARES",
      "receivableActivityID": 127029,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Walcha Multi-Purpose Service",
      "page": "Misc",
      "ID": "3571da8d-f32a-483d-89dc-c1698721aae7",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAL MEDICAL RECORDS REPORTS",
      "receivableActivityID": 127030,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Walcha Multi-Purpose Service",
      "page": "Misc",
      "ID": "999bef0f-4c38-49c7-bb13-4c202bb3bb47",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAL MEDICAL RECORDS SUBPOENA",
      "receivableActivityID": 127031,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Walcha Multi-Purpose Service",
      "page": "Misc",
      "ID": "eb42c226-ba97-4be7-950e-9b02baefb48c",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAL STAFF ACCOM",
      "receivableActivityID": 127032,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Walcha Multi-Purpose Service",
      "page": "Misc",
      "ID": "10b7d047-277a-4873-bbbb-0afcf1900b33",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SC",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAL STAFF CAFE",
      "receivableActivityID": 127034,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Staff Cafeteria",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Walcha Multi-Purpose Service",
      "page": "Misc",
      "ID": "93500663-c34b-49f6-9ec8-a8264d2ba7cb",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAL SALE OF OLD WARES",
      "receivableActivityID": 127035,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Moree District Hospital",
      "page": "Misc",
      "ID": "11020a48-c843-44c4-a380-81d7e5c98afe",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "MOR PHYSIO SALES",
      "receivableActivityID": 129002,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Armidale Rural Referral Hospital",
      "page": "Misc",
      "ID": "6707ccea-13ac-4bfc-bb1b-9c8be6221a6c",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "ARM PHARMACY",
      "receivableActivityID": 166009,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "5993faab-d06c-42e5-9184-d7e62d292b82",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SD",
      "priority": null,
      "source": "stafflink",
      "activityName": "CRU Car Sales",
      "receivableActivityID": 169010,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Disposed assets",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Glen Innes District Hospital",
      "page": "Misc",
      "ID": "4c2620e2-3f84-4ddc-9094-4139fe0c4f93",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIN Medical Records (Reports)",
      "receivableActivityID": 192009,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Glen Innes District Hospital",
      "page": "Misc",
      "ID": "c076911e-9b27-4a35-a660-eb883b2d4c2b",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIN Medical Records (Subpoena)",
      "receivableActivityID": 192010,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Gloucester Soldier's Memorial Hospital",
      "page": "Misc",
      "ID": "2feb2748-0c73-44aa-9731-6b250eb6110f",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "GLO Accommodation",
      "receivableActivityID": 241009,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "dd8d309b-a403-4a56-a0dc-ceb6c16c7079",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training courses",
      "prefix": "CC",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH Simulation Centre Courses",
      "receivableActivityID": 25000,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Course - Clinical Skills and Simulation Centre",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Armidale Rural Referral Hospital",
      "page": "Misc",
      "ID": "9987a827-e422-4e1f-93b3-db6ecded78df",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Staff ID card replacement",
      "prefix": "CR",
      "priority": null,
      "source": "stafflink",
      "activityName": "ARM REPLACE SWIPE CARD",
      "receivableActivityID": 250009,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Staff ID Card Replacement",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "99a841f3-e4bf-4735-84ba-d927d26311b6",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH Hunter Equipment Services",
      "receivableActivityID": 25001,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "8d1be8a9-96ab-4dfc-8433-288200f6cd6b",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH Medical Records-Medical Reports",
      "receivableActivityID": 25002,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "31892b76-815c-4a16-afa3-51c1a3fc0c1d",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH Medical Records-Subpoenas",
      "receivableActivityID": 25003,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Belmont Hospital",
      "page": "Misc",
      "ID": "b6c5ff62-56b8-4c9e-8be9-fa252bd5feea",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BEL Medical Records-Medical Reports",
      "receivableActivityID": 25004,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Belmont Hospital",
      "page": "Misc",
      "ID": "4a0c2539-c259-4448-80ef-fc398261072d",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "BEL Medical Records-Subpoenas",
      "receivableActivityID": 25005,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Armidale Rural Referral Hospital",
      "page": "Misc",
      "ID": "28e9b795-1ac4-4a3e-87a6-95262dbd7f63",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "ARM Relative Accomm (GST)",
      "receivableActivityID": 273009,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "c7e44a33-c99d-49b5-90a1-eb696b328975",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "HO",
      "priority": null,
      "source": "stafflink",
      "activityName": "CRU Inverell HealthOne",
      "receivableActivityID": 297010,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "HealthOne",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "867aeabd-4c1b-4a6b-a18c-0c944558368c",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for podiatry products such as Orthotic insoles, foot braces and supports",
      "prefix": "PO",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH Podiatry",
      "receivableActivityID": 318009,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Podiatry",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "99a53f6c-5557-458f-9d5b-79521c2ea44c",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CC",
      "priority": null,
      "source": "stafflink",
      "activityName": "Critical Care Conference",
      "receivableActivityID": 371010,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Course - Critical Care",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "294e07a2-99e2-45d3-a7a9-d65c1d5fc609",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training courses",
      "prefix": "CF",
      "priority": null,
      "source": "stafflink",
      "activityName": "Staying Connected Course",
      "receivableActivityID": 371011,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Course - Staying Connected when Emotions Run High",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "ca039472-6795-4876-91a4-1b67ddcae32b",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH Medical Records",
      "receivableActivityID": 4031,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "b1a128ee-451d-4404-a10a-0ea5ae8a23dd",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH Anenatal class",
      "receivableActivityID": 4033,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "90172646-5cf7-4729-97ad-69b12a691244",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH ED Crutches",
      "receivableActivityID": 4035,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "3b2b710f-c162-41b7-8cad-f4a12951a03e",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EO",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH O/T Equip Sales",
      "receivableActivityID": 4036,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "2796922c-7f42-4d62-b1eb-c1a831621dde",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH Physio Sales",
      "receivableActivityID": 4039,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Belmont Hospital",
      "page": "Misc",
      "ID": "263c83ac-b641-49bc-87aa-95c579697dcf",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "BDH Sale Old Wares",
      "receivableActivityID": 4041,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "7fe5f6e8-c50b-43df-b4f2-9d901a87389f",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Equipment Hire",
      "receivableActivityID": 4046,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "8f119862-2ebf-48fa-a51a-6d2cf044fb82",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AR",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Rent",
      "receivableActivityID": 4047,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Rental",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "eab021a9-3cea-4081-89c4-46149008da87",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Sale Old Wares",
      "receivableActivityID": 4048,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "d58b5055-092a-4a5c-85aa-31c43e12fd65",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Physio",
      "receivableActivityID": 4049,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "c5f319aa-a489-4366-a602-417b60d81bd7",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for use of the hydrotherapy pool including group classes, private bookings and treatments.",
      "prefix": "HY",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Hydro Pool",
      "receivableActivityID": 4050,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Hydrotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "d67ac3d9-462f-4841-9e59-59e403d26f4e",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Crutches Sales",
      "receivableActivityID": 4052,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "d3c32e06-a76a-4e91-98ff-dc03df8390b3",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Accom Staff",
      "receivableActivityID": 4053,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Armidale Rural Referral Hospital",
      "page": "Misc",
      "ID": "a4909fd0-f97f-4295-8d32-de81320c68da",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "ARM Equipment Hire",
      "receivableActivityID": 4055,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Armidale Rural Referral Hospital",
      "page": "Misc",
      "ID": "ae764520-b006-4ab1-95ab-27a1bcb479a7",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "ARM Physio Sales",
      "receivableActivityID": 4056,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Armidale Rural Referral Hospital",
      "page": "Misc",
      "ID": "bb8905a7-5c89-45a6-a3d0-ec40acc2c102",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "ARM Med Rec Reports/Notes",
      "receivableActivityID": 4057,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Armidale Rural Referral Hospital",
      "page": "Misc",
      "ID": "4b00b8ec-e883-4fae-8a51-6823941ab663",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for use of the hydrotherapy pool including group classes, private bookings and treatments.",
      "prefix": "HY",
      "priority": null,
      "source": "stafflink",
      "activityName": "ARM Hydrotherapy",
      "receivableActivityID": 4058,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Hydrotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Armidale Rural Referral Hospital",
      "page": "Misc",
      "ID": "f3055bcd-525e-4038-a95f-29952ebb587c",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "ARM Ed Sales",
      "receivableActivityID": 4059,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Armidale Rural Referral Hospital",
      "page": "Misc",
      "ID": "07a11ac6-da5d-469d-9d49-6a57b16adcf1",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "ARM Ante Natal class",
      "receivableActivityID": 4060,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Armidale Rural Referral Hospital",
      "page": "Misc",
      "ID": "9e60761d-21ad-4c9d-8985-c5a6aef72ecd",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EO",
      "priority": null,
      "source": "stafflink",
      "activityName": "ARM O/Therapy Sales",
      "receivableActivityID": 4061,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "1572c809-9ad0-4404-a864-746829d7e524",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Pharmacy",
      "receivableActivityID": 4063,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Maitland Hospital",
      "page": "Misc",
      "ID": "3adcfed2-c858-435e-af48-e9c2aef9d198",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "Mai Pharmacy",
      "receivableActivityID": 4064,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "ffed0f56-bbf6-4b70-b264-9cf9142bdcd5",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for use of the hydrotherapy pool including group classes, private bookings and treatments.",
      "prefix": "HY",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH Hydro Pool",
      "receivableActivityID": 4065,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Hydrotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Glen Innes District Hospital",
      "page": "Misc",
      "ID": "0105f9b7-92f7-409d-9a7d-ddfbf3e2d39c",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIN Crutches Sale",
      "receivableActivityID": 4068,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Warialda Multi-Purpose Service",
      "page": "Misc",
      "ID": "a2edca4f-add1-4441-817e-059fb7ec13b5",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAR Crutches Sale",
      "receivableActivityID": 4069,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Inverell District Hospital",
      "page": "Misc",
      "ID": "03a2d975-d8b5-4d26-b08c-8bc83006285f",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "INV Sale Old Wares",
      "receivableActivityID": 4070,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Inverell District Hospital",
      "page": "Misc",
      "ID": "f3b3db91-fa64-48b9-b028-70a3ad8879d6",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "INV Equipment Hire",
      "receivableActivityID": 4071,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Inverell District Hospital",
      "page": "Misc",
      "ID": "68aeedd1-9a64-41f6-8f9a-593cbc9f52b9",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "INV Crutches Sales",
      "receivableActivityID": 4072,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Inverell District Hospital",
      "page": "Misc",
      "ID": "ee3a23ce-c244-4880-8f5c-a148482f7735",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "INV Physio Sales",
      "receivableActivityID": 4074,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Bingara Multi-Purpose Service",
      "page": "Misc",
      "ID": "b872e7aa-c5af-487b-ae7d-49eb45728a44",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "BIN Equip Hire",
      "receivableActivityID": 4075,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Bingara Multi-Purpose Service",
      "page": "Misc",
      "ID": "229765e2-ce97-4236-9555-d22426b03e02",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "BIN Crutches Sale",
      "receivableActivityID": 4076,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Bingara Multi-Purpose Service",
      "page": "Misc",
      "ID": "5a738ff3-760f-4b68-9f91-8be441f8e05d",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BIN Physio Sales",
      "receivableActivityID": 4077,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "01c560ef-b092-444d-bfa5-a23c546ba8ba",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH Sale Paed Aids",
      "receivableActivityID": 4078,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Paediatric",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Gloucester Soldier's Memorial Hospital",
      "page": "Misc",
      "ID": "abc40ecc-2dd4-45c8-b766-8c9f55e7ae11",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "GLO Medical Records",
      "receivableActivityID": 4080,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Wingham Memorial Hospital",
      "page": "Misc",
      "ID": "8ac1ffda-ce94-4d4a-a2a8-6ceaf6302892",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for use of the hydrotherapy pool including group classes, private bookings and treatments.",
      "prefix": "HY",
      "priority": null,
      "source": "stafflink",
      "activityName": "WIN Hydrotherapy",
      "receivableActivityID": 4081,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Hydrotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Armidale Rural Referral Hospital",
      "page": "Misc",
      "ID": "d74a160f-8006-4c16-ae1b-d63c995177cc",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "ARM Equipment Sales",
      "receivableActivityID": 434099,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Armidale Rural Referral Hospital",
      "page": "Misc",
      "ID": "3ad3f464-a04a-4426-87ec-6ab7472de28e",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "ARM Subpeona Health Records",
      "receivableActivityID": 434100,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Barraba Multi-Purpose Service",
      "page": "Misc",
      "ID": "4a96df42-9384-4040-845b-9f874c821e6b",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "BAR Equipment Sales",
      "receivableActivityID": 434105,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Barraba Multi-Purpose Service",
      "page": "Misc",
      "ID": "a4e34456-136e-4dbc-a736-8ee53c265541",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BAR Medical Records",
      "receivableActivityID": 434106,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Barraba Multi-Purpose Service",
      "page": "Misc",
      "ID": "df75de7d-e000-4c8b-b22e-17e17384f46a",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "BAR Subpeona Health Records",
      "receivableActivityID": 434107,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Belmont Hospital",
      "page": "Misc",
      "ID": "c33aacb5-bac9-431d-8bc7-2b5d5b231936",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BEL Pharmacy",
      "receivableActivityID": 434109,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Belmont Hospital",
      "page": "Misc",
      "ID": "5e9c6791-c96b-4bc6-a681-df7d31fb08bf",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "BEL Equipment Sales",
      "receivableActivityID": 434110,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Belmont Hospital",
      "page": "Misc",
      "ID": "d0a27f5b-11a2-487f-9b14-946bb35f82ab",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "BEL Medical Equipment Sales",
      "receivableActivityID": 434111,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Bingara Multi-Purpose Service",
      "page": "Misc",
      "ID": "d08f96a0-ae29-4823-bddd-3aa7f43931bd",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "BIN Equipment Sales",
      "receivableActivityID": 434114,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Bingara Multi-Purpose Service",
      "page": "Misc",
      "ID": "83f343ea-3b08-47f5-a64d-d057c6d250ea",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BIN Medical Records",
      "receivableActivityID": 434115,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Bingara Multi-Purpose Service",
      "page": "Misc",
      "ID": "fa47a219-7171-46d3-9ee1-f9ed21c6859c",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "BIN Subpeona Health Records",
      "receivableActivityID": 434116,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Boggabri Multi-Purpose Service",
      "page": "Misc",
      "ID": "f69ed2e2-83fa-41a3-bb58-74227bb4bf59",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "BOG Equipment Sales",
      "receivableActivityID": 434117,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Boggabri Multi-Purpose Service",
      "page": "Misc",
      "ID": "bae20f6f-2160-44cc-8784-13b2ec464e8e",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BOG Medical Records",
      "receivableActivityID": 434118,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Boggabri Multi-Purpose Service",
      "page": "Misc",
      "ID": "06de3bf3-6e4e-4b45-bc19-4414e11f55a4",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "BOG Subpeona Health Records",
      "receivableActivityID": 434119,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Cessnock District Hospital",
      "page": "Misc",
      "ID": "05ea8ff4-ca73-44d3-ab03-18306462fcb3",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "CES Equipment Sales",
      "receivableActivityID": 434124,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Cessnock District Hospital",
      "page": "Misc",
      "ID": "81bba247-5ff6-439d-850a-d8f881c750ad",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "CES Medical Equipment Sales",
      "receivableActivityID": 434125,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Denman Multi-Purpose Service",
      "page": "Misc",
      "ID": "bae9878c-1c17-4e61-adce-1e68bbd47bc1",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "DEN Equipment Sales",
      "receivableActivityID": 434141,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Denman Multi-Purpose Service",
      "page": "Misc",
      "ID": "a01c9d43-2eb4-44ef-b718-8c504d47f1e2",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "DEN Medical Equipment Sales",
      "receivableActivityID": 434142,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Denman Multi-Purpose Service",
      "page": "Misc",
      "ID": "41c2df58-83ad-4e58-8fd7-a6b0e7bb7d3b",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "DEN Medical Records",
      "receivableActivityID": 434143,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Denman Multi-Purpose Service",
      "page": "Misc",
      "ID": "5b489bf9-a648-45b7-a720-5b83c625b385",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "DEN Subpeona Health Records",
      "receivableActivityID": 434144,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Dungog District Hospital",
      "page": "Misc",
      "ID": "1575c730-17a0-47a5-8a00-cea8b55445eb",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "DUN Equipment Sales",
      "receivableActivityID": 434145,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Dungog District Hospital",
      "page": "Misc",
      "ID": "a91d5279-63e7-43ed-bffb-f53d95893d16",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "Dun Medical Records",
      "receivableActivityID": 434146,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Dungog District Hospital",
      "page": "Misc",
      "ID": "4e801315-9ee3-46b2-b4c5-dea3b7e0e5e7",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "DUN Subpeona Health Records",
      "receivableActivityID": 434147,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Emmaville - Vegetable Creek Multi-Purpose Service",
      "page": "Misc",
      "ID": "96be0e92-07f8-49fb-b082-bfd783064ca2",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "EMM Equipment Sales",
      "receivableActivityID": 434148,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Emmaville - Vegetable Creek Multi-Purpose Service",
      "page": "Misc",
      "ID": "33c35655-034b-4dca-beee-9d57b12901a8",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "EMM Medical Equipment Sales",
      "receivableActivityID": 434149,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Emmaville - Vegetable Creek Multi-Purpose Service",
      "page": "Misc",
      "ID": "cb51ed22-b06b-4d4f-b74a-43cd82a9e880",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "EMM Medical Records",
      "receivableActivityID": 434150,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Emmaville - Vegetable Creek Multi-Purpose Service",
      "page": "Misc",
      "ID": "72f67572-7ce0-4328-9219-c816d259b281",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "EMM Subpeona Health Records",
      "receivableActivityID": 434151,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Glen Innes District Hospital",
      "page": "Misc",
      "ID": "b14bc37d-d914-402f-a6bb-54555168e5f8",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIN Equipment Sales",
      "receivableActivityID": 434156,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Gloucester Soldier's Memorial Hospital",
      "page": "Misc",
      "ID": "1d5921d9-3646-4e6e-aa22-9f10678b6305",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "GLO Equipment Sales",
      "receivableActivityID": 434157,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Gloucester Soldier's Memorial Hospital",
      "page": "Misc",
      "ID": "84209749-9bbb-4d23-94f7-8a0207db8680",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "GLO Medical Equipment Sales",
      "receivableActivityID": 434158,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Gloucester Soldier's Memorial Hospital",
      "page": "Misc",
      "ID": "e48dba74-1e3c-4edd-a676-87f7d6133d36",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "GLO Subpeona Health Records",
      "receivableActivityID": 434159,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Gunnedah District Hospital",
      "page": "Misc",
      "ID": "57fca53f-6d39-4baf-a9d1-53cb3f16d0ef",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "GUN Medical Equipment Sales",
      "receivableActivityID": 434165,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Gunnedah District Hospital",
      "page": "Misc",
      "ID": "00176517-ade8-456a-9842-1929ec9941ce",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "GUN Medical Records",
      "receivableActivityID": 434166,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Gunnedah District Hospital",
      "page": "Misc",
      "ID": "2705019e-baed-4c54-b3b8-1339fbb1a38f",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "GUN Subpeona Health Records",
      "receivableActivityID": 434167,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Guyra Multi Purpose Service",
      "page": "Misc",
      "ID": "8d64bf28-7a2b-4c36-ba69-f4358508acfc",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "GUY Equipment Sales",
      "receivableActivityID": 434168,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Guyra Multi Purpose Service",
      "page": "Misc",
      "ID": "5380eaf6-0808-4d3e-9336-d7586e538d2d",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "GUY Medical Records",
      "receivableActivityID": 434169,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Guyra Multi Purpose Service",
      "page": "Misc",
      "ID": "b81cccc3-3edc-40ce-829b-6d0398d4d4eb",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "GUY Subpeona Health Records",
      "receivableActivityID": 434170,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Inverell District Hospital",
      "page": "Misc",
      "ID": "659e3a03-d99f-4dda-a827-692a6ea5f3e2",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "INV Equipment Sales",
      "receivableActivityID": 434183,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "2d406c70-552f-49c8-b168-058ec5ac0daa",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH Equipment Sales",
      "receivableActivityID": 434186,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Kurri Kurri District Hospital",
      "page": "Misc",
      "ID": "8cf61e7b-e882-47cb-9f7f-66f43ddf323f",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "KUR Equipment Sales",
      "receivableActivityID": 434190,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Kurri Kurri District Hospital",
      "page": "Misc",
      "ID": "d1586ee8-53c0-4f82-b663-b206ac28423f",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "KUR Medical Equipment Sales",
      "receivableActivityID": 434191,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Kurri Kurri District Hospital",
      "page": "Misc",
      "ID": "4e15cc87-b07c-4fe5-926a-32c6c80eb2dc",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "KUR Medical Records",
      "receivableActivityID": 434192,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Kurri Kurri District Hospital",
      "page": "Misc",
      "ID": "926c9b3a-d7a0-494a-add5-9f03618ba42f",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "KUR Subpeona Health Records",
      "receivableActivityID": 434193,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Maitland Hospital",
      "page": "Misc",
      "ID": "55810184-3fc2-4126-821d-0688bcac5322",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAI Equipment Sales",
      "receivableActivityID": 434202,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Maitland Hospital",
      "page": "Misc",
      "ID": "a65d38e6-2bb1-470d-90ef-04a43c8ef078",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAU Medical Equipment Sales",
      "receivableActivityID": 434203,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manilla District Hospital",
      "page": "Misc",
      "ID": "e950d881-2c20-4e57-93f7-7e6aadaefda7",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAN Medical Equipment Sales",
      "receivableActivityID": 434204,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "2e02fb7f-557f-4b2f-888f-2b3ec9ba76db",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAN Pharmacy",
      "receivableActivityID": 434205,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Merriwa Multi-Purpose Service",
      "page": "Misc",
      "ID": "87256066-43cf-4d1e-a434-ebc613dda202",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "MER Equipment Sales",
      "receivableActivityID": 434206,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Merriwa Multi-Purpose Service",
      "page": "Misc",
      "ID": "f8b0bb74-3930-4edc-bb81-8f0fdcace508",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "MER Medical Equipment Sales",
      "receivableActivityID": 434207,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Merriwa Multi-Purpose Service",
      "page": "Misc",
      "ID": "4f0b3aeb-0185-469c-9d8e-a6e94414beb5",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "MER Medical Records",
      "receivableActivityID": 434208,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Merriwa Multi-Purpose Service",
      "page": "Misc",
      "ID": "a04bb1ab-5d1c-478d-b740-57b907367a17",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "MER Subpeona Health Records",
      "receivableActivityID": 434209,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Moree District Hospital",
      "page": "Misc",
      "ID": "4c0d2864-3803-49e4-bcd3-1d7330365acc",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "MOR Equipment Sales",
      "receivableActivityID": 434211,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Muswellbrook District Hospital",
      "page": "Misc",
      "ID": "ffce2c87-1223-4cad-825c-97981c8ec71e",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUS Equipment Sales",
      "receivableActivityID": 434216,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Muswellbrook District Hospital",
      "page": "Misc",
      "ID": "e833c5ed-56c3-4a94-85a6-e77ca3926241",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUS Medical Sales Equip Sales",
      "receivableActivityID": 434217,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Narrabri District Hospital",
      "page": "Misc",
      "ID": "5ff9d311-4978-4f3d-9f6c-5fb6a99c1ef5",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "NAR Equipment Sales",
      "receivableActivityID": 434218,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Narrabri District Hospital",
      "page": "Misc",
      "ID": "c8eeb516-1311-4ce9-af44-85cdd29721a5",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "NAR Medical Records",
      "receivableActivityID": 434219,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Narrabri District Hospital",
      "page": "Misc",
      "ID": "a0a9ced9-06a1-4da8-982a-2dc3bede98b0",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "NAR Subpeona Health Records",
      "receivableActivityID": 434220,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Prince Albert Memorial, Tenterfield",
      "page": "Misc",
      "ID": "4b1dd56a-e5e6-4154-ba77-a3561c8f1d84",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "TEN Equipment Sales",
      "receivableActivityID": 434224,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Quirindi Community Hospital",
      "page": "Misc",
      "ID": "4bf651b2-d701-4870-ab9b-826bd5cf4a95",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "QUI Equipment Sales",
      "receivableActivityID": 434226,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Scott Memorial Hospital, Scone",
      "page": "Misc",
      "ID": "5cc90b72-c501-4558-a331-fa1ae24f9f51",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "SCONE Equipment Sales",
      "receivableActivityID": 434229,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Singleton District Hospital",
      "page": "Misc",
      "ID": "58b0498e-d4a1-4ddd-9880-2129e1901f50",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "SING Equipment Sales",
      "receivableActivityID": 434230,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Singleton District Hospital",
      "page": "Misc",
      "ID": "233efe86-d729-4f89-b7f7-23a6a69c4c4c",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "SING Medical Equipment Sales",
      "receivableActivityID": 434231,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "dbcb3b90-9512-4b72-9143-c39facf63a1a",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Equipment Sales",
      "receivableActivityID": 434235,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tomaree Community Hospital",
      "page": "Misc",
      "ID": "8ea1b0ae-ed0e-4e49-a40c-664f77cab9a0",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "TOM Equipment Sales",
      "receivableActivityID": 434242,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tomaree Community Hospital",
      "page": "Misc",
      "ID": "96f226ca-c611-437a-9887-23aed654dc99",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "TOM Medical Equipment Sales",
      "receivableActivityID": 434243,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tomaree Community Hospital",
      "page": "Misc",
      "ID": "69fc0498-3085-4070-818d-b05c4de81482",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "TOM Medical Records",
      "receivableActivityID": 434244,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tomaree Community Hospital",
      "page": "Misc",
      "ID": "865cf4ea-e58e-446e-96db-9550eb514183",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "TOM Subpeona Health Records",
      "receivableActivityID": 434245,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Walcha Multi-Purpose Service",
      "page": "Misc",
      "ID": "11199f76-f3b1-488d-bd56-80447c1dfba8",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAL Equipment Sales",
      "receivableActivityID": 434254,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Warialda Multi-Purpose Service",
      "page": "Misc",
      "ID": "9620c3cc-1636-4663-a201-c11f6e89b2b6",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAR Equipment Sales",
      "receivableActivityID": 434255,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Warialda Multi-Purpose Service",
      "page": "Misc",
      "ID": "e9b0c5d2-d109-4b41-b6a5-6dc8fb17643c",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAR Medical Records",
      "receivableActivityID": 434256,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Warialda Multi-Purpose Service",
      "page": "Misc",
      "ID": "7254e58b-c44f-41a1-893b-6ad7b54fa5e0",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAR Subpeona Health Records",
      "receivableActivityID": 434257,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Wee Waa District Hospital",
      "page": "Misc",
      "ID": "659a6fa4-16d4-495d-9138-d96a3dad81cb",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "WWH Medical Records",
      "receivableActivityID": 434258,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Wee Waa District Hospital",
      "page": "Misc",
      "ID": "81a5303b-c724-48ca-917b-de4b174e68cc",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "WWH Subpeona Health Records",
      "receivableActivityID": 434259,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Werris Creek District Hospital",
      "page": "Misc",
      "ID": "8830d08c-1b37-488a-aa76-93b50ab849f1",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "WER Equipment Sales",
      "receivableActivityID": 434260,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Werris Creek District Hospital",
      "page": "Misc",
      "ID": "ac66c350-9605-4de6-a4ea-dfe718c8be35",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "WER Medical Equipment Sales",
      "receivableActivityID": 434261,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Werris Creek District Hospital",
      "page": "Misc",
      "ID": "7f42d437-880a-462e-98fb-7913d473c51c",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "WER Medical Records",
      "receivableActivityID": 434262,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Werris Creek District Hospital",
      "page": "Misc",
      "ID": "695894be-c654-4f80-b4a4-b30e820831c0",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "WER Subpeona Health Records",
      "receivableActivityID": 434263,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Wilson Memorial Hospital, Murrurundi",
      "page": "Misc",
      "ID": "7d0dc3de-cba3-4cae-8d8a-81dfde21b088",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUR Equipment Sales",
      "receivableActivityID": 434264,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Wilson Memorial Hospital, Murrurundi",
      "page": "Misc",
      "ID": "6d6c215c-081c-4aec-8f95-ee3ccf66ceb9",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUR Medical Equip Sales",
      "receivableActivityID": 434265,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Wilson Memorial Hospital, Murrurundi",
      "page": "Misc",
      "ID": "78fcc1f0-b3cf-4ddf-9714-c218946c7cc0",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUR Medical Records",
      "receivableActivityID": 434266,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Wilson Memorial Hospital, Murrurundi",
      "page": "Misc",
      "ID": "400a305c-e67a-4943-83eb-5c5482bc5429",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUR Subpeona Health Records",
      "receivableActivityID": 434267,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Wingham Memorial Hospital",
      "page": "Misc",
      "ID": "144bfac4-0970-4753-9c12-62c31b6fdb12",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "WIN Equipment Sales",
      "receivableActivityID": 434268,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Wingham Memorial Hospital",
      "page": "Misc",
      "ID": "be2d5cc4-6a10-4b19-8675-58ee4f27e807",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "WIN Medical Records",
      "receivableActivityID": 434269,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Wingham Memorial Hospital",
      "page": "Misc",
      "ID": "4a6a1c8d-d311-41de-8527-4b74aea43241",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "WIN Subpeona Health Records",
      "receivableActivityID": 434270,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "8906a1f6-4422-41f9-b1f7-efa26eae8274",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "GIPA application fee for access to information held by the Health Organisation, excluding health records managed by facilities",
      "prefix": "GF",
      "priority": null,
      "source": "stafflink",
      "activityName": "HNELHD GIPA Processing Fees",
      "receivableActivityID": 441013,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "GIPA - Government Information (Public Access)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Cessnock District Hospital",
      "page": "Misc",
      "ID": "33925d78-e115-4cc7-b35d-d9c56662077b",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EF",
      "priority": null,
      "source": "stafflink",
      "activityName": "CES Plaster",
      "receivableActivityID": 5000,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Fracture Clinic",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Quirindi Community Hospital",
      "page": "Misc",
      "ID": "bc30d992-bb9a-443e-8c99-e9e89ce220b8",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "QUI Sales of Old Wares",
      "receivableActivityID": 5001,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Quirindi Community Hospital",
      "page": "Misc",
      "ID": "5ff599fb-1cc8-41b2-8e8e-497273ae52ad",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "QUI Crutches Sales",
      "receivableActivityID": 5002,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Scott Memorial Hospital, Scone",
      "page": "Misc",
      "ID": "bc8adc1c-acfe-41ea-bd70-1a03aace8bfb",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "SCO Ante Natal Classes",
      "receivableActivityID": 5003,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Walcha Multi-Purpose Service",
      "page": "Misc",
      "ID": "36ce582b-1efe-4f08-9454-0a5b7919dd0a",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AR",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAL Rent",
      "receivableActivityID": 5007,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Rental",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Walcha Multi-Purpose Service",
      "page": "Misc",
      "ID": "47c5dcdb-9cea-41e3-a833-39ea141cb0c7",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAL Crutches Sales",
      "receivableActivityID": 5008,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Barraba Multi-Purpose Service",
      "page": "Misc",
      "ID": "ea00a180-7b56-4c98-a209-adfd444f2011",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "TS",
      "priority": null,
      "source": "stafflink",
      "activityName": "BAR Bus Money",
      "receivableActivityID": 5009,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Transport Services",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Barraba Multi-Purpose Service",
      "page": "Misc",
      "ID": "1af7347a-4681-43a1-8a9d-df2d65b15cd5",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "BAR Equipment Hire",
      "receivableActivityID": 5010,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Barraba Multi-Purpose Service",
      "page": "Misc",
      "ID": "06bd1249-ae35-49dc-a930-27e28271ce31",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "BAR Crutches Sale",
      "receivableActivityID": 5011,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Barraba Multi-Purpose Service",
      "page": "Misc",
      "ID": "15665ce3-de7c-4c0b-8e47-540ecf474339",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BAR Physio Sales",
      "receivableActivityID": 5012,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Narrabri District Hospital",
      "page": "Misc",
      "ID": "11d5a742-2270-4d04-ab15-f6422e3a9f13",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "NAR Equipment Hire",
      "receivableActivityID": 5014,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Narrabri District Hospital",
      "page": "Misc",
      "ID": "ae78207a-e234-4ac8-bd4f-4313ac6be55c",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "NAR Crutches Sales",
      "receivableActivityID": 5015,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Wee Waa District Hospital",
      "page": "Misc",
      "ID": "39e937f1-f6f7-473c-9bba-c87f6c2d1aeb",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "WWH Crutches Sales",
      "receivableActivityID": 5016,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Moree District Hospital",
      "page": "Misc",
      "ID": "a22532e3-0a58-49f4-9ea3-4a0510985b4d",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "MOR Sales of Old Wares",
      "receivableActivityID": 5017,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Moree District Hospital",
      "page": "Misc",
      "ID": "83c6dda4-7ac3-4982-b638-9c4184bb26c2",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "MOR Crutches Sales",
      "receivableActivityID": 5018,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Boggabri Multi-Purpose Service",
      "page": "Misc",
      "ID": "61b75e39-2314-4314-aa6f-6ec0ef854ee6",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "BOG Crutches Sale",
      "receivableActivityID": 5019,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "963029f3-25c4-4f65-b731-4f6ecba0f03d",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH Nimoola House",
      "receivableActivityID": 5021,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Maitland Hospital",
      "page": "Misc",
      "ID": "1cae0b05-6dd4-42e4-a586-a237b4f7f8d1",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAI Physio/Plaster",
      "receivableActivityID": 5024,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Maitland Hospital",
      "page": "Misc",
      "ID": "91c18e47-2a51-40d3-8ae4-8a60a7059d65",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAI Anenatal class",
      "receivableActivityID": 5025,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Maitland Hospital",
      "page": "Misc",
      "ID": "320d29ce-dd21-47cc-bce1-aab9abc3e464",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAI Maternity Eq",
      "receivableActivityID": 5027,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Maternity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "1b8f93ce-ae26-469c-8533-ad1612329514",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH Sale Old Wares",
      "receivableActivityID": 5028,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "72b128b8-dfd7-496c-87f0-909ee00f9640",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HP",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH Physio Equipment Hire",
      "receivableActivityID": 5030,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "6a19b10e-cce8-4962-8bde-0465fddbffba",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH Physio Sales",
      "receivableActivityID": 5032,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "4f3bf51d-df3f-44c3-83e1-afc996fc6c24",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH Parenting Education",
      "receivableActivityID": 5035,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "b1f4afdb-5671-49d9-b269-389318c13601",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "TF",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Temp Assistance",
      "receivableActivityID": 5037,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Temporary Assistance Program Fee",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Gunnedah District Hospital",
      "page": "Misc",
      "ID": "de62ddf2-d455-435b-916c-60393bafb0c8",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "GUN Antenatal Classes",
      "receivableActivityID": 504024,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Cessnock District Hospital",
      "page": "Misc",
      "ID": "d123bcea-e185-4c0a-8b95-2c05e7aee4f5",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CES Pharmacy",
      "receivableActivityID": 504025,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manilla District Hospital",
      "page": "Misc",
      "ID": "f877aa40-19d3-4711-bb3c-ed4bafe40510",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAN Accom Staff",
      "receivableActivityID": 504026,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Wallsend Health Campus",
      "page": "Misc",
      "ID": "ea676a3f-2ae1-4b0d-b0c3-ce16617f0f96",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "WND HACC Equip Pool",
      "receivableActivityID": 5041,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "83761998-b2e0-4f46-9a93-85e6600711d8",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH Equipment Hire",
      "receivableActivityID": 5042,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Prince Albert Memorial, Tenterfield",
      "page": "Misc",
      "ID": "5e8e0d70-7829-456b-b339-53c031f2c324",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "TEN Equip Hire",
      "receivableActivityID": 5046,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Prince Albert Memorial, Tenterfield",
      "page": "Misc",
      "ID": "a1d66ba0-a47e-495f-8f9a-9f9d58cd3e60",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "TEN Crutches Sale",
      "receivableActivityID": 5047,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Glen Innes District Hospital",
      "page": "Misc",
      "ID": "4e622d06-4ffe-4624-a7f2-bf8b91a66a92",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIN Staff Accommodation",
      "receivableActivityID": 5054,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Inverell District Hospital",
      "page": "Misc",
      "ID": "ddc5e6f8-df13-4b22-8e46-df5ceeb84fef",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "INV Staff Accomm",
      "receivableActivityID": 5055,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Wingham Memorial Hospital",
      "page": "Misc",
      "ID": "bb0d2fdc-2792-47ba-b310-10b28e4ee749",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "WIN Equip Hire",
      "receivableActivityID": 5057,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Wingham Memorial Hospital",
      "page": "Misc",
      "ID": "818fb5af-2565-4506-aa86-a4c447eb452d",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "WIN Equip Purchase",
      "receivableActivityID": 5058,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Wingham Memorial Hospital",
      "page": "Misc",
      "ID": "d152d355-3bbe-433d-9f88-1355ad518009",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "WIN Staff Rent",
      "receivableActivityID": 5059,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Kurri Kurri District Hospital",
      "page": "Misc",
      "ID": "4907498f-ec18-4274-ba9e-759132604888",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "KUR Sale Old Wares",
      "receivableActivityID": 5061,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Dungog District Hospital",
      "page": "Misc",
      "ID": "4e7d4805-7b9c-4639-9669-1aaa376e9f5c",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "DUN Equip Sales",
      "receivableActivityID": 5063,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Dungog District Hospital",
      "page": "Misc",
      "ID": "e825b328-b18f-4f20-9d81-e8e808d712cd",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "DUN Accommodation",
      "receivableActivityID": 5064,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Cessnock District Hospital",
      "page": "Misc",
      "ID": "4112908a-9224-4b52-a760-953299eec7d3",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "CES Sale Old Wares",
      "receivableActivityID": 5065,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Cessnock District Hospital",
      "page": "Misc",
      "ID": "c542fec3-43fe-4ad7-9c48-4f9997bfbdff",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SC",
      "priority": null,
      "source": "stafflink",
      "activityName": "CES Staff Cafe",
      "receivableActivityID": 5067,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Staff Cafeteria",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Singleton District Hospital",
      "page": "Misc",
      "ID": "7c5c2745-3811-46ce-976f-56a7634f7ccc",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "SIN Ante Natal Classes",
      "receivableActivityID": 5069,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Singleton District Hospital",
      "page": "Misc",
      "ID": "3b7738a3-5d2e-49b6-8ad1-573862f0440c",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "SIN Accommodation",
      "receivableActivityID": 5070,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Quirindi Community Hospital",
      "page": "Misc",
      "ID": "6e81ef31-d998-49e9-9b76-056b33dd72ab",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "QUI Accom Staff",
      "receivableActivityID": 5071,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Scott Memorial Hospital, Scone",
      "page": "Misc",
      "ID": "c1c821a6-b455-4314-bc4b-dabc6a5b068f",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "SCO Accom Nurses Home",
      "receivableActivityID": 5072,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Muswellbrook District Hospital",
      "page": "Misc",
      "ID": "3ff9e4c4-fbc4-4784-bfc3-2d592a94359d",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUS Accom Nurses Home",
      "receivableActivityID": 5073,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Narrabri District Hospital",
      "page": "Misc",
      "ID": "d624d2ea-626b-47e4-8bf3-9860d5db73ca",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "NAR Accomodation Staff",
      "receivableActivityID": 5075,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Moree District Hospital",
      "page": "Misc",
      "ID": "37a96b97-d46f-49de-8be1-54f127411d44",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "MOR Accom Staff",
      "receivableActivityID": 5076,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Maitland Hospital",
      "page": "Misc",
      "ID": "39e6893c-2586-40d9-989c-bfea2f664294",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for parking fees and services",
      "prefix": "PF",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAI Carparking",
      "receivableActivityID": 5077,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Parking Fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Maitland Hospital",
      "page": "Misc",
      "ID": "8fbf530c-faf2-4b03-82ce-028428bfa125",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SC",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAI Staff Cafe",
      "receivableActivityID": 5079,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Staff Cafeteria",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Maitland Hospital",
      "page": "Misc",
      "ID": "55593f3e-4fd4-4224-b5f3-d58fad67dfe7",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "MW",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAI Meals on Wheels",
      "receivableActivityID": 5080,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Meals on wheels",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "9551b246-12a4-4a77-ac36-67406ab37bd1",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SC",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH Staff Cafe",
      "receivableActivityID": 5081,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Staff Cafeteria",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "d01ec037-f662-44d1-9f6b-7ed52eb2facb",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH Hanka House Accommodation",
      "receivableActivityID": 5082,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "deb2d771-3c90-4959-b797-a44ed5a3308a",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SK",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Meals Hospital Kiosk",
      "receivableActivityID": 5085,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Kiosk",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "adf70e4b-533f-47c4-8192-53c2c4c013f7",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AR",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Accom Rotary",
      "receivableActivityID": 5086,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accom - Patient/relative/contractor - Rotary Lodge",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Armidale Rural Referral Hospital",
      "page": "Misc",
      "ID": "a39f0ba3-d7f7-4dbc-aafb-b0984bc8ef6f",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "ARM Staff Accomm",
      "receivableActivityID": 5087,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Wee Waa District Hospital",
      "page": "Misc",
      "ID": "9cab935e-6178-414e-8b25-70e06c75590a",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "WWH Meals Catering",
      "receivableActivityID": 5090,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Warialda Multi-Purpose Service",
      "page": "Misc",
      "ID": "4107c65c-c1ec-46e8-a1b5-96366cbfd5d1",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAR Meals Catering",
      "receivableActivityID": 5091,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Walcha Multi-Purpose Service",
      "page": "Misc",
      "ID": "4acd4b9d-631b-419c-af29-211b68a2db72",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAL Meals Catering",
      "receivableActivityID": 5092,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Prince Albert Memorial, Tenterfield",
      "page": "Misc",
      "ID": "fe3e8854-478c-400c-8615-36fbd4dce428",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "TEN Meals Catering",
      "receivableActivityID": 5093,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Moree District Hospital",
      "page": "Misc",
      "ID": "d6074324-b55c-4904-85c1-40aba078142d",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SC",
      "priority": null,
      "source": "stafflink",
      "activityName": "MOR Staff Cafe",
      "receivableActivityID": 5095,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Staff Cafeteria",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Inverell District Hospital",
      "page": "Misc",
      "ID": "043512ce-744e-4184-a101-037d716fe247",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "INV Meals",
      "receivableActivityID": 5098,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Guyra Multi Purpose Service",
      "page": "Misc",
      "ID": "ab962682-4baf-4c01-9956-f01fa520d526",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SC",
      "priority": null,
      "source": "stafflink",
      "activityName": "GUY Staff Cafe",
      "receivableActivityID": 5099,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Staff Cafeteria",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "a349bb10-e27f-4515-bbf4-6c496c2c889e",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SC",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Staff Cafe",
      "receivableActivityID": 5100,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Staff Cafeteria",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Glen Innes District Hospital",
      "page": "Misc",
      "ID": "f9147ada-d11f-4d05-a613-782f7a06ba3a",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SC",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIN Staff Cafe",
      "receivableActivityID": 5101,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Staff Cafeteria",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Armidale Rural Referral Hospital",
      "page": "Misc",
      "ID": "7bdf0d48-2640-477c-8952-be6fd2a674b2",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "ARM Meals",
      "receivableActivityID": 5102,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Armidale Rural Referral Hospital",
      "page": "Misc",
      "ID": "eccac6cf-6b72-4d33-838a-f0d9377ebb94",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SC",
      "priority": null,
      "source": "stafflink",
      "activityName": "ARM Staff Cafe",
      "receivableActivityID": 5103,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Staff Cafeteria",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "1abea006-545c-4191-8904-e1dc9e628fbc",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SC",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH Cafe",
      "receivableActivityID": 5104,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Staff Cafeteria",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Belmont Hospital",
      "page": "Misc",
      "ID": "d1fe737c-823a-4f8c-ba19-7989c25e4de4",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SK",
      "priority": null,
      "source": "stafflink",
      "activityName": "BDH Cafe",
      "receivableActivityID": 5105,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Kiosk",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "63f36949-86a3-42ac-9bd6-e1f889ecee73",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "All Miscellaneous Change Combo",
      "receivableActivityID": 5107,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Guyra Multi Purpose Service",
      "page": "Misc",
      "ID": "ae727cbe-14a5-4590-acd5-df5fb2f8a847",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "GUY Relatives Accommodation",
      "receivableActivityID": 5108,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Guyra Multi Purpose Service",
      "page": "Misc",
      "ID": "c3182dd4-19e5-4244-b3df-d56d6fc8972e",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "GUY Crutches Sale",
      "receivableActivityID": 5109,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Glen Innes District Hospital",
      "page": "Misc",
      "ID": "f747abdf-d72c-43a8-8935-7655d04f27b5",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIN Sale Old Wares",
      "receivableActivityID": 5110,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Glen Innes District Hospital",
      "page": "Misc",
      "ID": "f9ce114b-5494-4892-9305-0bce9de18543",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIN Physio Sales",
      "receivableActivityID": 5111,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Wallsend Aged Care Facility",
      "page": "Misc",
      "ID": "96914cae-5301-43e1-a90a-fb475d9aa0fa",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AK",
      "priority": null,
      "source": "stafflink",
      "activityName": "CAC Key Deposit",
      "receivableActivityID": 5112,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Key Deposit",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Guyra Multi Purpose Service",
      "page": "Misc",
      "ID": "8bed0774-6e38-4dbc-9f8a-f8663229d810",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AK",
      "priority": null,
      "source": "stafflink",
      "activityName": "GUY Key Deposit",
      "receivableActivityID": 5113,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Key Deposit",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Glen Innes District Hospital",
      "page": "Misc",
      "ID": "55b18fc6-0a88-4cd1-aa55-33341ebc4e16",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "RD",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIN Capsule Deposit",
      "receivableActivityID": 5115,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire - Refundable Deposits",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Inverell District Hospital",
      "page": "Misc",
      "ID": "571d1468-dbda-4ef7-b806-f0daf3e36acb",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AK",
      "priority": null,
      "source": "stafflink",
      "activityName": "INV Key Deposit",
      "receivableActivityID": 5116,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Key Deposit",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Singleton District Hospital",
      "page": "Misc",
      "ID": "e513b1db-3349-4ed8-a775-53fbddbe32c8",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for radiology services for outpatients",
      "prefix": "RF",
      "priority": null,
      "source": "stafflink",
      "activityName": "SIN Diag Fees Radiology",
      "receivableActivityID": 5118,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Radiology Fee",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Hunter New England Mater Mental Health Service",
      "page": "Misc",
      "ID": "421a15da-c60c-4148-8c56-861e9b836488",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AK",
      "priority": null,
      "source": "stafflink",
      "activityName": "HMH Key Deposit",
      "receivableActivityID": 5122,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Key Deposit",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "2f6b11a1-c66d-47e4-907e-d2af53bcda2e",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "RD",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH Dep Clean RMO",
      "receivableActivityID": 5124,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire - Refundable Deposits",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "a24d1cae-8de1-4744-b30a-60085dc74622",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AK",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH Key Deposit",
      "receivableActivityID": 5125,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Key Deposit",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "8072d4fc-886a-4bad-8fbe-ae10be194769",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "RD",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH Deposit Crutches",
      "receivableActivityID": 5127,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire - Refundable Deposits",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "cef4da4c-d3e4-42d8-af77-7621a51661ac",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "AD",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Deposit Doctors Bond",
      "receivableActivityID": 5129,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Refundable Deposits",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "7c70b515-7a22-406a-a618-79f2dc9a3d45",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AK",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Deposit Keys",
      "receivableActivityID": 5130,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Key Deposit",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Gloucester Soldier's Memorial Hospital",
      "page": "Misc",
      "ID": "c1221de2-0fb0-410a-b78c-00cad860d40e",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "GLO Equipment Hire",
      "receivableActivityID": 5133,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Cessnock District Hospital",
      "page": "Misc",
      "ID": "ca547750-4ec6-4583-8fea-1e3a0cd909e7",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "CES Tummy Rumbles",
      "receivableActivityID": 5134,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "feb53244-bc16-4215-acd4-3a14167f046d",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Ante Natal Classes",
      "receivableActivityID": 5145,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "8d75d51a-a686-43b0-8ffe-ba7196e3465a",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DO",
      "priority": null,
      "source": "stafflink",
      "activityName": "CRU Child Care",
      "receivableActivityID": 52009,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Child Care",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "5d135929-14a8-4443-b922-6460566c39a2",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CV",
      "priority": null,
      "source": "stafflink",
      "activityName": "CRU Vacation Care",
      "receivableActivityID": 52010,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Child Care Vacation Fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "9daf8f02-da5d-43e1-a8c1-e794682a668d",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Gym",
      "prefix": "DO",
      "priority": null,
      "source": "stafflink",
      "activityName": "CRU Staff Fitness",
      "receivableActivityID": 54000,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Gym",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "084399b7-4cb9-4298-a551-ba3fb60337f8",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for library books",
      "prefix": "LB",
      "priority": null,
      "source": "stafflink",
      "activityName": "CRU Gardiner Library",
      "receivableActivityID": 54002,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Library Books",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "9e44311b-a239-42f7-9938-43472aa5f085",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CRU PBS-Manning",
      "receivableActivityID": 54007,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Gunnedah District Hospital",
      "page": "Misc",
      "ID": "b2a6b2b7-53e7-46eb-9c01-347740e57d41",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "GUN Staff Accommodation",
      "receivableActivityID": 55000,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Gunnedah District Hospital",
      "page": "Misc",
      "ID": "95aeb215-dde1-45ee-9127-ba14ebc1c3b0",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "GUN Crutches Sales",
      "receivableActivityID": 55001,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Gunnedah District Hospital",
      "page": "Misc",
      "ID": "2a1a751c-adc4-421d-9601-5995677fae16",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "GUN Physio Sales",
      "receivableActivityID": 55002,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Gunnedah District Hospital",
      "page": "Misc",
      "ID": "23d36ff9-1d72-4f81-9250-e7be091897fe",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "NS",
      "priority": null,
      "source": "stafflink",
      "activityName": "GUN Harm Minimisation",
      "receivableActivityID": 55003,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Needle and Syringe Program",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Muswellbrook District Hospital",
      "page": "Misc",
      "ID": "8e256954-5cbb-44f5-958e-54eb82f7acaa",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUS Staff Meals",
      "receivableActivityID": 76003,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Muswellbrook District Hospital",
      "page": "Misc",
      "ID": "a39a3656-24b5-4974-968c-64d3414bb3c9",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUS Antenatal Classes",
      "receivableActivityID": 76004,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Muswellbrook District Hospital",
      "page": "Misc",
      "ID": "f20a88c3-2c25-43c6-80f2-32584e1945c1",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUS Equipment Loan",
      "receivableActivityID": 76006,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Muswellbrook District Hospital",
      "page": "Misc",
      "ID": "ca700ed7-cf42-4a6d-bfca-dff9f1b7af2c",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUS Medical Records Reports",
      "receivableActivityID": 76007,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Muswellbrook District Hospital",
      "page": "Misc",
      "ID": "83a6c034-7a1c-44d3-8bc5-b8d737d3850f",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUS Medical Records Subpoenas",
      "receivableActivityID": 76008,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Muswellbrook District Hospital",
      "page": "Misc",
      "ID": "dfa8a1b1-d95c-4cfc-a5fa-b687837c2f81",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUS Meals & Accommodation",
      "receivableActivityID": 76009,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Muswellbrook District Hospital",
      "page": "Misc",
      "ID": "e96744f3-2e53-4db4-8ffb-70bbe2731153",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "NS",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUS Needle Vending Machine",
      "receivableActivityID": 76013,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Needle and Syringe Program",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Singleton District Hospital",
      "page": "Misc",
      "ID": "2362237f-6b92-4693-9461-12ba23805bd5",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "RD",
      "priority": null,
      "source": "stafflink",
      "activityName": "SING Crutches deposit",
      "receivableActivityID": 79005,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire - Refundable Deposits",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Singleton District Hospital",
      "page": "Misc",
      "ID": "94653579-afcb-491c-a013-e03ba504236a",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for library books",
      "prefix": "LB",
      "priority": null,
      "source": "stafflink",
      "activityName": "SING Sale Book",
      "receivableActivityID": 79008,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Sales - Library Books",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Singleton District Hospital",
      "page": "Misc",
      "ID": "2b2d4a94-5b57-44da-acc5-cdfde0d34f72",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "SING Medical Records- Reports",
      "receivableActivityID": 79010,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Singleton District Hospital",
      "page": "Misc",
      "ID": "0510a830-b031-4b7c-9370-dc7e19d668b3",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "SING Medical Records- Subpoenas",
      "receivableActivityID": 79011,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Singleton District Hospital",
      "page": "Misc",
      "ID": "56ef672b-bed9-4fb3-bbe4-e12a2ef5d1a7",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "SING Meals",
      "receivableActivityID": 79012,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "46c69ccf-e86a-49f8-9166-d384321898cc",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for radiology services for outpatients",
      "prefix": "RF",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH Copy of X-Rays",
      "receivableActivityID": 79013,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Radiology Fee",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "185a8741-a4cd-43a2-9cda-cb07f1b4c4e4",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Staff ID card replacement",
      "prefix": "CR",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH Replacement Fee Swipe Cards",
      "receivableActivityID": 82002,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Staff ID Card Replacement",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "dc7b0cf3-85a0-4a62-9270-d55bf493b5d0",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "NS",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH Needle/Syringe Vending Machine",
      "receivableActivityID": 83002,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Needle and Syringe Program",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "8d473d28-3154-4a15-87b7-73067de66d2a",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH Rent-Nursing Administration",
      "receivableActivityID": 83003,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "0f8eb3eb-30c6-4aaf-a33e-b80438165ef6",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "MBH Equipment Hire Palliative Care TCHC",
      "receivableActivityID": 84003,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Equipment Hire - Palliative Care",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "69177928-d98b-4966-a163-bebcfc0d0dd9",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for parking fees and services",
      "prefix": "PF",
      "priority": null,
      "source": "stafflink",
      "activityName": "CRU Car Parking",
      "receivableActivityID": 89006,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Parking Fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "d756cc30-bc50-4ee5-894c-3c66c115de19",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Medical Records - Reports",
      "receivableActivityID": 89007,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Tamworth Base Hospital",
      "page": "Misc",
      "ID": "9a4e5c59-4625-4bdb-8605-879acb5fcb59",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "TAM Medical Records Subpoenas",
      "receivableActivityID": 89008,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Hunter New England Mater Mental Health Service",
      "page": "Misc",
      "ID": "8da06667-84ab-45a7-b7de-02a08421c801",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "MMH Medical Records- Reports",
      "receivableActivityID": 89011,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Hunter New England Mater Mental Health Service",
      "page": "Misc",
      "ID": "08603f99-614a-43a1-b35b-c21d2beece85",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "MMH Medical Records- Subpoenas",
      "receivableActivityID": 89012,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Maitland Hospital",
      "page": "Misc",
      "ID": "6fd8132a-63aa-44ff-854e-423058aa3997",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAI Medical Records- Reports",
      "receivableActivityID": 90003,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Maitland Hospital",
      "page": "Misc",
      "ID": "eea0f91c-848d-4d53-bafa-293eac4389b2",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAI Medical Records- Subpoenas",
      "receivableActivityID": 90004,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Maitland Hospital",
      "page": "Misc",
      "ID": "381d0fb2-cb47-43fd-a13c-e38a0032de10",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "NS",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAI Needle Vending Machine",
      "receivableActivityID": 90005,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Needle and Syringe Program",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Maitland Hospital",
      "page": "Misc",
      "ID": "3a8a184b-c3fc-48d4-b843-f9d452442323",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAI Accomodation",
      "receivableActivityID": 90006,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Cessnock District Hospital",
      "page": "Misc",
      "ID": "f07f269d-585f-49de-916f-76eb23702019",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "CES Medical Records- Reports",
      "receivableActivityID": 90009,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Hunter New England Local Health District",
      "state": "NSW",
      "city": "HRMC",
      "hospital": "Cessnock District Hospital",
      "page": "Misc",
      "ID": "60b2373e-8ec1-4834-bf65-9273a7b7b006",
      "postalCode": "2310",
      "address1": "Locked Bag 1",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "CES Medical Records- Subpoenas",
      "receivableActivityID": 90010,
      "healthEntityCode": "HNELHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 598 010 203",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "HealthShare NSW",
      "state": "NSW",
      "city": "PARRAMATTA",
      "hospital": "The Children's Hospital at Westmead",
      "page": "Misc",
      "ID": "2a45735d-0566-482f-9636-daaea328a7c4",
      "postalCode": "2124",
      "address1": "Locked Bag 5270",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CS",
      "priority": null,
      "source": "stafflink",
      "activityName": "CHW Retail",
      "receivableActivityID": 238009,
      "healthEntityCode": "HS",
      "activityNameDisplay": "Cafeteria Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "65 697 563 521",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "HealthShare NSW",
      "state": "NSW",
      "city": "PARRAMATTA",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "e76d04b1-8c6c-4998-8dc4-98503bea8302",
      "postalCode": "2124",
      "address1": "Locked Bag 5270",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CS",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH Retail",
      "receivableActivityID": 238011,
      "healthEntityCode": "HS",
      "activityNameDisplay": "Cafeteria Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "65 697 563 521",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "HealthShare NSW",
      "state": "NSW",
      "city": "PARRAMATTA",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "fe3ae9fa-02c1-4d5e-9ed1-acfdbf03b856",
      "postalCode": "2124",
      "address1": "Locked Bag 5270",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CS",
      "priority": null,
      "source": "stafflink",
      "activityName": "Taree Cafeteria",
      "receivableActivityID": 238012,
      "healthEntityCode": "HS",
      "activityNameDisplay": "Cafeteria Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "65 697 563 521",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "HealthShare NSW",
      "state": "NSW",
      "city": "PARRAMATTA",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "10e4e130-b667-49a6-bbef-062a7248547d",
      "postalCode": "2124",
      "address1": "Locked Bag 5270",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CS",
      "priority": null,
      "source": "stafflink",
      "activityName": "Nepean Cafeteria",
      "receivableActivityID": 239009,
      "healthEntityCode": "HS",
      "activityNameDisplay": "Cafeteria Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "65 697 563 521",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "HealthShare NSW",
      "state": "NSW",
      "city": "PARRAMATTA",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "90161b97-db4e-41f9-82a6-f4633d16c0f8",
      "postalCode": "2124",
      "address1": "Locked Bag 5270",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "Miscellaneous",
      "receivableActivityID": 4026,
      "healthEntityCode": "HS",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "65 697 563 521",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "HealthShare NSW",
      "state": "NSW",
      "city": "PARRAMATTA",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "234802d8-b5c8-489b-99b8-881306b1e4ec",
      "postalCode": "2124",
      "address1": "Locked Bag 5270",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AI",
      "priority": null,
      "source": "stafflink",
      "activityName": "PTS - HSNSW - PBRC receipt",
      "receivableActivityID": 468013,
      "healthEntityCode": "HS",
      "activityNameDisplay": "Ambulance Invoice Payment",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "65 697 563 521",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "HealthShare NSW",
      "state": "NSW",
      "city": "PARRAMATTA",
      "hospital": "The Children's Hospital at Westmead",
      "page": "Misc",
      "ID": "49ebe934-15b6-407a-bf87-419b8120c061",
      "postalCode": "2124",
      "address1": "Locked Bag 5270",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CG",
      "priority": null,
      "source": "stafflink",
      "activityName": "CHW Retail - GST",
      "receivableActivityID": 474015,
      "healthEntityCode": "HS",
      "activityNameDisplay": "Cafeteria Sales - GST",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "65 697 563 521",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "HealthShare NSW",
      "state": "NSW",
      "city": "PARRAMATTA",
      "hospital": "John Hunter Hospital",
      "page": "Misc",
      "ID": "a1b83497-780b-4490-ad6e-17fe6a791d4f",
      "postalCode": "2124",
      "address1": "Locked Bag 5270",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CG",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHH Retail- GST",
      "receivableActivityID": 474016,
      "healthEntityCode": "HS",
      "activityNameDisplay": "Cafeteria Sales - GST",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "65 697 563 521",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "HealthShare NSW",
      "state": "NSW",
      "city": "PARRAMATTA",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "2df4fa40-9235-4a67-8bfe-f49e561a8ba7",
      "postalCode": "2124",
      "address1": "Locked Bag 5270",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CG",
      "priority": null,
      "source": "stafflink",
      "activityName": "Nepean Cafeteria - GST",
      "receivableActivityID": 474017,
      "healthEntityCode": "HS",
      "activityNameDisplay": "Cafeteria Sales - GST",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "65 697 563 521",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "HealthShare NSW",
      "state": "NSW",
      "city": "PARRAMATTA",
      "hospital": "Manning Base Hospital",
      "page": "Misc",
      "ID": "aa1f365d-5473-4d8c-9ae0-2d62386ccad4",
      "postalCode": "2124",
      "address1": "Locked Bag 5270",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CG",
      "priority": null,
      "source": "stafflink",
      "activityName": "Taree Cafeteria - GST",
      "receivableActivityID": 474018,
      "healthEntityCode": "HS",
      "activityNameDisplay": "Cafeteria Sales - GST",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "65 697 563 521",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "HealthShare NSW",
      "state": "NSW",
      "city": "PARRAMATTA",
      "hospital": "Albury Prosthetics and Orthotics Service",
      "page": "Misc",
      "ID": "1b03d3fe-f021-4eda-939f-02fdcfef34ab",
      "postalCode": "2124",
      "address1": "Locked Bag 5270",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "Albury Prosth Orth Service",
      "receivableActivityID": 474019,
      "healthEntityCode": "HS",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "65 697 563 521",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "HealthShare NSW",
      "state": "NSW",
      "city": "PARRAMATTA",
      "hospital": "Hunter Prosthetics and Orthotics Service",
      "page": "Misc",
      "ID": "76f04b47-7c30-4d47-b481-65e4d568b8ae",
      "postalCode": "2124",
      "address1": "Locked Bag 5270",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "Hunter Prosth Orth Service",
      "receivableActivityID": 474020,
      "healthEntityCode": "HS",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "65 697 563 521",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "HealthShare NSW",
      "state": "NSW",
      "city": "PARRAMATTA",
      "hospital": "Hawkesbury District Hospital",
      "page": "Misc",
      "ID": "6dbeced9-902c-467a-92ca-c43f3f184126",
      "postalCode": "2124",
      "address1": "Locked Bag 5270",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CG",
      "priority": null,
      "source": "stafflink",
      "activityName": "HDH Retail Food",
      "receivableActivityID": 504023,
      "healthEntityCode": "HS",
      "activityNameDisplay": "Cafeteria Sales - GST",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "65 697 563 521",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Health System Support Group",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "2d824bbe-21ce-498c-bbab-1c015a3e044e",
      "postalCode": "2065",
      "address1": "Locked Bag 2030",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "Miscellaneous",
      "receivableActivityID": 319009,
      "healthEntityCode": "HSSG",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "95 885 087 830",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shoalhaven District Memorial Hospital",
      "page": "Misc",
      "ID": "7f46bb3e-124c-4b44-9947-48b2e4f90f37",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EM",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDM 23hr Ward Crutches",
      "receivableActivityID": 128002,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Equipment Sales - Medical Ward",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "2eab6e78-e45c-40e7-9a1c-effe0f90226a",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training courses",
      "prefix": "CA",
      "priority": null,
      "source": "stafflink",
      "activityName": "ISLHD ACCCN",
      "receivableActivityID": 132002,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Course - ACCCN Advanced Life Support",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "d1200cb8-047a-4066-a99d-00fb9cddb0c3",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training courses",
      "prefix": "CB",
      "priority": null,
      "source": "stafflink",
      "activityName": "TWH ALS2 Course",
      "receivableActivityID": 133002,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Course - AL2 Course",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Wollongong Hospital",
      "page": "Misc",
      "ID": "77cdf109-90b0-48ca-b074-c42055972cd5",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EF",
      "priority": null,
      "source": "stafflink",
      "activityName": "TWH Fract/AMB Equip Sales",
      "receivableActivityID": 153003,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Equipment Sales - Fracture Clinic",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Port Kembla District Hospital",
      "page": "Misc",
      "ID": "2a3ae8fb-797c-43e6-bd75-0f78add82ae9",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "PKH Equipment Hire",
      "receivableActivityID": 17000,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Wollongong Hospital",
      "page": "Misc",
      "ID": "28f9fd8a-738b-48ed-b2f2-7f48bc5faef4",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MI",
      "priority": null,
      "source": "stafflink",
      "activityName": "TWH ISHEC Accomm",
      "receivableActivityID": 187009,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Meals and Accommodation - ISHEC Accommodation",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shellharbour Hospital",
      "page": "Misc",
      "ID": "17138b55-0deb-4490-aa45-14025bfb9a00",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "SHH ED Crutches",
      "receivableActivityID": 204009,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shellharbour Hospital",
      "page": "Misc",
      "ID": "60d3a891-051c-4072-9f58-961f28429b49",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "SHH Physio",
      "receivableActivityID": 204010,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shellharbour Hospital",
      "page": "Misc",
      "ID": "524ab39b-9d97-4b03-a6a2-4a913042c446",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for podiatry products such as Orthotic insoles, foot braces and supports",
      "prefix": "PO",
      "priority": null,
      "source": "stafflink",
      "activityName": "SHH Podiatry",
      "receivableActivityID": 204011,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Podiatry",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "10524678-5030-4bda-a9e4-518b0970af73",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training courses",
      "prefix": "CF",
      "priority": null,
      "source": "stafflink",
      "activityName": "ISLHD Staying Connected",
      "receivableActivityID": 230009,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Course - Staying Connected when Emotions Run High",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "David Berry Hospital",
      "page": "Misc",
      "ID": "bb2c76c1-8305-40e4-b539-def8fed7f529",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for TV Rental",
      "prefix": "TV",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBH TV Rental",
      "receivableActivityID": 230010,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "TV Rental",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "32b7b3e4-3516-4a37-b473-2c6e8adbdf6b",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AD",
      "priority": null,
      "source": "stafflink",
      "activityName": "ISLHD Carunya Day Centre",
      "receivableActivityID": 232009,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Aged Day Care / Activity Centre Payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "David Berry Hospital",
      "page": "Misc",
      "ID": "31fe8ff3-0bd9-4809-81b3-abd66fe2f2e0",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBH Staff Meal Purchase",
      "receivableActivityID": 243009,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "a2a889d7-2124-439b-8953-6c52422ae9f4",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "GIPA application fee for access to information held by the Health Organisation, excluding health records managed by facilities",
      "prefix": "GF",
      "priority": null,
      "source": "stafflink",
      "activityName": "ISLHD GIPA Processing Fees",
      "receivableActivityID": 269009,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "GIPA - Government Information (Public Access)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Coledale Hospital",
      "page": "Misc",
      "ID": "51c5c30f-1dc1-4635-b5b1-54e7f364775e",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CDE Pharmacy Sales/NRT Sales",
      "receivableActivityID": 269010,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shoalhaven District Memorial Hospital",
      "page": "Misc",
      "ID": "063a0bda-b404-4ea0-9d8c-dde59abe90e4",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy",
      "prefix": "PC",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDM SCCC Pharmacy",
      "receivableActivityID": 303009,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Chemist & Pharmacy Sales - Cancer Care",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Milton Ulladulla Hospital",
      "page": "Misc",
      "ID": "65c4de5c-aa47-432e-a9d7-7df0dbc1234f",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUH Medical Records (GST)",
      "receivableActivityID": 313009,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "5bf757b5-7e02-4a1c-9569-070512e488eb",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for providing P2/N95 fit testing services to students",
      "prefix": "RT",
      "priority": null,
      "source": "stafflink",
      "activityName": "ISLHD Respirator Fit Testing",
      "receivableActivityID": 422010,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Respirator Fit Testing",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "edc27c87-abf6-46c5-bec0-3f434579a7b3",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training courses",
      "prefix": "CD",
      "priority": null,
      "source": "stafflink",
      "activityName": "ISHEC-OSCE Practice",
      "receivableActivityID": 442013,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Course - ISHEC-OSCE Practice",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "26900e69-a527-415c-a068-a218e3a9354e",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Payment of Service Fee provided by Public Health Unit",
      "prefix": "PH",
      "priority": null,
      "source": "stafflink",
      "activityName": "ISLHD Public Health Unit",
      "receivableActivityID": 482015,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Public Health Unit",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shoalhaven District Memorial Hospital",
      "page": "Misc",
      "ID": "f2c41651-cf95-4926-bac4-eac454f9f288",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDM Crutches",
      "receivableActivityID": 49000,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shoalhaven District Memorial Hospital",
      "page": "Misc",
      "ID": "face19ac-6612-43ab-8986-0e41f48e945a",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDM Equipment Hire ISES",
      "receivableActivityID": 49001,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Port Kembla District Hospital",
      "page": "Misc",
      "ID": "cc04d02e-4bbe-45ef-aff1-a06c13513729",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SG",
      "priority": null,
      "source": "stafflink",
      "activityName": "PKH ELP Sales",
      "receivableActivityID": 508025,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Sales - Other GST",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Coledale Hospital",
      "page": "Misc",
      "ID": "6fb5e96b-1165-4acd-86a7-06e03136ba99",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "CDE Equipment Purchase",
      "receivableActivityID": 6008,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Bulli District Hospital",
      "page": "Misc",
      "ID": "1a8d3384-a87d-4b4e-a9ae-ff6a5a16b7a1",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "BUL Equip Purchases Bulli",
      "receivableActivityID": 6009,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Port Kembla District Hospital",
      "page": "Misc",
      "ID": "8f0fdcfb-14d9-4514-9d0f-4206029d1d85",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "PKH Podiatry Orthotics",
      "receivableActivityID": 6011,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Wollongong Hospital",
      "page": "Misc",
      "ID": "b962ea72-abbf-4ec8-a50a-6cfb91dd857f",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "TWH Physio Eq Sales",
      "receivableActivityID": 6012,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Milton Ulladulla Hospital",
      "page": "Misc",
      "ID": "a061bd2d-88a6-4cc2-8e0c-4203aaf9a835",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUH Physio sales",
      "receivableActivityID": 6014,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shoalhaven District Memorial Hospital",
      "page": "Misc",
      "ID": "78b438dc-eae4-4c2c-aadf-f7bdda9c1359",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDM Physio Sales",
      "receivableActivityID": 6015,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Wollongong Hospital",
      "page": "Misc",
      "ID": "b2c8d2fc-e628-48a9-a8e4-d463a8b370db",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for parking fees and services",
      "prefix": "PF",
      "priority": null,
      "source": "stafflink",
      "activityName": "TWH Car Parking",
      "receivableActivityID": 6022,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Parking Fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Bulli District Hospital",
      "page": "Misc",
      "ID": "aa127120-f48a-4790-ab23-aa243ba6e938",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "BUL Subpoena",
      "receivableActivityID": 6023,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Bulli District Hospital",
      "page": "Misc",
      "ID": "99208916-82fa-4d05-aea9-63079ceccd83",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BUL Medical Records (GST)",
      "receivableActivityID": 6024,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shellharbour Hospital",
      "page": "Misc",
      "ID": "a0db9d24-dfe9-4a25-a417-145766921ef0",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "SHH Medical Records (GST)",
      "receivableActivityID": 6031,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Wollongong Hospital",
      "page": "Misc",
      "ID": "ac137115-3ec9-43f0-b5ff-9fe0f72e3132",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "TWH Medical Records (GST)",
      "receivableActivityID": 6032,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Wollongong Hospital",
      "page": "Misc",
      "ID": "25f7ba87-68aa-44a5-b0ce-fb9e6b0032d0",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "TWH Subpoena",
      "receivableActivityID": 6033,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shoalhaven District Memorial Hospital",
      "page": "Misc",
      "ID": "44903d7f-1643-4888-bede-845af55a5494",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDM Medical Records (GST)",
      "receivableActivityID": 6034,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shoalhaven District Memorial Hospital",
      "page": "Misc",
      "ID": "5cb0e3c3-adc0-48e9-8fd3-8dabbd18465d",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDM Subpoena",
      "receivableActivityID": 6035,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shellharbour Hospital",
      "page": "Misc",
      "ID": "eee6d7b5-c42a-4dad-827d-efc889eaad42",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "SHH Accommodation",
      "receivableActivityID": 6036,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Wollongong Hospital",
      "page": "Misc",
      "ID": "9eaf42ac-46a9-41d5-85a1-7d5d37e60f08",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "TWH Alkira Accom",
      "receivableActivityID": 6037,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Wollongong Hospital",
      "page": "Misc",
      "ID": "e80ea1f7-6d89-486a-aee5-aa33ef692b13",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "TWH Nurses Home Rent",
      "receivableActivityID": 6038,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Wollongong Hospital",
      "page": "Misc",
      "ID": "c7282740-7054-4ece-81a9-fb8a1e3bea7c",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "TWH Child Wd Rent",
      "receivableActivityID": 6039,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Accommodation - Childrens Ward",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shoalhaven District Memorial Hospital",
      "page": "Misc",
      "ID": "6c2fd15b-c6fc-4cab-bee6-e9b2f53a864d",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDM Staff Quarters",
      "receivableActivityID": 6040,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Bulli District Hospital",
      "page": "Misc",
      "ID": "113ee89e-aa23-4dbd-852a-9d2522a0cd07",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BUL  Scripts",
      "receivableActivityID": 6041,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shellharbour Hospital",
      "page": "Misc",
      "ID": "7c353d04-56bf-4b73-8fa4-ace11e36b7a5",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "SHH Pharmacy Scripts",
      "receivableActivityID": 6043,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Wollongong Hospital",
      "page": "Misc",
      "ID": "e45894bf-b74c-4820-9e40-7773dd7b865e",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "TWH Pharmacy Sales",
      "receivableActivityID": 6044,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shoalhaven District Memorial Hospital",
      "page": "Misc",
      "ID": "455f1d3b-a777-424b-9d63-522f304756f5",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDM Prescriptions",
      "receivableActivityID": 6045,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Milton Ulladulla Hospital",
      "page": "Misc",
      "ID": "c72d13b3-8da6-49da-9c23-92fc51a4c38c",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HO",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUH OT Hire",
      "receivableActivityID": 6047,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Equipment Hire - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "David Berry Hospital",
      "page": "Misc",
      "ID": "ee17cf4a-d70f-41fb-bae6-6c7b635e94c9",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBH Cottage Rental",
      "receivableActivityID": 6053,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "5d343d41-1855-423d-968a-8fe50e762978",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "Miscellaneous Enter Distrib.",
      "receivableActivityID": 6072,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Wollongong Hospital",
      "page": "Misc",
      "ID": "2f50b524-d707-4123-a812-d36df54744f5",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "NS",
      "priority": null,
      "source": "stafflink",
      "activityName": "TWH First Step Vending Machine",
      "receivableActivityID": 6073,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Needle and Syringe Program",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "David Berry Hospital",
      "page": "Misc",
      "ID": "811c3917-16de-4bd4-8bb9-0563386fddb1",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SG",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBH Sale Old Wares",
      "receivableActivityID": 6080,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Sales - Other GST",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shoalhaven District Memorial Hospital",
      "page": "Misc",
      "ID": "ef20746f-dd7e-42fb-8c02-febba8829d79",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SG",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDM Sale Old Wares",
      "receivableActivityID": 6082,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Sales - Other GST",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Milton Ulladulla Hospital",
      "page": "Misc",
      "ID": "0f5fafb8-227e-4aca-839c-75e1913cf42a",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HP",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUH Physio Equip Hire",
      "receivableActivityID": 6097,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Equipment Hire - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "David Berry Hospital",
      "page": "Misc",
      "ID": "f9513230-72e6-4f9b-b378-b6b57ab9f39f",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBH Equipment Hire",
      "receivableActivityID": 6098,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shoalhaven District Memorial Hospital",
      "page": "Misc",
      "ID": "607d28f0-2fdd-4d46-9ee0-2a4c098c3f0c",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SP",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDM Pain Management Purchases",
      "receivableActivityID": 6102,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Sales - Pain Management Clinic",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shellharbour Hospital",
      "page": "Misc",
      "ID": "2735254e-085e-43bc-b32d-44a29a6c91e2",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "SHH Subpoena",
      "receivableActivityID": 7001,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Wollongong Hospital",
      "page": "Misc",
      "ID": "8d324861-750b-4a8b-82b8-0bb5f5b16ca1",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for MRI services for Outpatients",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "TWH MRI Outpatient",
      "receivableActivityID": 7013,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "MRI Services",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shoalhaven District Memorial Hospital",
      "page": "Misc",
      "ID": "1e6f69af-727a-44ae-b66b-a6698ca56900",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for podiatry products such as Orthotic insoles, foot braces and supports",
      "prefix": "PO",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDM Podiatry Sales",
      "receivableActivityID": 93002,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Podiatry",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Illawarra Shoalhaven Local Health District",
      "state": "NSW",
      "city": "PORT KEMBLA",
      "hospital": "Shoalhaven District Memorial Hospital",
      "page": "Misc",
      "ID": "bbfdf08a-b5da-4705-8b36-28cef483db50",
      "postalCode": "2505",
      "address1": "Finance Services",
      "address2": "PO Box 239",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDM SCCC Accommodation",
      "receivableActivityID": 93003,
      "healthEntityCode": "ISLHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "13 567 011 035",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Justice Health and Forensic Mental Health Network",
      "state": "NSW",
      "city": "MATRAVILLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "03f7b806-6af7-4775-9e76-24793f9b8f4b",
      "postalCode": "2036",
      "address1": "PO Box 150",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHFMHN Pharmacy",
      "receivableActivityID": 434088,
      "healthEntityCode": "JH",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 194 595 506",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Justice Health and Forensic Mental Health Network",
      "state": "NSW",
      "city": "MATRAVILLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "1ea2b8fe-02fa-4ce4-bf98-e70d4a4a9fa1",
      "postalCode": "2036",
      "address1": "PO Box 150",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training such as antenatal classes and diabetes courses.",
      "prefix": "ED",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHFMHN Education Training",
      "receivableActivityID": 434089,
      "healthEntityCode": "JH",
      "activityNameDisplay": "Education/Training",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 194 595 506",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Justice Health and Forensic Mental Health Network",
      "state": "NSW",
      "city": "MATRAVILLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "9c6b60ff-1b3f-4bd5-87ac-2306d71c3d95",
      "postalCode": "2036",
      "address1": "PO Box 150",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHFMHN Equipment Sales",
      "receivableActivityID": 434090,
      "healthEntityCode": "JH",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 194 595 506",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Justice Health and Forensic Mental Health Network",
      "state": "NSW",
      "city": "MATRAVILLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "a20c6294-8938-4731-80bb-9b4fde0806f1",
      "postalCode": "2036",
      "address1": "PO Box 150",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "GIPA application fee for access to information held by the Health Organisation, excluding health records managed by facilities",
      "prefix": "GF",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHFMHN GIPA Processing Fees",
      "receivableActivityID": 434094,
      "healthEntityCode": "JH",
      "activityNameDisplay": "GIPA - Government Information (Public Access)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 194 595 506",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Justice Health and Forensic Mental Health Network",
      "state": "NSW",
      "city": "MATRAVILLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "5590578d-f5f0-4006-ba4a-bd2068bb0914",
      "postalCode": "2036",
      "address1": "PO Box 150",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHFMHN Medical Records",
      "receivableActivityID": 434096,
      "healthEntityCode": "JH",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 194 595 506",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Justice Health and Forensic Mental Health Network",
      "state": "NSW",
      "city": "MATRAVILLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "554db245-b6bf-48c0-be79-ef8efbd214b7",
      "postalCode": "2036",
      "address1": "PO Box 150",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "JHFMHN Subpoena Health Records",
      "receivableActivityID": 434098,
      "healthEntityCode": "JH",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 194 595 506",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Justice Health and Forensic Mental Health Network",
      "state": "NSW",
      "city": "MATRAVILLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "d124182c-946e-429d-ad69-3d75b059d38f",
      "postalCode": "2036",
      "address1": "PO Box 150",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "Miscellaneous",
      "receivableActivityID": 5156,
      "healthEntityCode": "JH",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 194 595 506",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "ed67b4ca-a12c-49bb-b0b9-f77facd51a55",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "Miscellaneous",
      "receivableActivityID": 15000,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Deniliquin Health Service",
      "page": "Misc",
      "ID": "b312f54f-8501-4e67-98b0-d4023076df17",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "DEN Equipment Sales Deniliquin",
      "receivableActivityID": 214016,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Wagga Wagga Base Hospital",
      "page": "Misc",
      "ID": "7e65daea-12ac-4782-af63-037db10b4303",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAG Prenatal Classes",
      "receivableActivityID": 273012,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Wagga Wagga Base Hospital",
      "page": "Misc",
      "ID": "ade94443-af35-4866-99c8-1fadbd11c1b8",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAG Medical Records Admin",
      "receivableActivityID": 273014,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "c5f268a8-9a9c-48ac-a925-a17400040f48",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "GIPA application fee for access to information held by the Health Organisation, excluding health records managed by facilities",
      "prefix": "GF",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUR GIPA Processing Fees",
      "receivableActivityID": 434093,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "GIPA - Government Information (Public Access)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Barham MPS",
      "page": "Misc",
      "ID": "b74df71f-238c-48dd-93e7-def9fbe02b6c",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BAR Pharmacy",
      "receivableActivityID": 434102,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Barham MPS",
      "page": "Misc",
      "ID": "2f69faaf-596a-4ff9-9232-6d704c38d11c",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "BAR Equipment Sales Barham",
      "receivableActivityID": 434103,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Barham MPS",
      "page": "Misc",
      "ID": "e0548af4-1c48-4b65-b7f5-d7a84ffcacfd",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "BAR Staff Meals and Accom",
      "receivableActivityID": 434104,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Batlow MPS",
      "page": "Misc",
      "ID": "ecf17323-f715-456c-9d55-182ffc577375",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "BAT Staff Meals and Accom",
      "receivableActivityID": 434108,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Berrigan MPS",
      "page": "Misc",
      "ID": "899e469f-1170-4ba6-8b68-da2a7c7cc383",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "BER Equipment Sales Berrigan",
      "receivableActivityID": 434112,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Berrigan MPS",
      "page": "Misc",
      "ID": "49545c4f-a705-44ae-9104-18892dc575d6",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "BER Staff Meals and Accom",
      "receivableActivityID": 434113,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Boorowa MPS",
      "page": "Misc",
      "ID": "7a1e943e-7598-4662-aa59-67c756f94be0",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BOO Pharmacy",
      "receivableActivityID": 434120,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Boorowa MPS",
      "page": "Misc",
      "ID": "48c8ff36-f7f7-482a-8b09-a20f61aa191f",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "BOO Staff Meals and Accom",
      "receivableActivityID": 434121,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Coolamon MPS",
      "page": "Misc",
      "ID": "22fb813b-d083-4598-be67-e573663086d8",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "COOL Staff Meals and Accom",
      "receivableActivityID": 434126,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Cootamundra Health Service",
      "page": "Misc",
      "ID": "67e4f690-8748-4919-9983-3a4fa3751ade",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "COO Pharmacy",
      "receivableActivityID": 434128,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Cootamundra Health Service",
      "page": "Misc",
      "ID": "d600de57-6fbb-4c03-9732-c055c6bd00a7",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "COO Equipment Sales",
      "receivableActivityID": 434129,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Cootamundra Health Service",
      "page": "Misc",
      "ID": "fffa43e1-ffd3-44b3-91b1-9a14795a3de0",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "COO Staff Meals and Accom",
      "receivableActivityID": 434130,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Cootamundra Health Service",
      "page": "Misc",
      "ID": "9a070577-65e0-4d3d-bb2a-6dd04e9b7fbf",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "COO Medical Records",
      "receivableActivityID": 434131,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Corowa MPS",
      "page": "Misc",
      "ID": "b07cbf27-312b-4255-930c-f38da5e3b4c0",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "COR Pharmacy",
      "receivableActivityID": 434132,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Corowa MPS",
      "page": "Misc",
      "ID": "6b7638af-8e0b-4052-9ee7-e837b6dbb96d",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "COR Equipment Sales",
      "receivableActivityID": 434133,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Corowa MPS",
      "page": "Misc",
      "ID": "f71efaf3-6998-4a79-83d1-d4bf36b728ea",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "COR Staff Meals and Accom",
      "receivableActivityID": 434134,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Hay Hospital",
      "page": "Misc",
      "ID": "ca030cc4-936c-4e0f-85fb-e071a88472cc",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "HAY Pharmacy Hay Hospital",
      "receivableActivityID": 434171,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Hay Hospital",
      "page": "Misc",
      "ID": "a8c51c38-f133-4636-8d97-a2262616e863",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "HAY Equipment Sales Hay",
      "receivableActivityID": 434172,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Hay Hospital",
      "page": "Misc",
      "ID": "51c827bb-9131-4157-a299-b5caf0533a94",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "HAY Staff Meals and Accom",
      "receivableActivityID": 434173,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Hay Hospital",
      "page": "Misc",
      "ID": "fe326ef6-2cbc-4361-86cf-32dec4ae6200",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "HAY Medical Records",
      "receivableActivityID": 434174,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Lake Cargelligo MPS",
      "page": "Misc",
      "ID": "f0ebc0a7-1b5f-4bd0-bb5a-3f25bdd5bc75",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "LAK Equipment Lake Cargelligo",
      "receivableActivityID": 434194,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Lake Cargelligo MPS",
      "page": "Misc",
      "ID": "97d7087b-c2c7-479a-a31b-09c69e124659",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "LAK Medical Records",
      "receivableActivityID": 434195,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Leeton MPS",
      "page": "Misc",
      "ID": "6a123f5a-e019-4813-aa2b-c5b203061c02",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "LEE Pharmacy Leeton MPS",
      "receivableActivityID": 434196,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Leeton MPS",
      "page": "Misc",
      "ID": "2e3ca7a5-66ff-4fa6-9435-2e975044950a",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "LEE Medical Records",
      "receivableActivityID": 434197,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Leeton MPS",
      "page": "Misc",
      "ID": "11c38371-8557-4a1f-bb4d-9925fb93dd53",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "LEE Staff Meals and Accom",
      "receivableActivityID": 434198,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Lockhart MPS",
      "page": "Misc",
      "ID": "192daf20-b034-4b6f-820e-b1f4256789d1",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "LOC Staff Meals and Accom",
      "receivableActivityID": 434200,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Murrumburrah-Harden MPS",
      "page": "Misc",
      "ID": "583dd797-5a9b-4933-838b-0633c30187d7",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUR Pharmacy Murrum Harden MPS",
      "receivableActivityID": 434212,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Murrumburrah-Harden MPS",
      "page": "Misc",
      "ID": "b19adf26-42e9-4ae4-8edc-16c4cbb0389c",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUR Staff Meals and Accom",
      "receivableActivityID": 434213,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Murrumburrah-Harden MPS",
      "page": "Misc",
      "ID": "a92ca2fc-ebd3-4618-be0d-711575ddbe32",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUR Medical Records",
      "receivableActivityID": 434214,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Murrumburrah-Harden MPS",
      "page": "Misc",
      "ID": "6491cd31-7c89-47db-a067-7af882861b16",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUR Subpeona Health Records",
      "receivableActivityID": 434215,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Narrandera Health Service",
      "page": "Misc",
      "ID": "b0ce57fa-ffff-4645-8fae-9abd31192f76",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "NAR Pharmacy Narrandera",
      "receivableActivityID": 434221,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Narrandera Health Service",
      "page": "Misc",
      "ID": "70039fce-90be-408a-920c-d6e429413ad1",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "NAR Staff Meals and Accom",
      "receivableActivityID": 434222,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Narrandera Health Service",
      "page": "Misc",
      "ID": "95183b8a-8ff0-46d9-a69e-42ac46affcfd",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "NAR Medical Records",
      "receivableActivityID": 434223,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "South West Brain Injury Rehabilitation",
      "page": "Misc",
      "ID": "97a76b4a-87a0-41d6-8424-d5096e15bd97",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "SWB Pharmacy SW Brain Injury",
      "receivableActivityID": 434233,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "South West Brain Injury Rehabilitation",
      "page": "Misc",
      "ID": "4a80ef62-d8eb-4534-a0bc-814c40fcfdb4",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "SWS Medical Records",
      "receivableActivityID": 434234,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Temora Health Service",
      "page": "Misc",
      "ID": "b39ecbc3-1e5b-4baa-9dc6-e40eb4a4a43d",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "TEM Pharmacy Temora Health",
      "receivableActivityID": 434236,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Temora Health Service",
      "page": "Misc",
      "ID": "15b66704-a31b-46c2-88d7-b8fbedb1eeb4",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "TEM Equipment Sales",
      "receivableActivityID": 434237,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Temora Health Service",
      "page": "Misc",
      "ID": "9dd5eefa-0e0b-48c7-a5ce-c1824dda966d",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "TEM Staff Meals and Accom",
      "receivableActivityID": 434238,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Temora Health Service",
      "page": "Misc",
      "ID": "32901707-0a34-4528-a71a-7d01b197865c",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "TEM Medical Records",
      "receivableActivityID": 434239,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Tocumwal MPS",
      "page": "Misc",
      "ID": "b9dd1c17-31a3-4e4a-80d2-cd9e825f0b4e",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "TOC Equipment Sales Tocumwal",
      "receivableActivityID": 434240,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Tocumwal MPS",
      "page": "Misc",
      "ID": "6ad11d89-91af-47d7-8ea9-1d76251b0b5a",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "TOC Staff Meals and Accom",
      "receivableActivityID": 434241,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Tumbarumba MPS",
      "page": "Misc",
      "ID": "054243b9-0680-4092-b957-10301336509c",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "TUM Staff Meals and Accom",
      "receivableActivityID": 434246,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Tumut Health Service",
      "page": "Misc",
      "ID": "5bc9a7ce-12e1-4b53-9230-3c3f8a1155c2",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "TMT Pharmacy Tumut MPS",
      "receivableActivityID": 434247,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Tumut Health Service",
      "page": "Misc",
      "ID": "e853d9b8-a823-41f0-9787-35372087a25f",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "TMT Equipment Sales Tumut",
      "receivableActivityID": 434248,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Tumut Health Service",
      "page": "Misc",
      "ID": "4913aa97-522c-4cce-aa64-edc08a323e05",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "TMT Staff Meals and Accom",
      "receivableActivityID": 434249,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Urana MPS",
      "page": "Misc",
      "ID": "b36484be-ffae-41bf-a85f-8a918a575616",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "URA Staff Meals and Accom",
      "receivableActivityID": 434250,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Wagga Wagga Base Hospital",
      "page": "Misc",
      "ID": "b841009e-6d7d-4629-ab57-51b1c355af21",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAG Equipment Sales Wagga",
      "receivableActivityID": 434251,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Wagga Wagga Base Hospital",
      "page": "Misc",
      "ID": "7e529645-460c-42c6-bece-65650ea9ddad",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAG Staff Meals and Accom",
      "receivableActivityID": 434252,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Wagga Wagga Base Hospital",
      "page": "Misc",
      "ID": "460a28ba-c51e-488f-a607-94ad99e5edc9",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAG Subpeona Health Records",
      "receivableActivityID": 434253,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Wyalong Health Service",
      "page": "Misc",
      "ID": "23871df5-2c5c-4266-a3e4-75f62d55edcd",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "WYA Pharmacy Wyalong Health",
      "receivableActivityID": 434271,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Wyalong Health Service",
      "page": "Misc",
      "ID": "c5e6f02e-a36b-46fa-ab70-90cbe5b9a2fa",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "WYA Equipment Sales",
      "receivableActivityID": 434272,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Wyalong Health Service",
      "page": "Misc",
      "ID": "c00eac1d-769f-4634-bef5-ed6b47121f17",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "WYA Staff Meals and Accom",
      "receivableActivityID": 434273,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Wyalong Health Service",
      "page": "Misc",
      "ID": "b9205891-2903-44dd-9aa7-4a3cad71036f",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "WYA Medical Records",
      "receivableActivityID": 434274,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Young Health Service",
      "page": "Misc",
      "ID": "1d56401b-58c3-43b3-9027-d06bfec72e48",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "YOU Pharmacy Young Health",
      "receivableActivityID": 434276,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Young Health Service",
      "page": "Misc",
      "ID": "896942b6-85e9-4547-a370-6edf79b93467",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "YOU Equipment Sales Young",
      "receivableActivityID": 434277,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Young Health Service",
      "page": "Misc",
      "ID": "b056724e-6334-4a38-b6f4-672ec1e658db",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "YOU Staff Meals and Accom",
      "receivableActivityID": 434278,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Young Health Service",
      "page": "Misc",
      "ID": "71c08ed6-1cef-4c04-ba95-afe03d98b74b",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "YOU Medical Records",
      "receivableActivityID": 434279,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Young Health Service",
      "page": "Misc",
      "ID": "c61a111d-d5a4-4369-b1b4-c689ee27149d",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "YOU Subpeona Health Records",
      "receivableActivityID": 434280,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Coolamon MPS",
      "page": "Misc",
      "ID": "c05ccbeb-b79e-491b-ba5b-35ccb7610f64",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "COOL Medical Records Coolamon",
      "receivableActivityID": 453017,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Culcairn MPS",
      "page": "Misc",
      "ID": "629f2707-b1d7-4889-a1eb-d546686a0367",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "CUL Med Records Culcairn MPS",
      "receivableActivityID": 453018,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Henty MPS",
      "page": "Misc",
      "ID": "a6b311d1-8c2f-44ef-94cf-76e87e28e5df",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "HEN Medical Records Henty MPS",
      "receivableActivityID": 453019,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Jerilderie MPS",
      "page": "Misc",
      "ID": "c326c0b4-9ec4-4b6e-bff0-350cda3ee9a3",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "JER Med Records Jerilderie MPS",
      "receivableActivityID": 453020,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Lockhart MPS",
      "page": "Misc",
      "ID": "374069e9-d6e0-4ef2-b8f2-ac6330b47de1",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "LOC Med Records Lockhart MPS",
      "receivableActivityID": 453021,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Tocumwal MPS",
      "page": "Misc",
      "ID": "75b36a52-c671-4bc2-98aa-5ddc22963dff",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "TOC Med Records Tocumwal MPS",
      "receivableActivityID": 453022,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Tumbarumba MPS",
      "page": "Misc",
      "ID": "7ebe3488-2f7f-440f-bab7-fdee61fad627",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "TUM Med Records Tumbarumba",
      "receivableActivityID": 453023,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Tumut Health Service",
      "page": "Misc",
      "ID": "aef76dac-dfbc-4160-ac90-37117d1d7068",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "TMT Medical Records Tumut MPS",
      "receivableActivityID": 453024,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Hillston MPS",
      "page": "Misc",
      "ID": "9ebd52f4-e209-40ff-bbbe-5536cad89725",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "HIL Subpoena Hillston MPS",
      "receivableActivityID": 453038,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Holbrook MPS",
      "page": "Misc",
      "ID": "8644186f-56ac-44b7-9ecc-ee3b72fb15ff",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "HOL Subpoena Holbrook MPS",
      "receivableActivityID": 453039,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Jerilderie MPS",
      "page": "Misc",
      "ID": "4db22160-c690-4022-b739-053969487764",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "JER Subpoena Jerilderie MPS",
      "receivableActivityID": 453040,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Finley Health Service",
      "page": "Misc",
      "ID": "ff102789-8aea-476a-8547-9f6addf0a292",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "FIN Pharmacy Finley Health",
      "receivableActivityID": 454018,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Gundagai MPS",
      "page": "Misc",
      "ID": "20988f86-e7f8-4151-85a6-de25d48f8842",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "GUN Pharmacy Gundagai MPS",
      "receivableActivityID": 454019,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Henty MPS",
      "page": "Misc",
      "ID": "aea71431-3c29-43fa-a05e-9b4c481a48e3",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "HEN Pharmacy Henty MPS",
      "receivableActivityID": 454020,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Holbrook MPS",
      "page": "Misc",
      "ID": "00141e55-f597-43a2-bfe1-0ee097a83ea5",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "HOL Pharmacy Holbrook MPS",
      "receivableActivityID": 454021,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Jerilderie MPS",
      "page": "Misc",
      "ID": "347e0346-427f-4b31-99e6-85b3e9939d16",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "JER Pharmacy Jerilderie MPS",
      "receivableActivityID": 454022,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "805c5634-9633-4bca-98a2-e77ca40aebc2",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Payment of Service Fee provided by Public Health Unit",
      "prefix": "PH",
      "priority": null,
      "source": "stafflink",
      "activityName": "MLHD Public Health fees",
      "receivableActivityID": 473016,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Public Health Unit",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Narrandera Health Service",
      "page": "Misc",
      "ID": "b7edb79d-bac5-4a4e-b015-784e76e80082",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AK",
      "priority": null,
      "source": "stafflink",
      "activityName": "NAR Lost Key Fee",
      "receivableActivityID": 495023,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Accommodation - Key Deposit",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Wagga Wagga Base Hospital",
      "page": "Misc",
      "ID": "84ca5289-2bd9-4333-baca-6f184e39f552",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAG A & E Crutches",
      "receivableActivityID": 8008,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Griffith Base Hospital",
      "page": "Misc",
      "ID": "f3f7ae18-6aaa-4598-8887-5c5196e87db3",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "GRI Medical Records",
      "receivableActivityID": 8011,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Griffith Base Hospital",
      "page": "Misc",
      "ID": "c84e0f4c-b61d-4c88-acb5-37fe4fad0303",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "GRI Relatives O/N Meals/Accom",
      "receivableActivityID": 8019,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Griffith Base Hospital",
      "page": "Misc",
      "ID": "9b078d0e-a9b6-4402-83e5-ebb436c9d07a",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "GRI Pharmacy sales",
      "receivableActivityID": 8021,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Wagga Wagga Base Hospital",
      "page": "Misc",
      "ID": "25582846-ebc6-4698-9a23-34e9755398d1",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAG Pharmacy Wagga Base Hosp",
      "receivableActivityID": 8023,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Griffith Base Hospital",
      "page": "Misc",
      "ID": "ae48ef2b-4b56-411e-8583-92d6ff78f1c1",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "GRI A&E Equipment Sales",
      "receivableActivityID": 8025,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Wagga Wagga Base Hospital",
      "page": "Misc",
      "ID": "f370bdd7-c5d5-4dd0-8d5d-affe7ec237fb",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAG A&E Equipment Sales",
      "receivableActivityID": 8027,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Murrumbidgee Local Health District",
      "state": "NSW",
      "city": "WAGGA WAGGA",
      "hospital": "Wagga Wagga Base Hospital",
      "page": "Misc",
      "ID": "3a342a27-7880-4860-afa6-2aa4452614da",
      "postalCode": "2650",
      "address1": "Locked Bag 10",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for radiology services for outpatients",
      "prefix": "RF",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAG Radiology X-Ray copy",
      "receivableActivityID": 8030,
      "healthEntityCode": "MLHD",
      "activityNameDisplay": "Radiology Fee",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "71 172 428 618",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "24c11860-0e57-48fc-983f-66336027e1c4",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for parking fees and services",
      "prefix": "PF",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Car Park",
      "receivableActivityID": 116004,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Parking Fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Macksville District Hospital",
      "page": "Misc",
      "ID": "56bb96a8-8779-4f30-a61b-bb7d0890dacf",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAC Physio Equipment Sale",
      "receivableActivityID": 480030,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Macksville District Hospital",
      "page": "Misc",
      "ID": "6a4706c7-a1c1-4420-9807-aeeb79563861",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAC Subpoena",
      "receivableActivityID": 480031,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Macksville District Hospital",
      "page": "Misc",
      "ID": "82f4ffdf-8a22-4e39-85ce-b649187cf02f",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAC ED Equipment Sale",
      "receivableActivityID": 480032,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Dorrigo Plateau Multi-Purpose Service",
      "page": "Misc",
      "ID": "593773c0-1151-4a9a-954a-8e69275da325",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "DOR Fee for Subpoena",
      "receivableActivityID": 480033,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Dorrigo Plateau Multi-Purpose Service",
      "page": "Misc",
      "ID": "4029c7fa-d211-4bbe-aaa6-8aa6a07e53a9",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "EG",
      "priority": null,
      "source": "stafflink",
      "activityName": "DOR Equipment Physio",
      "receivableActivityID": 480034,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Sales - Physio (GST)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "0382d505-56be-4ffa-a15d-8e9eb18ec803",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Ante Natal Classes",
      "receivableActivityID": 480035,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "fb4d3e42-ff4e-49da-a540-d0fcabd29d4a",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HO",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Equipment Hire - OT",
      "receivableActivityID": 480036,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Hire - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "43fe7071-bc31-41a1-abb3-bac81803a40e",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HP",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Equipment Hire Physio",
      "receivableActivityID": 480037,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Hire - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "3e55b55d-6247-4628-90d1-5de9f0847ac1",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EF",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Equipment Fracture",
      "receivableActivityID": 480038,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Sales - Fracture Clinic",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Port Macquarie Base Hospital",
      "page": "Misc",
      "ID": "146e257d-3269-4d17-bdc0-bdd2bc192969",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "PMQ- Antenatal Classes",
      "receivableActivityID": 480039,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Port Macquarie Base Hospital",
      "page": "Misc",
      "ID": "2d3ce280-5faa-490c-b398-342924cf5e85",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for TV Rental",
      "prefix": "TV",
      "priority": null,
      "source": "stafflink",
      "activityName": "PMQ Television Revenue",
      "receivableActivityID": 480040,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "TV Rental",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Macksville District Hospital",
      "page": "Misc",
      "ID": "674d4de3-8e1e-4dc1-adf7-ae4aa879af34",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AT",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAC Tresillian Payments",
      "receivableActivityID": 490020,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Accommodation - Tresillian",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Kempsey Hospital",
      "page": "Misc",
      "ID": "af71200b-f99e-42db-bda8-fdb62b1f51c1",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "EM",
      "priority": null,
      "source": "stafflink",
      "activityName": "KDH Mat. Equip Hire Deposits",
      "receivableActivityID": 490021,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Hire - Maternity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Kempsey Hospital",
      "page": "Misc",
      "ID": "aa21364d-afae-4cd4-944f-7b6a532cf5b7",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "KDH Mat. Equipment Sales",
      "receivableActivityID": 490022,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Sales - Maternity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Kempsey Hospital",
      "page": "Misc",
      "ID": "0142b0d8-1dc4-4397-a084-45e56de4c27c",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "KDH Phys.Equipment Sales",
      "receivableActivityID": 490023,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Kempsey Hospital",
      "page": "Misc",
      "ID": "9966ff65-6b9f-4d38-b023-c4450f3abf99",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EF",
      "priority": null,
      "source": "stafflink",
      "activityName": "KDH Equip Sales Fibre Cst.",
      "receivableActivityID": 490024,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Sales - Fracture Clinic",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Kempsey Hospital",
      "page": "Misc",
      "ID": "e39e0c72-d3f6-47ae-874b-1bfcbf9c2f9d",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "KDH Sales Equip ED",
      "receivableActivityID": 490025,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Kempsey Hospital",
      "page": "Misc",
      "ID": "9691f11d-dcaa-429e-afa9-698c7e35d505",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "ET",
      "priority": null,
      "source": "stafflink",
      "activityName": "KDH DENTAL Tooth Moouse",
      "receivableActivityID": 490026,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Sales - Tooth Moouse",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Wauchope District Memorial Hospital",
      "page": "Misc",
      "ID": "f9704380-0fba-4fb4-bc9b-0300beaed344",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "EG",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAU Equipment Sales",
      "receivableActivityID": 490027,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Sales - Physio (GST)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Port Macquarie Base Hospital",
      "page": "Misc",
      "ID": "80d02c2c-7cc6-43dc-831c-649b69b39299",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "NS",
      "priority": null,
      "source": "stafflink",
      "activityName": "PMQ NSP Deposits",
      "receivableActivityID": 490028,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Needle and Syringe Program",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Dorrigo Plateau Multi-Purpose Service",
      "page": "Misc",
      "ID": "9e14a5f6-2cce-41cf-b8db-52115cab05fc",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "RD",
      "priority": null,
      "source": "stafflink",
      "activityName": "DOR Equip Hire Refundable Dep",
      "receivableActivityID": 490029,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Hire - Refundable Deposits",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Dorrigo Plateau Multi-Purpose Service",
      "page": "Misc",
      "ID": "3c230952-75e7-43ae-af00-250e63214fd2",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "HC",
      "priority": null,
      "source": "stafflink",
      "activityName": "DOR Homecare",
      "receivableActivityID": 490030,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Homecare",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Dorrigo Plateau Multi-Purpose Service",
      "page": "Misc",
      "ID": "78c80181-7a30-4922-99cf-68f1afc0b52d",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "DOR Meals",
      "receivableActivityID": 490031,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Dorrigo Plateau Multi-Purpose Service",
      "page": "Misc",
      "ID": "3edcf446-a389-415f-892b-4d52519d6091",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DT",
      "priority": null,
      "source": "stafflink",
      "activityName": "DOR Patient Purchase",
      "receivableActivityID": 490032,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Dietetics / Nutritional Supplements",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Dorrigo Plateau Multi-Purpose Service",
      "page": "Misc",
      "ID": "e04c4a0c-abdb-4598-b64a-49c65796384a",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AR",
      "priority": null,
      "source": "stafflink",
      "activityName": "DOR Hospital House Rent",
      "receivableActivityID": 490033,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Accommodation - Rental",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "3e7187da-e52d-4804-84cf-92f40c315993",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "BR",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Sundry Income - Bike Rack",
      "receivableActivityID": 490034,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Sundry Income - Bike Rack",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "ea06bea9-4328-41b2-b9c2-1f5e1c701e56",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SP",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Sundry Income - Postage",
      "receivableActivityID": 490035,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Sundry Income - Postage",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Port Macquarie Base Hospital",
      "page": "Misc",
      "ID": "b22ba8b0-9a59-4e2c-9417-bd47a4fbb915",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "PMQ - JMO Accomodation",
      "receivableActivityID": 490036,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Accommodation - Cleaning and Utility Fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Port Macquarie Base Hospital",
      "page": "Misc",
      "ID": "6a7d491f-13f8-467f-bcf3-0a9abfe26f64",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DC",
      "priority": null,
      "source": "stafflink",
      "activityName": "PMQ Delta EV Cards",
      "receivableActivityID": 490038,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Delta EV Cards",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Port Macquarie Base Hospital",
      "page": "Misc",
      "ID": "e67b7fb6-2ec7-4bf5-b638-b0e3a8d85153",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SP",
      "priority": null,
      "source": "stafflink",
      "activityName": "PMQ Sale of Polo Shirts",
      "receivableActivityID": 490039,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Sales - Polo Shirts",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Port Macquarie Base Hospital",
      "page": "Misc",
      "ID": "a46f780d-1d2b-4e1a-a657-eb3d87e28e19",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SD",
      "priority": null,
      "source": "stafflink",
      "activityName": "PMQ Sale of disposed assets",
      "receivableActivityID": 490040,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Sales - Disposed assets",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Port Macquarie Base Hospital",
      "page": "Misc",
      "ID": "93a70689-d871-4d83-9413-95ab77b4c7b6",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "UM",
      "priority": null,
      "source": "stafflink",
      "activityName": "PMQ Unicard machine",
      "receivableActivityID": 490041,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Unicard Machine",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "fd3793ad-06a3-488f-88ca-4352159e01db",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your mental health medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MC",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Medical Records M/Health",
      "receivableActivityID": 497024,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Medical Records - Mental Health",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "6c8cfe35-dc06-4f6a-a95d-4d02e05c7207",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SM",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Subpoena Mental Health",
      "receivableActivityID": 497029,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Subpoena – Mental Health",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Bellinger River District Hospital",
      "page": "Misc",
      "ID": "af8ff6b3-d660-4b6b-b091-ec6ccf5a6228",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "BEL Equipment Hire",
      "receivableActivityID": 5159,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Dorrigo Plateau Multi-Purpose Service",
      "page": "Misc",
      "ID": "0b332a6c-88f5-4ec0-9bdb-498a877ad980",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "DOR Equip Rent",
      "receivableActivityID": 5160,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "cc219beb-fd7a-40a1-bae2-809bc34932c6",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Loan Equipment Hire Fee",
      "receivableActivityID": 5162,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Macksville District Hospital",
      "page": "Misc",
      "ID": "8665651b-a79e-401d-be77-c61c9fd2c308",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAC Sundry Income",
      "receivableActivityID": 5173,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Bellinger River District Hospital",
      "page": "Misc",
      "ID": "6bc5e4cf-d81f-42a2-97da-4ec8dccbc43c",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "BEL Sundry Income",
      "receivableActivityID": 5175,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Dorrigo Plateau Multi-Purpose Service",
      "page": "Misc",
      "ID": "9f39aa04-3339-40c3-9fd1-ccc8c1edea38",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "DOR Sundry Income",
      "receivableActivityID": 5176,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Kempsey Hospital",
      "page": "Misc",
      "ID": "344b6217-0c89-4ca7-a2a2-e5daa74f6137",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "KEM Sundry Income",
      "receivableActivityID": 5177,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "9fa63f91-8536-4b0d-95fe-acd5972be62c",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Sundry Income",
      "receivableActivityID": 5178,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "f8fca131-2567-42f3-aee3-d2b378b74d31",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "MNC ZSundry",
      "receivableActivityID": 5179,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Macksville District Hospital",
      "page": "Misc",
      "ID": "8259541e-ec76-44cb-bd3b-307db9c69a8e",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EO",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAC Mobility Aids",
      "receivableActivityID": 5202,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Sales - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Dorrigo Plateau Multi-Purpose Service",
      "page": "Misc",
      "ID": "f66eaafe-aa8a-4547-86d7-856b17282606",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "DOR Fees for Medical Recds",
      "receivableActivityID": 5205,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Wauchope District Memorial Hospital",
      "page": "Misc",
      "ID": "46a47ec1-6752-4e1f-8582-b9b8040bf353",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAU Fees for Med Rec",
      "receivableActivityID": 5206,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Wauchope District Memorial Hospital",
      "page": "Misc",
      "ID": "8800d152-6083-41d3-a607-bf7a1d94b46b",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAU Fees Subpoena",
      "receivableActivityID": 5207,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Bellinger River District Hospital",
      "page": "Misc",
      "ID": "d10a4d7f-5f1f-4389-b96d-0db0ed089dd2",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BEL Medical Records",
      "receivableActivityID": 5210,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Macksville District Hospital",
      "page": "Misc",
      "ID": "b91769c4-f09e-489c-af67-3a4c84551e5a",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAC Fees for Medical Recds",
      "receivableActivityID": 5211,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Port Macquarie Base Hospital",
      "page": "Misc",
      "ID": "ab375345-3787-4198-ab87-da99ab7be9cb",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "PMQ Fees for Subpoenas",
      "receivableActivityID": 5213,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Kempsey Hospital",
      "page": "Misc",
      "ID": "b5f253c3-b40e-488f-bd77-620beb0c82a5",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "KEM Medical Records",
      "receivableActivityID": 5214,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Port Macquarie Base Hospital",
      "page": "Misc",
      "ID": "96079546-1af3-4f78-b677-2b45c4ed429d",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "PMQ Fees for Medical Recds",
      "receivableActivityID": 5215,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "e61c20a1-0e20-4548-a15d-62dc3be4d0a8",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Fees Medical Records",
      "receivableActivityID": 5216,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Macksville District Hospital",
      "page": "Misc",
      "ID": "77716d88-4a9d-46b8-9250-4fe556bc9103",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAC Meals & Accomodation",
      "receivableActivityID": 5219,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Macksville District Hospital",
      "page": "Misc",
      "ID": "75f150d8-8787-4249-8f4c-5b45fb7fe814",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "TK",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAC Theatre Pharmacy",
      "receivableActivityID": 5225,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Theatre kits",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "25dc7051-2cda-4f86-9c0b-96358f2ba88a",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "BP",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Bowel Prep Kits",
      "receivableActivityID": 5229,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Sales - Bowel Prep Kit",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "fc809214-fe34-42c9-b6ad-e2c23c3cd783",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Pharmacy",
      "receivableActivityID": 5230,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Dorrigo Plateau Multi-Purpose Service",
      "page": "Misc",
      "ID": "efd154ee-ca29-40a1-aa7d-148d70fc0ceb",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "DOR Patient Purch Cruches",
      "receivableActivityID": 5241,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Port Macquarie Base Hospital",
      "page": "Misc",
      "ID": "31e50dea-5eed-4b4c-8fa0-b34749370d4e",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for library books",
      "prefix": "LB",
      "priority": null,
      "source": "stafflink",
      "activityName": "PMQ Library Sales",
      "receivableActivityID": 5242,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Sales - Library Books",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Dorrigo Plateau Multi-Purpose Service",
      "page": "Misc",
      "ID": "344f8d6c-fcca-4791-b107-f142e4d354a4",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "DOR Patient Purch Physio",
      "receivableActivityID": 5245,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Port Macquarie Base Hospital",
      "page": "Misc",
      "ID": "9ac415fe-5100-407a-82ae-a0dd547010d1",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "PMQ Equipment Sales Physio",
      "receivableActivityID": 5248,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "16fbaa18-3fbd-4c9c-8a0e-e6d7d420857d",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DT",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Dietician Patient Purchase",
      "receivableActivityID": 5251,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Dietetics / Nutritional Supplements",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "bcdc9ea5-7134-41b7-b3b8-f167631966f6",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Patient Purch ED",
      "receivableActivityID": 5252,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Bellinger River District Hospital",
      "page": "Misc",
      "ID": "50ed1559-d117-4e3d-8bd1-8145f98dcbec",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "RD",
      "priority": null,
      "source": "stafflink",
      "activityName": "BEL Refund Deposit Equipment",
      "receivableActivityID": 5267,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Hire - Refundable Deposits",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Wauchope District Memorial Hospital",
      "page": "Misc",
      "ID": "1a6e5af4-c5f8-4920-ae19-ee2a25bff987",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAU Sundry Income",
      "receivableActivityID": 5273,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Dorrigo Plateau Multi-Purpose Service",
      "page": "Misc",
      "ID": "5fd7d102-8745-4d7c-a7db-66f1592ca7a4",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "TT",
      "priority": null,
      "source": "stafflink",
      "activityName": "DOR Countrylink",
      "receivableActivityID": 5276,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Train Ticket",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Port Macquarie Base Hospital",
      "page": "Misc",
      "ID": "b69794ea-cf6e-443b-bb1c-55b8ef9663f1",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "PMQ Sale of Old Wares",
      "receivableActivityID": 5277,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Kempsey Hospital",
      "page": "Misc",
      "ID": "274b6d68-125a-4a0d-892b-b4bbd6161dc6",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "KEM Sale Old Wares",
      "receivableActivityID": 5278,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Kempsey Hospital",
      "page": "Misc",
      "ID": "7e58c339-83b8-4f0e-a6ee-587315ec8685",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "KEM Physio Aids",
      "receivableActivityID": 5279,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Mid North Coast Cancer Institute",
      "page": "Misc",
      "ID": "c92d7b84-c6c0-4ba6-931a-36f97e53bd93",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAU Patient Purhcase Physio",
      "receivableActivityID": 5280,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Grafton Base Hospital",
      "page": "Misc",
      "ID": "807da484-a456-4621-95f3-b96bc21e2ff1",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "NS",
      "priority": null,
      "source": "stafflink",
      "activityName": "GRA NSP Deposits",
      "receivableActivityID": 5295,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Needle and Syringe Program",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Macksville District Hospital",
      "page": "Misc",
      "ID": "37e75d62-8a4b-4428-bcae-10a675961436",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "NS",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAC NSP Deposits",
      "receivableActivityID": 5296,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Needle and Syringe Program",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Lismore Base Hospital",
      "page": "Misc",
      "ID": "e2e6b342-fb96-4060-b78c-5389520c8cb9",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "NS",
      "priority": null,
      "source": "stafflink",
      "activityName": "LIS NSP Deposits",
      "receivableActivityID": 5297,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Needle and Syringe Program",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Ballina District Hospital",
      "page": "Misc",
      "ID": "6c197694-71d5-4c62-a323-91af455e0307",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "NS",
      "priority": null,
      "source": "stafflink",
      "activityName": "BAL NSP Deposits",
      "receivableActivityID": 5299,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Needle and Syringe Program",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Byron Central Hospital",
      "page": "Misc",
      "ID": "4d69c5f6-0757-4f44-b549-c90ff377cb38",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "NS",
      "priority": null,
      "source": "stafflink",
      "activityName": "BYR NSP Deposits",
      "receivableActivityID": 5300,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Needle and Syringe Program",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Murwillumbah District Hospital",
      "page": "Misc",
      "ID": "7f7513ec-a6cb-4b68-ba67-ab0c1aed6396",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "NS",
      "priority": null,
      "source": "stafflink",
      "activityName": "MUR NSP Deposits",
      "receivableActivityID": 5302,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Needle and Syringe Program",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "012212c6-eebd-45b4-97f2-cc98834c5c51",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "NS",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Vending Machine",
      "receivableActivityID": 5304,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Needle and Syringe Program",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "dd4a5775-0348-4f13-a531-8ba1bbe72a3a",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Patient Purchase Physio",
      "receivableActivityID": 5305,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "31c9c8c3-413f-48f1-98bb-00dccb7b0744",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SC",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Meals Staff Cafeteria",
      "receivableActivityID": 5306,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Sales - Staff Cafeteria",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "4832143c-ecd7-4a45-bf85-952a38ae29dc",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DP",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Dental Purchase",
      "receivableActivityID": 5308,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Sales - Dental products",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Kempsey Hospital",
      "page": "Misc",
      "ID": "5987dc12-8307-4b9f-99fc-b540f46e51a0",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for TV Rental",
      "prefix": "TV",
      "priority": null,
      "source": "stafflink",
      "activityName": "KEM Television Hire",
      "receivableActivityID": 5329,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "TV Rental",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Coffs Harbour Base Hospital",
      "page": "Misc",
      "ID": "c4f9fdd1-bca0-4e08-9989-a6e238f3901a",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for prosthetics",
      "prefix": "PR",
      "priority": null,
      "source": "stafflink",
      "activityName": "COF Prosthesis",
      "receivableActivityID": 5336,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Prosthetics",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Macksville District Hospital",
      "page": "Misc",
      "ID": "abd47e01-adbf-491a-978c-6eb4c2052a7b",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAC Refd Deposit Equipment",
      "receivableActivityID": 5349,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Wauchope District Memorial Hospital",
      "page": "Misc",
      "ID": "4b10df89-1d48-4948-acd5-07c105622acb",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "WAU Refund Deposit Equipment",
      "receivableActivityID": 5353,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Kempsey Hospital",
      "page": "Misc",
      "ID": "14d5f79e-0ae5-41a9-90fc-c5cfccf87c54",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "KEM Equipment Deposit",
      "receivableActivityID": 5354,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Port Macquarie Base Hospital",
      "page": "Misc",
      "ID": "25b3149f-5909-4b2e-95bb-8dded4fff22d",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "PMQ Equipment Hire Physio",
      "receivableActivityID": 5355,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Mid North Coast Local Health District",
      "state": "NSW",
      "city": "PORT MACQUARIE",
      "hospital": "Kempsey Hospital",
      "page": "Misc",
      "ID": "e8c327f2-2d5a-4252-98e0-f096b656cecd",
      "postalCode": "2444",
      "address1": "PO Box 126",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "KEM Fees for Subpoenas",
      "receivableActivityID": 60002,
      "healthEntityCode": "MNCLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "57 946 356 658",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Ministry of Health",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Ministry of Health",
      "page": "Misc",
      "ID": "f0d8bdaf-9743-4012-ab72-2bdb93807925",
      "postalCode": "2065",
      "address1": "Locked Bag 2030",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Application fee(s). Please quote identifier and payment type",
      "prefix": "LF",
      "priority": null,
      "source": "stafflink",
      "activityName": "Licence fees",
      "receivableActivityID": 114002,
      "healthEntityCode": "MOH",
      "activityNameDisplay": "Application/Licence Fee",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "92 697 899  630",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Ministry of Health",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Ministry of Health",
      "page": "Misc",
      "ID": "303cd5bb-55fa-4145-8f3a-7d70f59abd67",
      "postalCode": "2065",
      "address1": "Locked Bag 2030",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Blue Book",
      "prefix": "BB",
      "priority": null,
      "source": "stafflink",
      "activityName": "Blue Book",
      "receivableActivityID": 311009,
      "healthEntityCode": "MOH",
      "activityNameDisplay": "Blue Book",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "92 697 899  630",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Ministry of Health",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Ministry of Health",
      "page": "Misc",
      "ID": "54c5f879-dc4b-4579-8606-05a535315427",
      "postalCode": "2065",
      "address1": "Locked Bag 2030",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "GIPA application fee for access to information held by the Health Organisation, excluding health records managed by facilities",
      "prefix": "GF",
      "priority": null,
      "source": "stafflink",
      "activityName": "MOH GIPA Processing Fees",
      "receivableActivityID": 314009,
      "healthEntityCode": "MOH",
      "activityNameDisplay": "GIPA - Government Information (Public Access)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "92 697 899  630",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Ministry of Health",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "97978317-5616-4319-9b89-a5a9a3426a88",
      "postalCode": "2065",
      "address1": "Locked Bag 2030",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "Miscellaneous",
      "receivableActivityID": 97005,
      "healthEntityCode": "MOH",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "92 697 899  630",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "918c3bfa-3b35-4068-b90e-843f2d942aff",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for VMO Parking",
      "prefix": "PV",
      "priority": null,
      "source": "stafflink",
      "activityName": "NBM Nepean Hosp Car Parking",
      "receivableActivityID": 446013,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Parking Fees - VMO",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "3f358679-3c6d-49c9-9271-b95d37807a6f",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for conference fees.",
      "prefix": "CQ",
      "priority": null,
      "source": "stafflink",
      "activityName": "NBM Conference Tiny Tots NICU",
      "receivableActivityID": 480016,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Conference Fee - Tiny Tots NICU",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "30da39e1-b118-4f52-b208-0ab37d4489fb",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Staff ID card replacement",
      "prefix": "CR",
      "priority": null,
      "source": "stafflink",
      "activityName": "NEP Staff ID Badges",
      "receivableActivityID": 490013,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Staff ID Card Replacement",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "dcd4f9ff-1abb-48c8-ae9a-5cfc6e3e84ef",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "NEP Antenatal Classes",
      "receivableActivityID": 490014,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Hawkesbury District Hospital",
      "page": "Misc",
      "ID": "f6070792-d4f0-45cc-b6bd-3870af67ce68",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "IE",
      "priority": null,
      "source": "stafflink",
      "activityName": "HDH M/care Ineligible ED OOS",
      "receivableActivityID": 490015,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Ineligible ED",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Hawkesbury District Hospital",
      "page": "Misc",
      "ID": "2bac56a4-64dd-413f-b5e2-1728339060e7",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "IP",
      "priority": null,
      "source": "stafflink",
      "activityName": "HDH M/Care Ineligible Prepay",
      "receivableActivityID": 490016,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Ineligible Payment",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Blue Mountains District Anzac Memorial Hospital",
      "page": "Misc",
      "ID": "1cdc6172-8c18-45de-bf1a-11365ab17acf",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for TV Rental",
      "prefix": "TV",
      "priority": null,
      "source": "stafflink",
      "activityName": "BMDAMH TV Payment",
      "receivableActivityID": 491030,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "TV Rental",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Blue Mountains District Anzac Memorial Hospital",
      "page": "Misc",
      "ID": "5a199429-6cc4-48a9-aa71-2d9c41b4441c",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for use of the hydrotherapy pool including group classes, private bookings and treatments.",
      "prefix": "HY",
      "priority": null,
      "source": "stafflink",
      "activityName": "BMDAMH Hydrotherapy Pool",
      "receivableActivityID": 491031,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Hydrotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Blue Mountains District Anzac Memorial Hospital",
      "page": "Misc",
      "ID": "c98fb451-8426-4c40-b2c3-683e4f1a1efb",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "BMDAMH Staff Accomodation",
      "receivableActivityID": 491032,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Blue Mountains District Anzac Memorial Hospital",
      "page": "Misc",
      "ID": "8876db7d-03f4-48d9-a354-5617425da946",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EO",
      "priority": null,
      "source": "stafflink",
      "activityName": "BMDAMH OT Payments",
      "receivableActivityID": 491033,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Equipment Sales - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Blue Mountains District Anzac Memorial Hospital",
      "page": "Misc",
      "ID": "555fbc82-5289-4dd7-a480-9c0244a069fa",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "BMDAMH ED Equipment Sales",
      "receivableActivityID": 491034,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Blue Mountains District Anzac Memorial Hospital",
      "page": "Misc",
      "ID": "1d5537fa-521c-4370-b5f5-286503a895ef",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BMDAMH Postage Charges",
      "receivableActivityID": 491035,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Sundry Income - Postage",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Blue Mountains District Anzac Memorial Hospital",
      "page": "Misc",
      "ID": "c07fafea-c24e-4743-b819-4b1d775ceca6",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "BMDAMH Antenatal Classes",
      "receivableActivityID": 491036,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "a8af7c35-2902-485d-878c-9f07713ee2d9",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DT",
      "priority": null,
      "source": "stafflink",
      "activityName": "NEP Nutrition Supplements",
      "receivableActivityID": 491037,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Dietetics / Nutritional Supplements",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "e7288302-c47c-4396-af60-010971463747",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "NM",
      "priority": null,
      "source": "stafflink",
      "activityName": "NEP PET Scans",
      "receivableActivityID": 491038,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Nuclear Medicine Scans",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "c3089543-9eae-44ae-a797-2a554822c9ce",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "LF",
      "priority": null,
      "source": "stafflink",
      "activityName": "NEP Library Fines",
      "receivableActivityID": 491039,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Library Fines",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Hawkesbury District Hospital",
      "page": "Misc",
      "ID": "c5f9a665-f3d3-416e-bd48-140132308df6",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "HDH pharmacy",
      "receivableActivityID": 495025,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Hawkesbury District Hospital",
      "page": "Misc",
      "ID": "d191b8a5-9fe0-40c6-bdaf-140be4339375",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "HDH Equipment Sales",
      "receivableActivityID": 495026,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Hawkesbury District Hospital",
      "page": "Misc",
      "ID": "922e6e78-cab0-46c1-b20f-533cdb26095f",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "HDH Medical Records",
      "receivableActivityID": 495027,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "c84d5ef5-558a-4f16-b26f-aca51dfe8222",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SP",
      "priority": null,
      "source": "stafflink",
      "activityName": "NEP Staff Parking",
      "receivableActivityID": 495032,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Staff Parking",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "c61e794c-2ffb-4872-ac15-0dfe1088446c",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "PD",
      "priority": null,
      "source": "stafflink",
      "activityName": "NEP Renal Dialysis Parking",
      "receivableActivityID": 495033,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Parking - Dialysis",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Lithgow Health Service",
      "page": "Misc",
      "ID": "14d6b46a-7331-4472-8bf8-f820f4a05f12",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Staff ID card replacement",
      "prefix": "CR",
      "priority": null,
      "source": "stafflink",
      "activityName": "LTH Security Badges",
      "receivableActivityID": 496024,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Staff ID Card Replacement",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "9a8efde4-8cde-4dfb-9cfc-1db115a1ebf3",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AH",
      "priority": null,
      "source": "stafflink",
      "activityName": "NEP Hope Cottage",
      "receivableActivityID": 5360,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Accommodation - Hope Cottage",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "a1da7bad-2632-4801-9f0b-67479f9deb4e",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy",
      "prefix": "PC",
      "priority": null,
      "source": "stafflink",
      "activityName": "NEP Cancer care Pharmacy",
      "receivableActivityID": 5363,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Chemist & Pharmacy Sales - Cancer Care",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "411d3fd7-2331-42b5-b6c8-7c5180ee15fd",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "Emergency",
      "receivableActivityID": 5364,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "6314191e-b729-420f-8dfc-7e3e4f10e27a",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "NEP Nepean Pharmacy",
      "receivableActivityID": 5366,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "fe544e03-2cbb-4331-b74c-13bddc4b7a14",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "NEP Subpoena",
      "receivableActivityID": 5367,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "1bb13726-5e66-445b-84a2-a7d0decc543b",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "Miscellaneous",
      "receivableActivityID": 5370,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "09ec7523-0263-45fe-b1ff-469781c0c8aa",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for use of the hydrotherapy pool including group classes, private bookings and treatments.",
      "prefix": "HY",
      "priority": null,
      "source": "stafflink",
      "activityName": "NEP Hydrotherapy Pool",
      "receivableActivityID": 5371,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "Hydrotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Nepean Blue Mountains Local Health District",
      "state": "NSW",
      "city": "PENRITH",
      "hospital": "Nepean Hospital",
      "page": "Misc",
      "ID": "406c7021-8892-4bd9-a4db-adcabbb911ee",
      "postalCode": "2751",
      "address1": "PO Box 63",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for MRI services for Outpatients",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "MRI",
      "receivableActivityID": 5373,
      "healthEntityCode": "NBMLHD",
      "activityNameDisplay": "MRI Services",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "31 910 677 424",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Byron Central Hospital",
      "page": "Misc",
      "ID": "1df58d70-118e-4155-b6af-9b9f746186b0",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BCH Medical Records",
      "receivableActivityID": 326010,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Byron Central Hospital",
      "page": "Misc",
      "ID": "9599e5de-4619-468a-9694-307089d6e2cb",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "BCH Subpoena",
      "receivableActivityID": 326011,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Byron Central Hospital",
      "page": "Misc",
      "ID": "c47a0a5d-11e1-4174-a0eb-ca68ddedaeaf",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "BCH Physio Equipment",
      "receivableActivityID": 326014,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Byron Central Hospital",
      "page": "Misc",
      "ID": "568e7726-f872-44b8-95f8-1ac0efed4a63",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BCH Pharmacy",
      "receivableActivityID": 326015,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Byron Central Hospital",
      "page": "Misc",
      "ID": "2b7cf8d6-fec7-43b4-b2ef-d5533e0391fd",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "BCH Zsundry",
      "receivableActivityID": 327009,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Kyogle Multi-Purpose Service",
      "page": "Misc",
      "ID": "185a2718-5bb5-4494-b949-a8dab74a20a1",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "KYO LBH Pharmacy Sales",
      "receivableActivityID": 334009,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Kyogle Multi-Purpose Service",
      "page": "Misc",
      "ID": "50dec975-af50-43dd-a1f6-70f30348c39f",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "KYO Crutches Sale",
      "receivableActivityID": 334010,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Kyogle Multi-Purpose Service",
      "page": "Misc",
      "ID": "986ba69d-3c73-40c0-b060-0b34b6ebb234",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "KYO - Medical Record requests",
      "receivableActivityID": 334015,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Kyogle Multi-Purpose Service",
      "page": "Misc",
      "ID": "d986de65-0794-46bb-81fd-cbfa60644912",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "KYO Zsundry",
      "receivableActivityID": 334019,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Kyogle Multi-Purpose Service",
      "page": "Misc",
      "ID": "3aaab468-53f6-447b-a265-15c7adc078c8",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "KYO - Subpoena Medical Records",
      "receivableActivityID": 334020,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Tweed Valley Hospital",
      "page": "Misc",
      "ID": "a58ea3de-f514-4388-874d-25746925dd14",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "TWE Outpatients Clinic",
      "receivableActivityID": 348010,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Equipment Sales - Outpatients",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Casino and District Memorial Hospital",
      "page": "Misc",
      "ID": "06f9467d-5228-412a-8659-fb176108b301",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "CAS Accommodation",
      "receivableActivityID": 354010,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Casino and District Memorial Hospital",
      "page": "Misc",
      "ID": "744a0912-6289-4d09-92ce-58eb5104e231",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "CAS Crutches",
      "receivableActivityID": 354011,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Casino and District Memorial Hospital",
      "page": "Misc",
      "ID": "751caed6-e625-40e7-bb21-a1d1ae4e520e",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CAS LBH Pharmacy",
      "receivableActivityID": 354012,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Grafton Base Hospital",
      "page": "Misc",
      "ID": "c44b5010-cd0c-4f62-8938-0428277b6474",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "GRA Physio Equipment",
      "receivableActivityID": 426012,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Grafton Base Hospital",
      "page": "Misc",
      "ID": "e61170be-a255-4ed2-b432-ab993e3e5757",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for prosthetic lenses",
      "prefix": "PL",
      "priority": null,
      "source": "stafflink",
      "activityName": "GRA Toric Lens",
      "receivableActivityID": 435013,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Prosthetics - Lenses",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Ballina District Hospital",
      "page": "Misc",
      "ID": "e5c16a54-c9c5-4511-81f6-83f2b20e89a5",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BDH MEDICAL RECORDS",
      "receivableActivityID": 484043,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Ballina District Hospital",
      "page": "Misc",
      "ID": "3f75ce8d-df7b-4603-9d66-061dd2cb9b2b",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "BDH SUBPONEA FEE",
      "receivableActivityID": 484044,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Ballina District Hospital",
      "page": "Misc",
      "ID": "ead90dc8-a24d-4cda-be4d-ab25ba7fb782",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "BDH MEDICAL AIDS AND EQUIPMENT",
      "receivableActivityID": 484045,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Ballina District Hospital",
      "page": "Misc",
      "ID": "a24e89eb-d4e2-4de0-8813-7ab608c5d842",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "BDH Food costs - staff",
      "receivableActivityID": 484046,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Ballina District Hospital",
      "page": "Misc",
      "ID": "d0da3e19-e96f-448a-977b-064306d536c1",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BDH - LBH PHARMACY",
      "receivableActivityID": 484047,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Lismore Base Hospital",
      "page": "Misc",
      "ID": "2726ca49-8534-4ae5-824b-ec8d07d97836",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "LIS OUTPATIENTS CLINIC",
      "receivableActivityID": 484048,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Equipment Sales - Outpatients",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Lismore Base Hospital",
      "page": "Misc",
      "ID": "e1a3289a-ab49-4cbf-91fe-00c32c87fda6",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "FW",
      "priority": null,
      "source": "stafflink",
      "activityName": "LIS FOB WATCH",
      "receivableActivityID": 484049,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Sales - FOB Watch",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Lismore Base Hospital",
      "page": "Misc",
      "ID": "cc9e6d7f-0e45-413c-83a4-58c34762fb40",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "GIPA application fee for access to information held by the Health Organisation, excluding health records managed by facilities",
      "prefix": "GF",
      "priority": null,
      "source": "stafflink",
      "activityName": "NNSWLHD GIPA Processing Fees",
      "receivableActivityID": 484050,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "GIPA - Government Information (Public Access)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "All - Whole Health Entity",
      "page": "Misc",
      "ID": "17e3d2ab-da91-4dc6-8964-742e6c99c02f",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "RB",
      "priority": null,
      "source": "stafflink",
      "activityName": "NNSWLHD Rental Bond Board",
      "receivableActivityID": 508023,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Rental Bond Board",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Grafton Base Hospital",
      "page": "Misc",
      "ID": "e3538f47-de2a-41ae-989d-3ce8bc67640d",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "GRA Site Accom & Board",
      "receivableActivityID": 5417,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Grafton Base Hospital",
      "page": "Misc",
      "ID": "ffdfda1d-8913-4b08-a53c-136cffc5c2a2",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "GRA Sundry Income",
      "receivableActivityID": 5419,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Tweed Valley Hospital",
      "page": "Misc",
      "ID": "7050f720-89cb-4f26-b8ed-e64977b9d230",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "TWE Sundry Income",
      "receivableActivityID": 5427,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Grafton Base Hospital",
      "page": "Misc",
      "ID": "b515ca28-da9c-4c0b-b6b7-588c9bce5169",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "MC",
      "priority": null,
      "source": "stafflink",
      "activityName": "GRA Methadone",
      "receivableActivityID": 5432,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Methadone Clinic",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Tweed Valley Hospital",
      "page": "Misc",
      "ID": "a91abcb9-a986-4d61-88ea-5cd205d9d0b9",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "TWE Antenatal Classes",
      "receivableActivityID": 5433,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Tweed Valley Hospital",
      "page": "Misc",
      "ID": "04fb1d4e-94a7-4c20-867c-0928e9abee78",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "TWE Fees for Subpoenas",
      "receivableActivityID": 5435,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Lismore Base Hospital",
      "page": "Misc",
      "ID": "03423a5e-fdbe-4bf7-b08f-38723023658e",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "LIS Pharmacy Lismore",
      "receivableActivityID": 5457,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern NSW Local Health District",
      "state": "NSW",
      "city": "LISMORE",
      "hospital": "Lismore Base Hospital",
      "page": "Misc",
      "ID": "5c8051cb-8ec0-4485-aa13-ce5cd8b9c27c",
      "postalCode": "2480",
      "address1": "LOCKED BAG 11",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "LIS Antenatal Classes",
      "receivableActivityID": 5470,
      "healthEntityCode": "NNSWLHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "67 284 856 520",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Royal North Shore Hospital",
      "page": "Misc",
      "ID": "5118acb4-0dfc-4b97-ba26-3cbea57573fb",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EO",
      "priority": null,
      "source": "stafflink",
      "activityName": "RNS Occ Therapy",
      "receivableActivityID": 392020,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Equipment Sales - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Royal North Shore Hospital",
      "page": "Misc",
      "ID": "c3a3a511-09a1-424b-8375-a53cbef9082a",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for radiology services for outpatients",
      "prefix": "RF",
      "priority": null,
      "source": "stafflink",
      "activityName": "RNS Radiology",
      "receivableActivityID": 392021,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Radiology Fee",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Royal North Shore Hospital",
      "page": "Misc",
      "ID": "33f0f8fb-5179-49c4-81db-07fa19e6b96e",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training courses",
      "prefix": "CC",
      "priority": null,
      "source": "stafflink",
      "activityName": "RNS Simulation Centre",
      "receivableActivityID": 392023,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Course - Clinical Skills and Simulation Centre",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Hornsby Ku-ring-gai Hospital",
      "page": "Misc",
      "ID": "fc83befc-1ee2-40de-a3f4-1dc4117bf9a5",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for use of the hydrotherapy pool including group classes, private bookings and treatments.",
      "prefix": "HY",
      "priority": null,
      "source": "stafflink",
      "activityName": "HKH Hydro",
      "receivableActivityID": 403010,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Hydrotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Hornsby Ku-ring-gai Hospital",
      "page": "Misc",
      "ID": "54e30535-a942-4f03-a545-545027e712c0",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EO",
      "priority": null,
      "source": "stafflink",
      "activityName": "HKH O/T",
      "receivableActivityID": 403015,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Equipment Sales - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Hornsby Ku-ring-gai Hospital",
      "page": "Misc",
      "ID": "edaf7da7-3e93-44a1-8d80-520197c5b883",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for podiatry products such as Orthotic insoles, foot braces and supports",
      "prefix": "PO",
      "priority": null,
      "source": "stafflink",
      "activityName": "HKH Podiatry",
      "receivableActivityID": 403017,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Podiatry",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Hornsby Ku-ring-gai Hospital",
      "page": "Misc",
      "ID": "520281c9-03e5-460c-93bc-e9db6d4478d0",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for radiology services for outpatients",
      "prefix": "RF",
      "priority": null,
      "source": "stafflink",
      "activityName": "HKH Radiology",
      "receivableActivityID": 403018,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Radiology Fee",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Macquarie Hospital",
      "page": "Misc",
      "ID": "223549fe-54a6-4be2-a3fd-9c8c74706424",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAC Pharmacy",
      "receivableActivityID": 434201,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Mona Vale Hospital",
      "page": "Misc",
      "ID": "b2b597fe-3835-4e0a-92fb-e95abf16d85c",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "MVH Equipment Hire",
      "receivableActivityID": 434210,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Royal North Shore Hospital",
      "page": "Misc",
      "ID": "1e4bfaca-9f05-4581-a898-305773f4ac62",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "RNS Accomodation Family",
      "receivableActivityID": 434227,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Ryde Hospital",
      "page": "Misc",
      "ID": "26869c06-d2a8-4156-99f3-8407571e24a6",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "RYD Equipment Sales",
      "receivableActivityID": 434228,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Macquarie Hospital",
      "page": "Misc",
      "ID": "1a8c6af0-6e8a-43e8-952e-dd531d412174",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "PT",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAQ Patient Trust",
      "receivableActivityID": 464016,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Patient Trust",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Hornsby Ku-ring-gai Hospital",
      "page": "Misc",
      "ID": "9ad1664f-e2c5-41ef-9dee-bfcda64229a9",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "GP",
      "priority": null,
      "source": "stafflink",
      "activityName": "HKH GP Clinic",
      "receivableActivityID": 503024,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "GP Clinic",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Hornsby Ku-ring-gai Hospital",
      "page": "Misc",
      "ID": "6645a74a-354b-4bcd-97a0-9d3876de7d2f",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Gift shop sales",
      "prefix": "FA",
      "priority": null,
      "source": "stafflink",
      "activityName": "HKH Florist",
      "receivableActivityID": 5472,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Florist",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Ryde Hospital",
      "page": "Misc",
      "ID": "8a6c0d9d-77e3-438b-80e3-f33090fb0606",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "RYH Physiotherapy Dept Sales",
      "receivableActivityID": 5473,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Ryde Hospital",
      "page": "Misc",
      "ID": "a67cfd6c-96a1-4d53-bedf-b25ae21d10b8",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "RYH Med Rec",
      "receivableActivityID": 5475,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Royal North Shore Hospital",
      "page": "Misc",
      "ID": "1b6d7e11-615f-4935-803d-d20a504f1bef",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "RNS Sale Orthotics",
      "receivableActivityID": 5479,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Royal North Shore Hospital",
      "page": "Misc",
      "ID": "f982544b-8a80-405a-b250-957abdf1747e",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "RNS Physio Plaster Room",
      "receivableActivityID": 5480,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Royal North Shore Hospital",
      "page": "Misc",
      "ID": "249993f2-7757-4d4f-8313-a7a4d1739d24",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "RNS Medical Records",
      "receivableActivityID": 5483,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Royal North Shore Hospital",
      "page": "Misc",
      "ID": "6b53f135-d04b-459f-87cd-f9239dfc9553",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DO",
      "priority": null,
      "source": "stafflink",
      "activityName": "RNS Child Care Fees",
      "receivableActivityID": 5487,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Child Care",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Hornsby Ku-ring-gai Hospital",
      "page": "Misc",
      "ID": "d23842ad-5ef2-4bf7-91bf-355f937510e1",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "HKH Physio",
      "receivableActivityID": 5519,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Hornsby Ku-ring-gai Hospital",
      "page": "Misc",
      "ID": "7404d647-b99f-4a07-85a6-f0f373329526",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "HKH Pharm",
      "receivableActivityID": 5520,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Hornsby Ku-ring-gai Hospital",
      "page": "Misc",
      "ID": "f18ee0f2-300f-4a5b-83ea-7911a44a5520",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CG",
      "priority": null,
      "source": "stafflink",
      "activityName": "HKH GP Training",
      "receivableActivityID": 5523,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Course - GP Training",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Hornsby Ku-ring-gai Hospital",
      "page": "Misc",
      "ID": "14251f48-47f1-4c55-a695-5e887e1a2fb3",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "HKH Ante Classes",
      "receivableActivityID": 5528,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Mona Vale Hospital",
      "page": "Misc",
      "ID": "7e8bd38b-d83f-4402-8a77-bb8f2366c68b",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for use of the hydrotherapy pool including group classes, private bookings and treatments.",
      "prefix": "HY",
      "priority": null,
      "source": "stafflink",
      "activityName": "MVH Hydro",
      "receivableActivityID": 5539,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Hydrotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Northern Sydney Local Health District",
      "state": "NSW",
      "city": "ST LEONARDS",
      "hospital": "Macquarie Hospital",
      "page": "Misc",
      "ID": "0c87ea6e-50f4-4270-8e58-9910d9958426",
      "postalCode": "1590",
      "address1": "PO BOX 100",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SG",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAQ Sale Other",
      "receivableActivityID": 5541,
      "healthEntityCode": "NSLHD",
      "activityNameDisplay": "Sales - Other GST",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "63 834 171 987",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "NSW Health Pathology",
      "state": "NSW",
      "city": "NEWCASTLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "bfd7389f-6af2-4981-b366-1987f5a67feb",
      "postalCode": "2300",
      "address1": "PO Box 846",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "X MISCELLANEOUS",
      "receivableActivityID": 27001,
      "healthEntityCode": "NSWHP",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "49 382 586 535",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "The Children's Hospital at Westmead",
      "page": "Misc",
      "ID": "3f1387f2-a94a-4703-abcf-9ba6040e5f70",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "CHW Appl Cent Eqmt/Cons Sales",
      "receivableActivityID": 415022,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "The Children's Hospital at Westmead",
      "page": "Misc",
      "ID": "8809fdbb-9cc0-46a9-9d9a-908d7d82c8de",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training such as antenatal classes and diabetes courses.",
      "prefix": "ED",
      "priority": null,
      "source": "stafflink",
      "activityName": "SCHN Education Centre Revenue",
      "receivableActivityID": 415023,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Education/Training",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "The Children's Hospital at Westmead",
      "page": "Misc",
      "ID": "49b306d4-d8ca-418b-bbbc-75928a4e5837",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CHW Parking Fees",
      "receivableActivityID": 441014,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Staff Parking",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "The Children's Hospital at Westmead",
      "page": "Misc",
      "ID": "edee07c3-6539-4bc8-9e4b-b3c2e9d91594",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CB",
      "priority": null,
      "source": "stafflink",
      "activityName": "CHW Childcare Bond/Deposit",
      "receivableActivityID": 492036,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Child Care Centre Bond / Deposit",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "The Children's Hospital at Westmead",
      "page": "Misc",
      "ID": "2ae494b4-aa44-485c-83d9-93f22d1f76d5",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CV",
      "priority": null,
      "source": "stafflink",
      "activityName": "CHW Childcare Vacation fees",
      "receivableActivityID": 492037,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Child Care Vacation Fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "The Children's Hospital at Westmead",
      "page": "Misc",
      "ID": "4e702b26-6e6d-4816-a73e-29ae9446a813",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Staff ID card replacement",
      "prefix": "CR",
      "priority": null,
      "source": "stafflink",
      "activityName": "CHW Staff ID badges",
      "receivableActivityID": 492038,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Staff ID Card Replacement",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "The Children's Hospital at Westmead",
      "page": "Misc",
      "ID": "e59d282a-9200-4495-97ef-18d6ec972397",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "LR",
      "priority": null,
      "source": "stafflink",
      "activityName": "CHW Locker key deposit",
      "receivableActivityID": 492039,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Locker Rental",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "The Children's Hospital at Westmead",
      "page": "Misc",
      "ID": "5e58c5f2-dde5-495a-a449-3b63541c8117",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "LS",
      "priority": null,
      "source": "stafflink",
      "activityName": "CHW Library Services",
      "receivableActivityID": 492040,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Library Services",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "The Children's Hospital at Westmead",
      "page": "Misc",
      "ID": "9949a3e6-b15e-4655-91a9-3928a6ecc553",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "LS",
      "priority": null,
      "source": "stafflink",
      "activityName": "CHW Library Subscriptions",
      "receivableActivityID": 492041,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Library Subscriptions",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "The Children's Hospital at Westmead",
      "page": "Misc",
      "ID": "00fe8d25-a90d-4070-a31b-2ab17201355e",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "CHW Hostel accomodation",
      "receivableActivityID": 492042,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "The Children's Hospital at Westmead",
      "page": "Misc",
      "ID": "485c60be-9a62-46d8-9c79-bb9954e38a3f",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CHW Crutches And Cast",
      "receivableActivityID": 5630,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "The Children's Hospital at Westmead",
      "page": "Misc",
      "ID": "16d92895-bdb1-4e12-8274-68f20c80908e",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "CHW Parents Hostel",
      "receivableActivityID": 5635,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "The Children's Hospital at Westmead",
      "page": "Misc",
      "ID": "98140340-fdb0-4936-a64b-09c70895f4ef",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SK",
      "priority": null,
      "source": "stafflink",
      "activityName": "CHW Kids Health",
      "receivableActivityID": 5636,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Sales - Kids Health books",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "The Children's Hospital at Westmead",
      "page": "Misc",
      "ID": "11bcb9a4-5750-481e-9b7a-b2dead0a60af",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CC",
      "priority": null,
      "source": "stafflink",
      "activityName": "CHW Child Care Centre",
      "receivableActivityID": 5637,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Child Care Centre Fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "Sydney Children's Hospital at Randwick",
      "page": "Misc",
      "ID": "1dafa388-9f40-42fb-a4fd-dc51a4f3899f",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "SCH Pharmacy Supplies",
      "receivableActivityID": 5638,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "Sydney Children's Hospital at Randwick",
      "page": "Misc",
      "ID": "f293e541-41cd-43a0-a2b0-d1fc501afb0e",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "SCH SCH H.E.L.P Hire/Consum.",
      "receivableActivityID": 5640,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "Sydney Children's Hospital at Randwick",
      "page": "Misc",
      "ID": "5c411478-4e7c-46c3-bea4-aa5781f1ed31",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EF",
      "priority": null,
      "source": "stafflink",
      "activityName": "SCH Under Cast Waterproof Pad",
      "receivableActivityID": 5642,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Equipment Sales - Fracture Clinic",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "The Sydney Children's Hospitals Network",
      "state": "NSW",
      "city": "WESTMEAD",
      "hospital": "Sydney Children's Hospital at Randwick",
      "page": "Misc",
      "ID": "097eab59-58c9-44b1-9a98-3f214d93b866",
      "postalCode": "2145",
      "address1": "Locked Bag 4002",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "SCH Hostel Accomm",
      "receivableActivityID": 5644,
      "healthEntityCode": "SCHN",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "53 188 579 090",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "Garrawarra Centre",
      "page": "Misc",
      "ID": "11d4eeca-cb9a-475b-bdf8-c1c62c009033",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "RF",
      "priority": null,
      "source": "stafflink",
      "activityName": "GAR Fees/Res/Nurse H",
      "receivableActivityID": 11001,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Resident Fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "St George Hospital",
      "page": "Misc",
      "ID": "928b6851-d837-4276-9348-3e680fc7de14",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "STG Antenatal Classes",
      "receivableActivityID": 11014,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "Bezzina House Cancer Care Lodge",
      "page": "Misc",
      "ID": "f16e1c7c-3a63-4968-a93b-37145a13a632",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "STG Bezzina House",
      "receivableActivityID": 11016,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "St George Hospital",
      "page": "Misc",
      "ID": "50171137-5506-42ba-b2bd-659f252cacc4",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "STG Emergency Dept",
      "receivableActivityID": 11022,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "The Sutherland Hospital",
      "page": "Misc",
      "ID": "4eff75fc-dcd6-4f8d-9eb7-20a040efcf2b",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EI",
      "priority": null,
      "source": "stafflink",
      "activityName": "TSH Southcare Equip",
      "receivableActivityID": 11059,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Equipment Hire - Southcare Loan",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "Prince of Wales Hospital",
      "page": "Misc",
      "ID": "97c00286-a876-4e81-a11a-74400fbb58b8",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DT",
      "priority": null,
      "source": "stafflink",
      "activityName": "POW Nut Dietetics",
      "receivableActivityID": 13000,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Dietetics / Nutritional Supplements",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "Prince of Wales Hospital",
      "page": "Misc",
      "ID": "7c09694c-b8a5-45f3-b920-00b0334a8f3c",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "POW Pharmacy",
      "receivableActivityID": 13001,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "Royal Hospital for Women",
      "page": "Misc",
      "ID": "f9ae5cd7-7846-4767-94a2-b7aef9865954",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "RHW Boarder Accommodation",
      "receivableActivityID": 13005,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "Royal Hospital for Women",
      "page": "Misc",
      "ID": "46d8da98-257b-4ba6-b981-79136b738be6",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "RHW Course Fees Hlth Ed",
      "receivableActivityID": 13007,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "Royal Hospital for Women",
      "page": "Misc",
      "ID": "e6bca5ef-5a53-4080-bc98-461033eb0f41",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "EG",
      "priority": null,
      "source": "stafflink",
      "activityName": "RHW Sale Glue/Stockings",
      "receivableActivityID": 13012,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Equipment Sales - Physio (GST)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "St George Hospital",
      "page": "Misc",
      "ID": "85614a27-79b6-431b-93b2-12500651903d",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training courses",
      "prefix": "CC",
      "priority": null,
      "source": "stafflink",
      "activityName": "STG Clinical Skills Ctr Course",
      "receivableActivityID": 13016,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Course - Clinical Skills and Simulation Centre",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "St George Hospital",
      "page": "Misc",
      "ID": "951782d9-3401-438a-b2e1-4d488b1183db",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DO",
      "priority": null,
      "source": "stafflink",
      "activityName": "STG Drug Trials",
      "receivableActivityID": 13018,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Clinical Trials Unit",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "St George Hospital",
      "page": "Misc",
      "ID": "b1e3aef9-62b5-4cdb-917c-c804cab977a1",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "ED",
      "priority": null,
      "source": "stafflink",
      "activityName": "STG Endo/Diamond",
      "receivableActivityID": 13019,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Endocrinology",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "Sydney and Sydney Eye Hospital",
      "page": "Misc",
      "ID": "4c73abee-c1a8-4b26-90e8-4690ed0d077e",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "SEH Pharmacy Charges",
      "receivableActivityID": 18013,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "Sydney and Sydney Eye Hospital",
      "page": "Misc",
      "ID": "bdcdfd48-2b7d-42a5-a602-9aafea9d106d",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "SEH WaterProof Cast",
      "receivableActivityID": 18016,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Equipment Sales - Paediatric",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "The Sutherland Hospital",
      "page": "Misc",
      "ID": "858026c3-c11e-4f2b-a0ca-b727b1b200fc",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DO",
      "priority": null,
      "source": "stafflink",
      "activityName": "TSH Staff Education",
      "receivableActivityID": 294010,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Staff Training & Wellbeing",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "The Sutherland Hospital",
      "page": "Misc",
      "ID": "086911c6-f692-4845-9e0c-a81459177ac4",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Payment for Service provided by Health Organisation",
      "prefix": "SA",
      "priority": null,
      "source": "stafflink",
      "activityName": "TSH - Craft Markets",
      "receivableActivityID": 325010,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Service Fee - Craft Market Stall",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "Prince of Wales Hospital",
      "page": "Misc",
      "ID": "f1c13118-c434-43e1-9964-8672f2e76b10",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for exercise classes.",
      "prefix": "EC",
      "priority": null,
      "source": "stafflink",
      "activityName": "POW - AIM Class",
      "receivableActivityID": 415024,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Exercise Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "Prince of Wales Hospital",
      "page": "Misc",
      "ID": "5b613940-0a47-46b0-90f7-d4833a9213e5",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for use of the hydrotherapy pool including group classes, private bookings and treatments.",
      "prefix": "HY",
      "priority": null,
      "source": "stafflink",
      "activityName": "POW - WAVES Class",
      "receivableActivityID": 415025,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Hydrotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "dbdcba1b-af6a-4bb0-b874-54d471b0b778",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training courses",
      "prefix": "CS",
      "priority": null,
      "source": "stafflink",
      "activityName": "SES Practice Dev. School",
      "receivableActivityID": 480029,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Course - PD School",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "Prince of Wales Hospital",
      "page": "Misc",
      "ID": "8ea6fd9b-3bb0-4a72-81cb-c926f9a5e8f1",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "POW Equipment Sales",
      "receivableActivityID": 482014,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "Prince of Wales Hospital",
      "page": "Misc",
      "ID": "9d212968-3628-418a-b298-0bb3d71bbcdb",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "POW Equipment Hire",
      "receivableActivityID": 486014,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Eastern Sydney Local Health District",
      "state": "NSW",
      "city": "TAREN POINT",
      "hospital": "St George Hospital",
      "page": "Misc",
      "ID": "d968f318-b1f0-4b09-b85a-445d939b1360",
      "postalCode": "2229",
      "address1": "Finance and Corporate Services",
      "address2": "Locked Bag 21",
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "PT",
      "priority": null,
      "source": "stafflink",
      "activityName": "STG PET Scan Diagnostic Fee",
      "receivableActivityID": 506023,
      "healthEntityCode": "SESLHD",
      "activityNameDisplay": "PET Scan Diagnostic Fee",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "70 442 041 439",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "Goulburn Base Hospital",
      "page": "Misc",
      "ID": "837bdcd9-8c4b-433f-8d9f-7c331e0da60f",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOU Equipment Sales",
      "receivableActivityID": 280017,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "Goulburn Base Hospital",
      "page": "Misc",
      "ID": "71a4b591-aad8-47a7-8585-b8ded5896c91",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOU Breast pump hire",
      "receivableActivityID": 280018,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "Equipment Sales - Maternity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "Goulburn Base Hospital",
      "page": "Misc",
      "ID": "a649ba0a-baeb-4057-99e5-598b64ab0d11",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "EG",
      "priority": null,
      "source": "stafflink",
      "activityName": "GOU Physio Sales",
      "receivableActivityID": 280019,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "Equipment Sales - Physio (GST)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "Batemans Bay District Hospital",
      "page": "Misc",
      "ID": "337fb66e-ba76-4759-9af6-11e0317177b7",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "EG",
      "priority": null,
      "source": "stafflink",
      "activityName": "BBH Physio Sales",
      "receivableActivityID": 280020,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "Equipment Sales - Physio (GST)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "Batemans Bay District Hospital",
      "page": "Misc",
      "ID": "cd6b1fc6-f5d9-4dd1-bd9b-73341f545ade",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "BBH Accommodation - Staff",
      "receivableActivityID": 280021,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "Queanbeyan Health Service",
      "page": "Misc",
      "ID": "fc3d01ba-2c03-4de9-b808-d7edd202007e",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "QUE Medical Records",
      "receivableActivityID": 434225,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "4ebe1f8f-e969-41e4-a9f5-7a00e913cab5",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "GIPA application fee for access to information held by the Health Organisation, excluding health records managed by facilities",
      "prefix": "GF",
      "priority": null,
      "source": "stafflink",
      "activityName": "SNSW GIPA Processing Fee",
      "receivableActivityID": 441015,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "GIPA - Government Information (Public Access)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "Braidwood Multi-Purpose Service",
      "page": "Misc",
      "ID": "5e8e86c8-e33a-4265-a1b3-32833bec17d1",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRA Equipment Sales",
      "receivableActivityID": 460033,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "Braidwood Multi-Purpose Service",
      "page": "Misc",
      "ID": "0d98f325-9cd1-41cc-a40f-363dec9f9123",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRA Medical Records",
      "receivableActivityID": 460034,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "Braidwood Multi-Purpose Service",
      "page": "Misc",
      "ID": "72f91f61-4f72-4253-b3c2-bd5872517bed",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRA Meals & Accom",
      "receivableActivityID": 460035,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "Queanbeyan Health Service",
      "page": "Misc",
      "ID": "756dd6a6-f861-47d4-9657-a3a1ed55b63e",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "EG",
      "priority": null,
      "source": "stafflink",
      "activityName": "QUE Physio Equipment Sale",
      "receivableActivityID": 461032,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "Equipment Sales - Physio (GST)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "Goulburn Base Hospital",
      "page": "Misc",
      "ID": "07319477-5a48-4e9c-a0fc-ad7ed3265ad8",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "Goulburn Pharamcy",
      "receivableActivityID": 492056,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "Cooma Health Service",
      "page": "Misc",
      "ID": "abbaf9ab-af8c-4640-b680-e100a0668ef9",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "Cooma Pharmacy",
      "receivableActivityID": 492058,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "Queanbeyan Health Service",
      "page": "Misc",
      "ID": "a2f05899-64e6-4fc0-ab9a-2cb528086cf3",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "Queanbeyan Pharmacy",
      "receivableActivityID": 492059,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "South East Regional Hospital (Bega)",
      "page": "Misc",
      "ID": "0e328f3a-c537-4a44-b030-fab992f379c1",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "SERH (Bega) Pharmacy",
      "receivableActivityID": 492061,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "Pambula Health Service",
      "page": "Misc",
      "ID": "41e3e3a1-e212-4549-b492-9aadd3356fd0",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "Pambula Pharmacy",
      "receivableActivityID": 492063,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "Batemans Bay District Hospital",
      "page": "Misc",
      "ID": "f9156dd5-0302-45d7-af1c-d5980f7d5f64",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "Batemans Bay Pharmacy",
      "receivableActivityID": 492064,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Southern NSW Local Health District",
      "state": "NSW",
      "city": "QUEANBEYAN",
      "hospital": "Moruya District Hospital",
      "page": "Misc",
      "ID": "74753122-bcd5-4eb2-b899-d9f174387d61",
      "postalCode": "2620",
      "address1": "PO Box 1845",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "Moruya Pharmacy",
      "receivableActivityID": 492065,
      "healthEntityCode": "SNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "94 495 894 851",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Campbelltown Hospital",
      "page": "Misc",
      "ID": "56e0455c-7c7e-4d04-b132-ce33ff5ca090",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "AD",
      "priority": null,
      "source": "stafflink",
      "activityName": "CAMP ROTARY LODGE DEPOSIT",
      "receivableActivityID": 406014,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Accommodation - Refundable Deposits",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Campbelltown Hospital",
      "page": "Misc",
      "ID": "2b5aea0d-2b8b-4073-8aec-701168ad01e3",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay deposit for Staff ID card",
      "prefix": "CD",
      "priority": null,
      "source": "stafflink",
      "activityName": "CAMP STAFF REFUNDABLE DEPOSIT",
      "receivableActivityID": 406015,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Staff ID Card Deposit",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Campbelltown Hospital",
      "page": "Misc",
      "ID": "9b88c47c-e167-4f15-a964-fe484c8bf7dc",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "RD",
      "priority": null,
      "source": "stafflink",
      "activityName": "CAMP ELP REFUNDABLE DEPOSIT",
      "receivableActivityID": 406016,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Equipment Hire - Refundable Deposits",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "66b3c74a-29ea-4bef-bd10-8413bb1fac24",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "GIPA application fee for access to information held by the Health Organisation, excluding health records managed by facilities",
      "prefix": "GF",
      "priority": null,
      "source": "stafflink",
      "activityName": "SWSLHD GIPA Processing Fees",
      "receivableActivityID": 434091,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "GIPA - Government Information (Public Access)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Bankstown / Lidcombe Hospital",
      "page": "Misc",
      "ID": "75854b12-a091-415b-a056-8d7864ad013d",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BANK Medical Records",
      "receivableActivityID": 434101,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Bowral and District Hospital",
      "page": "Misc",
      "ID": "aa59dfe3-c08d-4408-96ad-ddfbf70d2b14",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BOW Medical Records",
      "receivableActivityID": 434122,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Campbelltown Hospital",
      "page": "Misc",
      "ID": "ee88a7cd-e820-4110-bb32-40684779594c",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "CAMP Medical Records",
      "receivableActivityID": 434123,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Fairfield Hospital",
      "page": "Misc",
      "ID": "ac34cded-953c-415c-8ee4-260d25190725",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "FAIR Medical Records",
      "receivableActivityID": 434152,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Liverpool Hospital",
      "page": "Misc",
      "ID": "f61bd8f4-0c9b-45af-95f7-cadee7852897",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "LIV Medical Records",
      "receivableActivityID": 434199,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Campbelltown Hospital",
      "page": "Misc",
      "ID": "1fdea818-e3fe-462a-bb3d-850bc5533486",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Gym",
      "prefix": "DO",
      "priority": null,
      "source": "stafflink",
      "activityName": "CAMP GYM",
      "receivableActivityID": 483016,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Gym",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Campbelltown Hospital",
      "page": "Misc",
      "ID": "ee5043ff-4089-49b3-80f6-f895ad432265",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for library books",
      "prefix": "LB",
      "priority": null,
      "source": "stafflink",
      "activityName": "CAMP MAC LIRBRARY",
      "receivableActivityID": 483017,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Sales - Library Books",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Fairfield Hospital",
      "page": "Misc",
      "ID": "85814f68-96ab-498e-8b1b-5472c5e062f6",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "FAIR WJRC Equipment Sales",
      "receivableActivityID": 483018,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Fairfield Hospital",
      "page": "Misc",
      "ID": "0e06c72b-c69e-45be-9fd8-1d50ca0974c8",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "RD",
      "priority": null,
      "source": "stafflink",
      "activityName": "FAIR Refundable Deposits",
      "receivableActivityID": 483019,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Equipment Hire - Refundable Deposits",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Fairfield Hospital",
      "page": "Misc",
      "ID": "e3abc140-0bf4-42b3-8e04-9ec8ac114edb",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EO",
      "priority": null,
      "source": "stafflink",
      "activityName": "FAIR Occ Therapy Equip Sales",
      "receivableActivityID": 483020,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Equipment Sales - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Fairfield Hospital",
      "page": "Misc",
      "ID": "9bfed244-be3f-4e20-a910-48ab999d9d8d",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Staff ID card replacement",
      "prefix": "CR",
      "priority": null,
      "source": "stafflink",
      "activityName": "FAIR Lost Staff Access ID\r",
      "receivableActivityID": 483021,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Staff ID Card Replacement",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Bowral and District Hospital",
      "page": "Misc",
      "ID": "2d60fdb8-1b3c-42b2-aad8-c55e80d3014d",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for merchandise",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "BOW BLESSED ARE THEY BOOK",
      "receivableActivityID": 483035,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Sales - Blessed are they book",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Campbelltown Hospital",
      "page": "Misc",
      "ID": "64aa33be-45d4-4307-a97f-26740991cc0e",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your mental health medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MC",
      "priority": null,
      "source": "stafflink",
      "activityName": "MAC Medical Records Mental",
      "receivableActivityID": 508031,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Medical Records - Mental Health",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Camden Hospital",
      "page": "Misc",
      "ID": "f418b0d9-26f5-4e67-b9e7-e069ae8dc34d",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "TS",
      "priority": null,
      "source": "stafflink",
      "activityName": "CAMD TRANSPORT",
      "receivableActivityID": 5548,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Transport Services",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Camden Hospital",
      "page": "Misc",
      "ID": "aab8b2df-64f6-492a-8f4d-29d4fc9c701c",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CAMD PHYSIO",
      "receivableActivityID": 5549,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Equipment Hire - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "South Western Sydney Local Health District",
      "state": "NSW",
      "city": "LIVERPOOL BC",
      "hospital": "Bowral and District Hospital",
      "page": "Misc",
      "ID": "15310b38-c4ec-4960-867e-8fd376e177fd",
      "postalCode": "1871",
      "address1": "Liverpool Hospital Eastern Campus",
      "address2": "Locked Bag 7279",
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BOW PHARMACY",
      "receivableActivityID": 5556,
      "healthEntityCode": "SWSLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "46 738 965 845",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "e5b95e72-d6da-4428-8874-94faa3d9caae",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPA Accommodation",
      "receivableActivityID": 415010,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "0cc2e4a6-d86d-4778-9a62-b82932d7fda6",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training such as antenatal classes and diabetes courses.",
      "prefix": "ED",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPA Conf & Training",
      "receivableActivityID": 415011,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Education/Training",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "bf60779f-0cb4-4177-914d-e27d08b97d56",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPA - Pharmacy Fees",
      "receivableActivityID": 415012,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "9c004819-f01a-438a-bfa4-b6ce023ca641",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPA ED Equipment Hire",
      "receivableActivityID": 415013,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "cf46a6c8-7a86-42cb-9832-ac56388b1c72",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPA Equipment sales",
      "receivableActivityID": 415014,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "3dcc3f7b-8ba2-4745-86e6-d4ce0c819c50",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPA Medical records (GST)",
      "receivableActivityID": 415015,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "c8752915-de2d-4d97-8e13-9af82dc0d983",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPA Medical Records Subpoena",
      "receivableActivityID": 415016,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "6113bd78-496d-4282-9937-8a2abafcdfc7",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "GIPA application fee for access to information held by the Health Organisation, excluding health records managed by facilities",
      "prefix": "GF",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPA Medical record GIPA",
      "receivableActivityID": 415017,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "GIPA - Government Information (Public Access)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "02420203-f2e3-45f2-96bf-4f5e4bc6acfd",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "Callan Park Board Lodging",
      "receivableActivityID": 478018,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Accommodation - Callan Park",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "c67ac673-a13d-4ac0-9d3c-016491ea7e27",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for parking fees and services",
      "prefix": "PF",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPAH VMO Parking",
      "receivableActivityID": 479014,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Parking Fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "d61dcb2d-d7dc-416b-a427-85101f87a544",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SC",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPAH Social Club",
      "receivableActivityID": 479015,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Social Club",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Canterbury Hospital",
      "page": "Misc",
      "ID": "2519202c-0835-47be-b5df-435443b97d4c",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "CH Walking Aids Trust Fund",
      "receivableActivityID": 484028,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Canterbury Hospital",
      "page": "Misc",
      "ID": "5a6a22df-3308-4ef5-ad78-bc809a4bcce8",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "CH - ED / EMU - NURSING",
      "receivableActivityID": 484029,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Canterbury Hospital",
      "page": "Misc",
      "ID": "4583eb06-3680-4b15-b09e-baf91f0b5d6b",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CH - PHYSIO - ALLIED HEALTH",
      "receivableActivityID": 484030,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Canterbury Hospital",
      "page": "Misc",
      "ID": "df368ea9-b084-4f1a-b683-1f37d8767d7f",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CH - PHARMACY",
      "receivableActivityID": 484031,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Canterbury Hospital",
      "page": "Misc",
      "ID": "d3961055-3407-4c70-b375-31f222021250",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "FV",
      "priority": null,
      "source": "stafflink",
      "activityName": "Pink Ladies Contributions",
      "receivableActivityID": 484032,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Fundraising - Voluntary Services",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Canterbury Hospital",
      "page": "Misc",
      "ID": "500bdf74-ecf3-4329-b015-67b9b87c1902",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "CH - FINANCE DEPARTMENT",
      "receivableActivityID": 484034,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Canterbury Hospital",
      "page": "Misc",
      "ID": "f8f5ffb9-6a38-4812-a1cc-a82dc9882165",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Birthing and Parenting Classes",
      "prefix": "BC",
      "priority": null,
      "source": "stafflink",
      "activityName": "CH - EDU UNIT MIDWIFERY",
      "receivableActivityID": 484035,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Birthing and Parenting Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Canterbury Hospital",
      "page": "Misc",
      "ID": "29adf1b0-7b74-4f60-8531-5c5534da96b0",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "CH - MEDICAL RECORDS",
      "receivableActivityID": 484036,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Canterbury Hospital",
      "page": "Misc",
      "ID": "0e8d3c48-57b8-4369-90ec-45560288ead1",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "MT",
      "priority": null,
      "source": "stafflink",
      "activityName": "CH - OUTPATIENTS - NURSING",
      "receivableActivityID": 484037,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Mantoux Screening Test",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Canterbury Hospital",
      "page": "Misc",
      "ID": "d4076ebf-3bf5-4911-963a-5eb74c0695bb",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "MB",
      "priority": null,
      "source": "stafflink",
      "activityName": "CH - OACCP / ORP CLINIC",
      "receivableActivityID": 484038,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Medical Imaging Bone Scan",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Sydney Dental Hospital",
      "page": "Misc",
      "ID": "605366ae-ce71-4cfe-b26f-62bf9a7a7761",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDH-ORTHODONTICS",
      "receivableActivityID": 484039,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Sydney Dental Hospital",
      "page": "Misc",
      "ID": "2e6fe550-4529-487c-a8c4-ee355437b4d6",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Staff ID card replacement",
      "prefix": "CR",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDH-ACCESS CARD",
      "receivableActivityID": 484040,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Staff ID Card Replacement",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Sydney Dental Hospital",
      "page": "Misc",
      "ID": "0dbb224f-87a7-470d-bbf2-7fc60ee37225",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDH- FOI",
      "receivableActivityID": 484041,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Balmain Hospital",
      "page": "Misc",
      "ID": "02172524-4e20-4df0-a869-b514879b008c",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "RD",
      "priority": null,
      "source": "stafflink",
      "activityName": "BH Equipment Hire",
      "receivableActivityID": 488013,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Equipment Hire - Refundable Deposits",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Balmain Hospital",
      "page": "Misc",
      "ID": "5c78cf6c-34a0-4821-8c88-2192555a0fd3",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "BH Equipment Sales - ED",
      "receivableActivityID": 488014,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Balmain Hospital",
      "page": "Misc",
      "ID": "b516c36b-0547-4923-9c9c-b6e16ee4f5ed",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SF",
      "priority": null,
      "source": "stafflink",
      "activityName": "BH Strong Clinic Feed",
      "receivableActivityID": 488017,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Sales - Strong Feed",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Balmain Hospital",
      "page": "Misc",
      "ID": "f7dd56ed-bf20-4291-8466-b5944568ad8f",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BH Equipment Sales - Physio",
      "receivableActivityID": 488018,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Balmain Hospital",
      "page": "Misc",
      "ID": "ec56d8cf-13e2-4eb4-a227-f699af5e2623",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "BH Medical Records (Subpoena)",
      "receivableActivityID": 488019,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Balmain Hospital",
      "page": "Misc",
      "ID": "c304c207-8bcc-4ee2-ae1e-0a8e0b27897b",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EO",
      "priority": null,
      "source": "stafflink",
      "activityName": "BH Equipment Sales - OT",
      "receivableActivityID": 488020,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Equipment Sales - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Balmain Hospital",
      "page": "Misc",
      "ID": "80115d94-451b-4535-abbe-11eead75ecf0",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BH Medical Records",
      "receivableActivityID": 488021,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Concord Hospital",
      "page": "Misc",
      "ID": "f33b0a64-9b1d-4c07-bfb3-723348e543bc",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "RI",
      "priority": null,
      "source": "stafflink",
      "activityName": "CC - Executive Management",
      "receivableActivityID": 488022,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Rental Income",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "2a3d94ac-daba-4792-a702-53d63a884f37",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SN",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPA - 7 East Upper GI",
      "receivableActivityID": 488023,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Sales - Nutrition Pre-Op",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "77d34faa-d734-40fe-96df-4a568a24c452",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DT",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPA - Nutrition / Dietetic",
      "receivableActivityID": 488024,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Dietetics / Nutritional Supplements",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "7c829436-cd84-4d5a-9654-2ebcdd0e96af",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SA",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPA - SPF Clinical Immunology",
      "receivableActivityID": 488025,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Sales - Allergy Publications",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Balmain Hospital",
      "page": "Misc",
      "ID": "40dd4df1-487f-4f30-93ad-273c995db28c",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SA",
      "priority": null,
      "source": "stafflink",
      "activityName": "BH Hospital Lift Ads",
      "receivableActivityID": 491041,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Sales - Advertising",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Canterbury Hospital",
      "page": "Misc",
      "ID": "26a778f7-a295-470f-a393-f4eee9d4adf1",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SA",
      "priority": null,
      "source": "stafflink",
      "activityName": "CH Commissions",
      "receivableActivityID": 497023,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Sales - Advertising",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Balmain Hospital",
      "page": "Misc",
      "ID": "da7c9017-87d7-4cc4-a352-41353cde265a",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "HM",
      "priority": null,
      "source": "stafflink",
      "activityName": "OPD Clinic (Homeopathy)",
      "receivableActivityID": 501023,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Homeopathy Medicine Sales - OPD",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "4f94cb0a-aba8-4937-91d8-4a6fe3c6e5db",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "RI",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPA Executive Management",
      "receivableActivityID": 506025,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Rental Income",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "318b45d0-c425-4b7a-98ae-00d19c6c99bb",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SP",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPA Carpark keycard deposit",
      "receivableActivityID": 506026,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Staff Parking",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "fb38eb75-c7c2-4c67-8e75-954bafcc42d1",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "RE",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPA Market Day Stall",
      "receivableActivityID": 506027,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Market Day Stall",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "7dfd663a-9369-4d74-a034-04afe19ebb2d",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "FV",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPA Volunteers Service Stall",
      "receivableActivityID": 506028,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Fundraising - Voluntary Services",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Royal Prince Alfred Hospital",
      "page": "Misc",
      "ID": "b5570a64-af30-43dc-8d3b-83b2a9ed572b",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DO",
      "priority": null,
      "source": "stafflink",
      "activityName": "RPA Occupational Therapy",
      "receivableActivityID": 506029,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "6decbc39-775a-4b8d-b876-c60b0079fcf3",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DO",
      "priority": null,
      "source": "stafflink",
      "activityName": "CRGH Yarralla Childcare Fee",
      "receivableActivityID": 5580,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Child Care",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Sydney Dental Hospital",
      "page": "Misc",
      "ID": "687f9f41-8575-43c2-a149-a30affa7c125",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDH-Sale of Old Wares",
      "receivableActivityID": 5583,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Sydney Dental Hospital",
      "page": "Misc",
      "ID": "7ca56755-413d-4c5a-bb09-015669e43a5a",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "SDH-Medical Records",
      "receivableActivityID": 5585,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "1a5e51bc-02c3-4e07-baa9-db42a321c92a",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "Miscellaneous",
      "receivableActivityID": 5592,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Concord Hospital",
      "page": "Misc",
      "ID": "c7c2716f-e27b-4228-8bd8-5d5bc9f21986",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EO",
      "priority": null,
      "source": "stafflink",
      "activityName": "CC-Sale of O/T Equipment",
      "receivableActivityID": 5599,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Equipment Sales - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Concord Hospital",
      "page": "Misc",
      "ID": "7cb6227b-5c53-4244-9c6c-8c10d9ff6a5b",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HO",
      "priority": null,
      "source": "stafflink",
      "activityName": "CC-O/T Equipment ELP",
      "receivableActivityID": 5602,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Equipment Hire - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Concord Hospital",
      "page": "Misc",
      "ID": "089d8b03-02ec-4911-95f1-f33a8bea4b37",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CC-Pharmacy Fees",
      "receivableActivityID": 5614,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Concord Hospital",
      "page": "Misc",
      "ID": "5f264581-b262-4af7-87c8-270f1b836559",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for parking fees and services",
      "prefix": "PF",
      "priority": null,
      "source": "stafflink",
      "activityName": "CRGH-VMO Car Parking",
      "receivableActivityID": 5616,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Parking Fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Concord Hospital",
      "page": "Misc",
      "ID": "18b91228-94cb-41f3-83b8-c0017c2d8d2e",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "GIPA application fee for access to information held by the Health Organisation, excluding health records managed by facilities",
      "prefix": "GF",
      "priority": null,
      "source": "stafflink",
      "activityName": "CC Medical Records GIPAA",
      "receivableActivityID": 5618,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "GIPA - Government Information (Public Access)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Concord Hospital",
      "page": "Misc",
      "ID": "fde6a9e2-4ac3-4484-aa94-069c19c279bd",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "CC-Medical Record Subpoena",
      "receivableActivityID": 5619,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Sydney Local Health District",
      "state": "NSW",
      "city": "CAMPERDOWN",
      "hospital": "Concord Hospital",
      "page": "Misc",
      "ID": "9d8fca91-4190-48a5-a41a-c437f12b741e",
      "postalCode": "2050",
      "address1": "PO Box M36",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "CC-Medical Rcrd Clinical Notes",
      "receivableActivityID": 5620,
      "healthEntityCode": "SYDLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "17 520 269 052",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Forbes District Hospital",
      "page": "Misc",
      "ID": "2574bd57-40f3-4bda-9dae-5a943740de64",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "FRB Crutches - sale",
      "receivableActivityID": 160009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Forbes District Hospital",
      "page": "Misc",
      "ID": "ea05dbce-485a-4cc1-9e19-cdd6a18a5ef1",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HP",
      "priority": null,
      "source": "stafflink",
      "activityName": "FRB Equipment hire",
      "receivableActivityID": 160013,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Forbes District Hospital",
      "page": "Misc",
      "ID": "ed7dc87e-b658-4b63-a05e-151d4a21a7a6",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HO",
      "priority": null,
      "source": "stafflink",
      "activityName": "FRB OT Equipment hire",
      "receivableActivityID": 160015,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Forbes District Hospital",
      "page": "Misc",
      "ID": "260a9d39-fc91-4c2e-a5b9-5b7068602df3",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "FRB Physio sales",
      "receivableActivityID": 160016,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Forbes District Hospital",
      "page": "Misc",
      "ID": "8e21756b-d8f6-4a66-8acb-b5c8bb93c9b9",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "FRB OT sales - Sched 3 medical equip",
      "receivableActivityID": 160017,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Forbes District Hospital",
      "page": "Misc",
      "ID": "3f9ad3df-ec2a-4b9b-ba09-9bf2c99444be",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "FRB Medical records (no subpoena)",
      "receivableActivityID": 160018,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Forbes District Hospital",
      "page": "Misc",
      "ID": "c933c4ff-acb6-4702-b27a-e34cee179c4d",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "FRB Medical records (subpoena)",
      "receivableActivityID": 160019,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Forbes District Hospital",
      "page": "Misc",
      "ID": "ad637019-47fd-4c47-bae9-bb990e600c84",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "FRB Pharmacy sales (857250)",
      "receivableActivityID": 160020,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Forbes District Hospital",
      "page": "Misc",
      "ID": "83b47b6a-1953-4a9c-9a96-9f92fc61fecd",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CO",
      "priority": null,
      "source": "stafflink",
      "activityName": "FRB Pharmacy sales - HFO (857137)",
      "receivableActivityID": 160021,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments - HFO",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Parkes District Hospital",
      "page": "Misc",
      "ID": "d23640ec-bc58-4183-9190-6637d53ee505",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HP",
      "priority": null,
      "source": "stafflink",
      "activityName": "PRK Equipment hire - Physio",
      "receivableActivityID": 160031,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Parkes District Hospital",
      "page": "Misc",
      "ID": "8747a3f9-04e5-42d6-82ec-9b21e6418ff7",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HO",
      "priority": null,
      "source": "stafflink",
      "activityName": "PRK Equipment hire - OT",
      "receivableActivityID": 160032,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Parkes District Hospital",
      "page": "Misc",
      "ID": "210f8e1f-7968-4009-9bc4-a495e74944a9",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "PRK Physio sales - Sched 3 medical equip",
      "receivableActivityID": 160033,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Parkes District Hospital",
      "page": "Misc",
      "ID": "40101e3b-641d-400b-ac74-203a50f86843",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "PRK OT sales - Sched 3 medical equip",
      "receivableActivityID": 160034,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Parkes District Hospital",
      "page": "Misc",
      "ID": "d96a9a8f-e8ff-4fbc-83c4-fee8a1190fb3",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "PRK Medical records (no subpoena)",
      "receivableActivityID": 160035,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Parkes District Hospital",
      "page": "Misc",
      "ID": "0886d6e4-a658-4b5e-906d-fe922d3672c3",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "PRK Medical records (subpoena)",
      "receivableActivityID": 160036,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Parkes District Hospital",
      "page": "Misc",
      "ID": "c6e54901-d991-429f-9882-a518fecccbaa",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "PRK Pharmacy sales (861250)",
      "receivableActivityID": 160038,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Parkes District Hospital",
      "page": "Misc",
      "ID": "ef1d25dc-03ed-4f24-85db-dc685b9079b5",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CO",
      "priority": null,
      "source": "stafflink",
      "activityName": "PRK Pharmacy - HFO (861137)",
      "receivableActivityID": 160039,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments - HFO",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Parkes District Hospital",
      "page": "Misc",
      "ID": "4913e857-b4e4-49bc-b9aa-eb9dfba7f957",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CH",
      "priority": null,
      "source": "stafflink",
      "activityName": "PRK Pharmacy sales - HCD",
      "receivableActivityID": 160040,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments - HCD",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Parkes District Hospital",
      "page": "Misc",
      "ID": "032f5b59-e200-42ba-9903-3f13829fbf71",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "PRK Staff accommodation",
      "receivableActivityID": 160043,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Cobar District Hospital",
      "page": "Misc",
      "ID": "ac4479da-ed62-4eff-9268-1eba3e993970",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "MW",
      "priority": null,
      "source": "stafflink",
      "activityName": "CBR Meals on wheels",
      "receivableActivityID": 160051,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals on wheels",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Cobar District Hospital",
      "page": "Misc",
      "ID": "2e2f7fce-9612-4089-be95-f265782308b2",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "CBR Medical records (no subpoena)",
      "receivableActivityID": 160052,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Cobar District Hospital",
      "page": "Misc",
      "ID": "0781f1b1-9ee1-4784-a5a6-76aaf2ed9571",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "CBR Medical records (subpoena)",
      "receivableActivityID": 160053,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Cobar District Hospital",
      "page": "Misc",
      "ID": "dffdd1fa-334f-47f4-b2cb-d3ce19761330",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "MF",
      "priority": null,
      "source": "stafflink",
      "activityName": "CBR Mortuary fees",
      "receivableActivityID": 160054,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Mortuary fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Cobar District Hospital",
      "page": "Misc",
      "ID": "781c0da5-850e-4e0c-87e3-aa212c0cc1a2",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CBR Pharmacy sales",
      "receivableActivityID": 160055,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Rylstone Multi-Purpose Service",
      "page": "Misc",
      "ID": "25640802-7231-4424-bb39-d2a127008fbb",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "RYL Meals",
      "receivableActivityID": 165018,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Rylstone Multi-Purpose Service",
      "page": "Misc",
      "ID": "d6ee9fd1-6972-4ceb-b1d9-4579f2ad3c10",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "RYL Medical records",
      "receivableActivityID": 165019,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Rylstone Multi-Purpose Service",
      "page": "Misc",
      "ID": "89371ea4-9ab6-4d39-ac77-45e211021184",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "RYL Medical records (subpoena)",
      "receivableActivityID": 165020,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Rylstone Multi-Purpose Service",
      "page": "Misc",
      "ID": "3a6d6af9-e49c-4ca4-8f29-4d8900aea0e3",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "RYL Pharmacy sales",
      "receivableActivityID": 165021,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Rylstone Multi-Purpose Service",
      "page": "Misc",
      "ID": "4798508f-4a27-44cc-95d4-e91fdef51402",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "RH",
      "priority": null,
      "source": "stafflink",
      "activityName": "RYL Room hire",
      "receivableActivityID": 165023,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Room Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Peak Hill Hospital",
      "page": "Misc",
      "ID": "a26d6baf-ebc8-4807-9bae-78b1888ef3cf",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "PKH Crutches - sale",
      "receivableActivityID": 165025,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Peak Hill Hospital",
      "page": "Misc",
      "ID": "dbcf40ac-6a95-46d8-b97d-9a690bfc0e6c",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "PKH Meals - staff/visitors",
      "receivableActivityID": 165029,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Peak Hill Hospital",
      "page": "Misc",
      "ID": "632779ff-7bc0-465f-a29d-de38083905a7",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "PKH Med records (no subpoena)",
      "receivableActivityID": 165030,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Peak Hill Hospital",
      "page": "Misc",
      "ID": "d36d59b9-48d0-4b53-9b47-12d7c7563dd8",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "PKH Medical records (subpoena)",
      "receivableActivityID": 165031,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Peak Hill Hospital",
      "page": "Misc",
      "ID": "85c2f0c8-0cb8-45b1-a004-e16abd1a2947",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "PKH Sale old wares",
      "receivableActivityID": 165034,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Tottenham Multi-Purpose Service",
      "page": "Misc",
      "ID": "8d3621d0-f156-4c9f-93b5-bf3baa4ea19d",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "TTH Crutches - sale",
      "receivableActivityID": 165035,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Tottenham Multi-Purpose Service",
      "page": "Misc",
      "ID": "72b30a95-a0b3-4a75-9b34-823e394e9e24",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "TTH Meals",
      "receivableActivityID": 165040,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Trundle Multi-Purpose Service",
      "page": "Misc",
      "ID": "bff07e05-1507-411c-a1fa-7be72bc5ba0f",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "TRD Meals",
      "receivableActivityID": 165046,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Tullamore Multi-Purpose Service",
      "page": "Misc",
      "ID": "f3a8bb20-352f-45d7-b847-1e5e600228ad",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "TLM Med records (no subpoena)",
      "receivableActivityID": 165054,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Tullamore Multi-Purpose Service",
      "page": "Misc",
      "ID": "1a8530e3-9bfc-42e5-8067-c32e11542865",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "TLM Staff accommodation",
      "receivableActivityID": 165056,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Brewarrina Multi-Purpose Service",
      "page": "Misc",
      "ID": "799a1474-2840-4a1a-8dad-82eb4ad70cae",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "MW",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRW Meals on wheels",
      "receivableActivityID": 177009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals on wheels",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Brewarrina Multi-Purpose Service",
      "page": "Misc",
      "ID": "4e8603bb-7920-432d-8856-52d6bdbbaf5a",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRW Medical records (no subpoena)",
      "receivableActivityID": 177010,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Brewarrina Multi-Purpose Service",
      "page": "Misc",
      "ID": "3afbf3d0-d811-4a69-9984-a5f0e744663c",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRW Medical records (subpoena)",
      "receivableActivityID": 177011,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Brewarrina Multi-Purpose Service",
      "page": "Misc",
      "ID": "37b268f8-9c3a-4169-a78f-7bcc7884bb5b",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRW Pharmacy sales",
      "receivableActivityID": 177014,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Brewarrina Multi-Purpose Service",
      "page": "Misc",
      "ID": "bc3575b1-8163-4ec1-aa27-8688f93da91f",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRW Staff accommodation",
      "receivableActivityID": 177016,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dunedoo War Memorial Multi-Purpose Service",
      "page": "Misc",
      "ID": "c454487e-8523-4e70-8120-9764b962a408",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "DND Equipment hire - sched 3 medical equip",
      "receivableActivityID": 177019,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dunedoo War Memorial Multi-Purpose Service",
      "page": "Misc",
      "ID": "8df7c842-01e2-4830-bd31-570da51a36a9",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "DND Meals",
      "receivableActivityID": 177020,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dunedoo War Memorial Multi-Purpose Service",
      "page": "Misc",
      "ID": "b6b8b3ba-84a4-463d-8a1d-e30302c7f5ba",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "DND Pharmacy sales",
      "receivableActivityID": 177021,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dunedoo War Memorial Multi-Purpose Service",
      "page": "Misc",
      "ID": "6f78eb2a-7241-40cb-8aa7-d0c518639578",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CO",
      "priority": null,
      "source": "stafflink",
      "activityName": "DND Pharmacy sales - HFO",
      "receivableActivityID": 177022,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments - HFO",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dunedoo War Memorial Multi-Purpose Service",
      "page": "Misc",
      "ID": "36f65bf4-d112-4bb5-a43b-afe3928ecb53",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "RH",
      "priority": null,
      "source": "stafflink",
      "activityName": "DND Room hire",
      "receivableActivityID": 177023,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Room Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Eugowra Multi-Purpose Service",
      "page": "Misc",
      "ID": "56349486-588e-4313-8212-07dc3424236e",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HP",
      "priority": null,
      "source": "stafflink",
      "activityName": "EGW Equipment hire - sched 3 medical equip (Physio",
      "receivableActivityID": 177024,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Eugowra Multi-Purpose Service",
      "page": "Misc",
      "ID": "15a03268-3092-441b-9ebc-40ab49f16b9e",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "EGW Meals",
      "receivableActivityID": 177025,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Eugowra Multi-Purpose Service",
      "page": "Misc",
      "ID": "369605fd-0839-43e1-bbe3-c42064daa05f",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "EGW Physio sales - sched 3 medical equip",
      "receivableActivityID": 177026,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Lightning Ridge Multi-Purpose Service",
      "page": "Misc",
      "ID": "804643dc-955c-483c-a464-a5f7dead2299",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "LRH Crutches - sale",
      "receivableActivityID": 177027,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Lightning Ridge Multi-Purpose Service",
      "page": "Misc",
      "ID": "59cc53ca-22dd-4508-b876-2e5f6b0a7c45",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "LRH Medical records (no subpoena)",
      "receivableActivityID": 177029,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Lightning Ridge Multi-Purpose Service",
      "page": "Misc",
      "ID": "28b18c1d-f350-4b1f-a983-9fb9bfe3063e",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "LRH Medical records (subpoena)",
      "receivableActivityID": 177030,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Lightning Ridge Multi-Purpose Service",
      "page": "Misc",
      "ID": "c1656154-8cc9-4498-bfc7-d8ade7cc49b9",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "LRH Pharmacy sales - HFO",
      "receivableActivityID": 177031,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Molong Multi-Purpose Service",
      "page": "Misc",
      "ID": "ae710313-57da-4811-8233-71b936d9a07a",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "MLG Crutches - sale",
      "receivableActivityID": 177036,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Molong Multi-Purpose Service",
      "page": "Misc",
      "ID": "e992e7d8-89c0-4635-93d3-96ab7c66daab",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "MLG Equipment hire - CHC (sched 3 medical equip)",
      "receivableActivityID": 177037,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Molong Multi-Purpose Service",
      "page": "Misc",
      "ID": "2792e162-eb06-405f-981f-9e05db18bef6",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HO",
      "priority": null,
      "source": "stafflink",
      "activityName": "MLG Equipment hire - OT (sched 3 medical equip)",
      "receivableActivityID": 177038,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Molong Multi-Purpose Service",
      "page": "Misc",
      "ID": "74c9cf04-dcc7-4c47-85aa-7d1e16c829b1",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HP",
      "priority": null,
      "source": "stafflink",
      "activityName": "MLG Equip hire - Physio (sched 3 medical equip)",
      "receivableActivityID": 177039,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Molong Multi-Purpose Service",
      "page": "Misc",
      "ID": "cc323cc0-4f73-405f-8171-17ca6fdc6cc3",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "MLG Meals",
      "receivableActivityID": 177040,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Molong Multi-Purpose Service",
      "page": "Misc",
      "ID": "0b0a0ef7-7f16-439c-81d2-e02eaab5c13f",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "MLG Medical records (no subpoena)",
      "receivableActivityID": 177041,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Molong Multi-Purpose Service",
      "page": "Misc",
      "ID": "7cf6a363-a918-440a-830b-5e00b30b406f",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "MLG Medical records (subpoena)",
      "receivableActivityID": 177042,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Oberon Multi-Purpose Service",
      "page": "Misc",
      "ID": "10b6b9e8-42f9-47e3-b64b-cb66a0fa2e67",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "OBN Meals",
      "receivableActivityID": 177048,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Oberon Multi-Purpose Service",
      "page": "Misc",
      "ID": "c6da5e0a-53a4-4a60-90ee-eec259179adf",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "OBN Medical records (no subpoena)",
      "receivableActivityID": 177050,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Oberon Multi-Purpose Service",
      "page": "Misc",
      "ID": "876324ab-2d62-44dc-9078-304969786f40",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "OBN Medical records (subpoena)",
      "receivableActivityID": 177051,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Wellington Hospital, Bindawalla",
      "page": "Misc",
      "ID": "0292c710-fa72-4043-8d59-5b4f40229cf4",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "WLG Crutches - sale (A&E)",
      "receivableActivityID": 177053,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Wellington Hospital, Bindawalla",
      "page": "Misc",
      "ID": "f325d09e-4111-4863-8aa3-86dcff283b23",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "WLG Medical records (no subpoena)",
      "receivableActivityID": 177055,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Wellington Hospital, Bindawalla",
      "page": "Misc",
      "ID": "15cceced-f044-47b8-a114-595bbfd53059",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "WLG Medical records (subpoena)",
      "receivableActivityID": 177056,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Wellington Hospital, Bindawalla",
      "page": "Misc",
      "ID": "60690340-1ac1-48bb-ae48-6d18bb8ab794",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "WLG Pharmacy sales",
      "receivableActivityID": 177057,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Wellington Hospital, Bindawalla",
      "page": "Misc",
      "ID": "b58b9348-17da-4122-ad41-27c16220eda7",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "WLG Physio sales - Sched 3 medical equip",
      "receivableActivityID": 177058,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Wellington Hospital, Bindawalla",
      "page": "Misc",
      "ID": "a8daa485-c27f-4e3a-9433-68f17407be47",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "RH",
      "priority": null,
      "source": "stafflink",
      "activityName": "WLG Room hire",
      "receivableActivityID": 177059,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Room Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bathurst Base Hospital",
      "page": "Misc",
      "ID": "8dadb081-6bb8-4a52-8c30-dfbe8dcf8632",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AM",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTH Boarder - maternity",
      "receivableActivityID": 177060,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Maternity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bathurst Base Hospital",
      "page": "Misc",
      "ID": "d051b6b2-ef2a-4b4c-b2db-96ee82f191f9",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for TV Rental",
      "prefix": "TV",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTH Commissions - TV hire",
      "receivableActivityID": 177063,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "TV Rental",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bathurst Base Hospital",
      "page": "Misc",
      "ID": "ca717112-24f8-47f9-9b94-82fe03d2e29a",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTH Crutches - sale (A&E)",
      "receivableActivityID": 177065,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bathurst Base Hospital",
      "page": "Misc",
      "ID": "d4dd4df1-215e-4fe5-9037-61be23c2e662",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTH Equipment hire - OT (sched 3 medical equip)",
      "receivableActivityID": 177075,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bathurst Base Hospital",
      "page": "Misc",
      "ID": "3dc0f8f6-7720-42df-b40a-c94e6b095bdf",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTH Medical records (no subpoena)",
      "receivableActivityID": 177077,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bathurst Base Hospital",
      "page": "Misc",
      "ID": "544a02c0-e367-48d0-a958-b9c1402e8ab3",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTH Medical records (subpoena)",
      "receivableActivityID": 177078,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bathurst Base Hospital",
      "page": "Misc",
      "ID": "4ae119b0-2bde-4ed8-ac97-eadc12ac2218",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTH OT sales - sched 3 medical equip",
      "receivableActivityID": 177079,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bathurst Base Hospital",
      "page": "Misc",
      "ID": "9a82fd63-5fe4-4b3f-b10c-65d50ca60988",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CH",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTH Pharmacy sales - HCD",
      "receivableActivityID": 177080,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments - HCD",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bathurst Base Hospital",
      "page": "Misc",
      "ID": "f5fb266e-3712-4891-b609-d62a16148ffe",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CO",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTH Pharmacy sales - HFO (826137)",
      "receivableActivityID": 177081,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments - HFO",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bathurst Base Hospital",
      "page": "Misc",
      "ID": "d115b00d-38a9-4205-9a9f-26a7add69966",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTH Pharmacy sales (826250)",
      "receivableActivityID": 177082,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bathurst Base Hospital",
      "page": "Misc",
      "ID": "31031e8a-9ae8-4e21-94cd-d11a6701d56e",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTH Physio sales - sched 3 medical equip",
      "receivableActivityID": 177083,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bathurst Base Hospital",
      "page": "Misc",
      "ID": "f1a810c8-8f5c-42fc-a668-2225eb207297",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training such as antenatal classes and diabetes courses.",
      "prefix": "ED",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTH Prenatal/parenting classes",
      "receivableActivityID": 177084,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Education/Training",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bathurst Base Hospital",
      "page": "Misc",
      "ID": "6bf73b05-bfdd-4c32-89d6-dd2165006a40",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTH Staff accommodation",
      "receivableActivityID": 177085,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Cowra District Hospital",
      "page": "Misc",
      "ID": "04805bda-b3ea-4a23-b12c-250ce7978d31",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "CWR Medical records (no subpoena)",
      "receivableActivityID": 177087,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Cowra District Hospital",
      "page": "Misc",
      "ID": "5d1bbc28-8d35-4ee3-a483-ed83a3dc9db1",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "CWR Medical records (subpoena)",
      "receivableActivityID": 177088,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Cowra District Hospital",
      "page": "Misc",
      "ID": "f5a24928-f0be-4e64-9d9e-0bbbfc5598ee",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "CWR Staff accommodation",
      "receivableActivityID": 177089,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Cowra District Hospital",
      "page": "Misc",
      "ID": "fae8a04d-5c2b-4721-a4e3-03df99681aa8",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CWR Pharmacy sales - HFO (854137)",
      "receivableActivityID": 177096,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Cowra District Hospital",
      "page": "Misc",
      "ID": "38e2c76a-04ef-4f48-b2dc-1002b9f82b0a",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CWR Equipment hire - Physio (sched 3 med equip)",
      "receivableActivityID": 177098,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Cowra District Hospital",
      "page": "Misc",
      "ID": "f7db314a-1d13-42f3-b946-bfe972ae2d79",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HO",
      "priority": null,
      "source": "stafflink",
      "activityName": "CWR Equipment hire - OT (sched 3 medical equip)",
      "receivableActivityID": 177099,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Blayney Multi-Purpose Service",
      "page": "Misc",
      "ID": "9c2801ea-9ec0-4bab-b378-a1f0bb1d6b84",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BLY Medical records (no subpoena)",
      "receivableActivityID": 177100,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Blayney Multi-Purpose Service",
      "page": "Misc",
      "ID": "c2860d7a-4840-47f6-869b-b1c7ea02285c",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "BLY Medical records (subpoena)",
      "receivableActivityID": 177101,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Blayney Multi-Purpose Service",
      "page": "Misc",
      "ID": "d910e8c4-3f93-43ba-987e-7b82044d0547",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "BLY Meals",
      "receivableActivityID": 177102,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Blayney Multi-Purpose Service",
      "page": "Misc",
      "ID": "4a9e367a-39be-4ddc-a66f-07d634d64765",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "BLY Staff accommodation",
      "receivableActivityID": 178011,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Blayney Multi-Purpose Service",
      "page": "Misc",
      "ID": "26dda4da-4cd9-4d04-8444-8e515e127003",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "MC",
      "priority": null,
      "source": "stafflink",
      "activityName": "BLY Methadone",
      "receivableActivityID": 178013,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Methadone Clinic",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Blayney Multi-Purpose Service",
      "page": "Misc",
      "ID": "1f6a4f1a-4ccd-421b-9b8e-bf3ebb741ef2",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "TS",
      "priority": null,
      "source": "stafflink",
      "activityName": "BLY Bus hire other",
      "receivableActivityID": 178015,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Transport Services",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Baradine Multi-Purpose Service",
      "page": "Misc",
      "ID": "877cce18-0839-4c09-b4c2-c12e3733b6e7",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRD Crutches - sale",
      "receivableActivityID": 178016,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Baradine Multi-Purpose Service",
      "page": "Misc",
      "ID": "d4224ac3-0452-499c-abd5-bdcd0ad649b8",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRD Meals",
      "receivableActivityID": 178017,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Baradine Multi-Purpose Service",
      "page": "Misc",
      "ID": "6fc88bb7-a81c-485b-9f42-2cbfbc72bc83",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRD Medical records (no subpoena)",
      "receivableActivityID": 178018,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Baradine Multi-Purpose Service",
      "page": "Misc",
      "ID": "46a5e5b2-e161-4107-a332-1eeb991d0a8d",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRD Medical records (subpoena)",
      "receivableActivityID": 178019,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Baradine Multi-Purpose Service",
      "page": "Misc",
      "ID": "b4f70169-243d-45dc-8bcb-5c2882d4ac4d",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRD Pharmacy sales",
      "receivableActivityID": 178020,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Baradine Multi-Purpose Service",
      "page": "Misc",
      "ID": "50b6eb0c-a252-46cf-b7de-7f1192eb0c2c",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRD Staff accommodation",
      "receivableActivityID": 178021,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Baradine Multi-Purpose Service",
      "page": "Misc",
      "ID": "087fb04f-ffc4-49a9-a4b0-e2cc228e86b5",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "TR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRD Telephone receipts",
      "receivableActivityID": 178022,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Telephone Receipts",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coonabarabran District Hospital",
      "page": "Misc",
      "ID": "a654214e-d3df-462c-8ded-b520430750a0",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "CBB Medical records (no subpoena)",
      "receivableActivityID": 178028,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coonabarabran District Hospital",
      "page": "Misc",
      "ID": "467413ba-2134-4273-a18b-f1f96fe99e5d",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "CBB Medical records (subpoena)",
      "receivableActivityID": 178029,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coonabarabran District Hospital",
      "page": "Misc",
      "ID": "a7c11f64-74d5-4146-9fde-d961437fded5",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CO",
      "priority": null,
      "source": "stafflink",
      "activityName": "CBB Pharmacy sales - HFO",
      "receivableActivityID": 178030,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments - HFO",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coonabarabran District Hospital",
      "page": "Misc",
      "ID": "0b67fe4e-242b-4866-84cb-c57f6e74dad6",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "CBB Staff accommodation",
      "receivableActivityID": 178033,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Nyngan Multi-Purpose Service",
      "page": "Misc",
      "ID": "d4e3b0bc-2218-40fa-987f-10cd62e55637",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "NYG Crutches - sale",
      "receivableActivityID": 178035,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Nyngan Multi-Purpose Service",
      "page": "Misc",
      "ID": "8a285815-cb13-482a-8fb1-50652eccdab1",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "NYG Meals",
      "receivableActivityID": 178038,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Nyngan Multi-Purpose Service",
      "page": "Misc",
      "ID": "d8d91907-1e3b-4664-9566-a0288433bd31",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "NYG Medical records (no subpoena)",
      "receivableActivityID": 178039,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Nyngan Multi-Purpose Service",
      "page": "Misc",
      "ID": "53aea10a-f262-4984-9324-c4ba5b9611aa",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "NYG Medical records (subpoena)",
      "receivableActivityID": 178040,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Nyngan Multi-Purpose Service",
      "page": "Misc",
      "ID": "a19a948f-1b35-45eb-9e68-e2acc4aabc83",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "MF",
      "priority": null,
      "source": "stafflink",
      "activityName": "NYG Mortuary fees",
      "receivableActivityID": 178041,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Mortuary fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Nyngan Multi-Purpose Service",
      "page": "Misc",
      "ID": "e6cc0229-a06a-4df3-95e8-17da28fa64c8",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CO",
      "priority": null,
      "source": "stafflink",
      "activityName": "NYG Pharmacy sales - HFO",
      "receivableActivityID": 178042,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments - HFO",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Nyngan Multi-Purpose Service",
      "page": "Misc",
      "ID": "e66384e1-2f37-4244-93ee-a6ba621345fa",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "NYG Pharmacy sales (838110)",
      "receivableActivityID": 178043,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Nyngan Multi-Purpose Service",
      "page": "Misc",
      "ID": "d00894da-2eb2-4183-80d2-c7270242e12e",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "NYG Staff accommodation",
      "receivableActivityID": 178044,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Walgett District Hospital",
      "page": "Misc",
      "ID": "c2ba2920-af23-4ab4-9ca2-d9a7b4bf2971",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "WGT Crutches - sale",
      "receivableActivityID": 178046,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Walgett District Hospital",
      "page": "Misc",
      "ID": "ec694b40-9a66-4908-b6a8-3275b27320d6",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "WGT Meals",
      "receivableActivityID": 178047,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Walgett District Hospital",
      "page": "Misc",
      "ID": "016afe3b-2147-4dd1-8187-cb5ee16eac1e",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "WGT Medical records (no subpoena)",
      "receivableActivityID": 178048,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Walgett District Hospital",
      "page": "Misc",
      "ID": "d2effecb-50f5-4e60-b78a-3dec9a4e5964",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "WGT Medical records (subpoena)",
      "receivableActivityID": 178049,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Walgett District Hospital",
      "page": "Misc",
      "ID": "7ea3e395-5f31-4a1e-abb8-a0a6c3e5746f",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "RH",
      "priority": null,
      "source": "stafflink",
      "activityName": "WGT Room hire",
      "receivableActivityID": 178052,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Room Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Walgett District Hospital",
      "page": "Misc",
      "ID": "73699e6c-84f8-4057-8bae-0fce1c254b8d",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "WGT Staff accommodation",
      "receivableActivityID": 178053,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Warren Multi-Purpose Service",
      "page": "Misc",
      "ID": "b00e6861-3120-458b-81cd-266b020754f3",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "WRN Meals",
      "receivableActivityID": 178057,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Warren Multi-Purpose Service",
      "page": "Misc",
      "ID": "fbdb89a6-6405-4f42-b05c-910ab5ec3098",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "WRN Medical records (no subpoena)",
      "receivableActivityID": 178058,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Warren Multi-Purpose Service",
      "page": "Misc",
      "ID": "b62698a8-6b5e-400a-9854-19ae14fa4367",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "WRN Medical records (subpoena)",
      "receivableActivityID": 178059,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Warren Multi-Purpose Service",
      "page": "Misc",
      "ID": "f2f14cd6-0514-4357-8358-65e1981a2b5c",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "WRN Pharmacy sales (842110)",
      "receivableActivityID": 178062,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Warren Multi-Purpose Service",
      "page": "Misc",
      "ID": "1078ab40-384c-47af-90ab-e8170957d0cb",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "WRN Staff accommodation",
      "receivableActivityID": 178063,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coolah Multi-Purpose Service",
      "page": "Misc",
      "ID": "6be755d1-85ee-4487-869a-d956a4b78137",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLH Medical records (no subpoena)",
      "receivableActivityID": 178064,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coolah Multi-Purpose Service",
      "page": "Misc",
      "ID": "c2fef205-2f76-4e2d-a252-68f6e4a04511",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLH Medical records (subpoena)",
      "receivableActivityID": 178065,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coonamble District Hospital",
      "page": "Misc",
      "ID": "2e2f6f66-ea11-4cf3-aa11-ff96c1ef089b",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "CNM Medical records (no subpoena)",
      "receivableActivityID": 195013,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coonamble District Hospital",
      "page": "Misc",
      "ID": "48fa171f-5190-4aba-a379-03d99bf61b07",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "CNM Medical records (subpoena)",
      "receivableActivityID": 195014,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coonamble District Hospital",
      "page": "Misc",
      "ID": "7358c1d6-4407-4c3b-bf5f-268778a8dbd6",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CNM Pharmacy sales (812110)",
      "receivableActivityID": 195015,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coonamble District Hospital",
      "page": "Misc",
      "ID": "2d7784eb-acd9-44cc-b681-2f71408e63a6",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "CNM Physio sales - sched 3 medical equip",
      "receivableActivityID": 196010,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coonamble District Hospital",
      "page": "Misc",
      "ID": "2745b6e2-5c66-45a1-bd94-d9d5a78c7fcc",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "RH",
      "priority": null,
      "source": "stafflink",
      "activityName": "CNM Room hire (812648)",
      "receivableActivityID": 197009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Room Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Gulgong District Hospital",
      "page": "Misc",
      "ID": "0462f5ea-a9fa-4fba-ac7a-14fbf8a2dc7b",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "GLG Crutches - sale",
      "receivableActivityID": 197012,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Gulgong District Hospital",
      "page": "Misc",
      "ID": "34a86b1c-b96d-436d-a8ca-66a47342de72",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "GLG Medical records (no subpoena)",
      "receivableActivityID": 197013,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Gulgong District Hospital",
      "page": "Misc",
      "ID": "c5626585-bdd3-4ffb-9b4f-c15bc6102d6b",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "GLG Medical records (subpoena)",
      "receivableActivityID": 197014,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Gulgong District Hospital",
      "page": "Misc",
      "ID": "ad38a7b8-f0e1-42e1-b771-dcc87a06d197",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "GLG Pharmacy sales (822110)",
      "receivableActivityID": 197015,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Gulgong District Hospital",
      "page": "Misc",
      "ID": "e48d99cc-b721-4254-a86c-68c9896d1d89",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CO",
      "priority": null,
      "source": "stafflink",
      "activityName": "GLG Pharmacy sales - HFO",
      "receivableActivityID": 197016,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments - HFO",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Gulgong District Hospital",
      "page": "Misc",
      "ID": "80bde229-2490-4e1b-a9aa-9f6fbf082176",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "RH",
      "priority": null,
      "source": "stafflink",
      "activityName": "GLG Room hire",
      "receivableActivityID": 197017,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Room Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Narromine District Hospital",
      "page": "Misc",
      "ID": "2501de09-ed96-4bf3-9e1c-341b774f79fc",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "NRM Medical records (no subpoena)",
      "receivableActivityID": 197021,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Narromine District Hospital",
      "page": "Misc",
      "ID": "08bb58be-94e6-4770-8e43-01d18844af61",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "NRM Medical records (subpoena)",
      "receivableActivityID": 198009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Narromine District Hospital",
      "page": "Misc",
      "ID": "6bee06fb-7490-47a0-ad60-e3d4c5ca948a",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "NRM Pharmacy sales (837110)",
      "receivableActivityID": 198010,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bourke Multi-Purpose Service",
      "page": "Misc",
      "ID": "7e43d26d-0c8a-42fa-a130-ec5e3a2438a6",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRK Crutches - sale",
      "receivableActivityID": 199010,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bourke Multi-Purpose Service",
      "page": "Misc",
      "ID": "318b59a7-b763-452c-bf6a-0f2f6bc6ca89",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRK Medical records (no subpoena)",
      "receivableActivityID": 199011,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bourke Multi-Purpose Service",
      "page": "Misc",
      "ID": "05c0e9ff-6b7d-4d7f-ac18-827347cac13c",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRK Medical records (subpoena)",
      "receivableActivityID": 199012,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bourke Multi-Purpose Service",
      "page": "Misc",
      "ID": "59348192-5e08-4f50-a6bf-e4f7c8c01114",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "MF",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRK Mortuary fees",
      "receivableActivityID": 199013,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Mortuary fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bourke Multi-Purpose Service",
      "page": "Misc",
      "ID": "2f55348d-946f-4824-8d95-c8cfed6cf40c",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRK Pharmacy sales (834110)",
      "receivableActivityID": 199014,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bourke Multi-Purpose Service",
      "page": "Misc",
      "ID": "42af69b8-77e5-4852-9661-7a74fcc7ed38",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CO",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRK Pharmacy sales - HFO",
      "receivableActivityID": 199015,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments - HFO",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bourke Multi-Purpose Service",
      "page": "Misc",
      "ID": "8b8b7965-5d0b-474c-a344-2c5116f11ef5",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRK Staff accommodation",
      "receivableActivityID": 199016,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Gilgandra Multi-Purpose Service",
      "page": "Misc",
      "ID": "c18166f6-09ae-4d72-ac27-d624807105c7",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CT",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIL Bus hire community transport",
      "receivableActivityID": 199017,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Community Transport",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Gilgandra Multi-Purpose Service",
      "page": "Misc",
      "ID": "def3d6ba-c755-4132-903c-70901fe6ac37",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "TS",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIL Bus hire other",
      "receivableActivityID": 199018,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Transport Services",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Gilgandra Multi-Purpose Service",
      "page": "Misc",
      "ID": "41691e55-ccf2-428c-88fb-d62a5fa356fb",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DM",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIL Day care meals",
      "receivableActivityID": 199019,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Day Care Meals",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Gilgandra Multi-Purpose Service",
      "page": "Misc",
      "ID": "6032a9b9-1669-4f7b-9f96-17f87d7ffd68",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIL Physio sales",
      "receivableActivityID": 200011,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Gilgandra Multi-Purpose Service",
      "page": "Misc",
      "ID": "158dd4b7-eb46-44b6-8cb8-ea35f4f0c4de",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIL Medical records (no subpoena)",
      "receivableActivityID": 200012,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Gilgandra Multi-Purpose Service",
      "page": "Misc",
      "ID": "2f9e9ef7-ec2e-4abb-bb28-850257f45d6e",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIL Medical records (subpoena)",
      "receivableActivityID": 200013,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Gilgandra Multi-Purpose Service",
      "page": "Misc",
      "ID": "2067a0b8-1d2d-4d16-b7ae-dff79e8dcb7c",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIL Pharmacy sales (813110)",
      "receivableActivityID": 200014,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Gilgandra Multi-Purpose Service",
      "page": "Misc",
      "ID": "543f8d41-d076-447e-b863-f98c8fbc6881",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CO",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIL Pharmacy sales - HFO",
      "receivableActivityID": 200015,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments - HFO",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Grenfell Multi-Purpose Service",
      "page": "Misc",
      "ID": "750699ad-313b-47b3-9f9a-88cfb861185a",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "GRF Medical records (no subpoena)",
      "receivableActivityID": 200020,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Grenfell Multi-Purpose Service",
      "page": "Misc",
      "ID": "9af9eca4-72aa-4e64-ac52-f6bfc2b44f18",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "GRF Medical records (subpoena)",
      "receivableActivityID": 200021,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Grenfell Multi-Purpose Service",
      "page": "Misc",
      "ID": "d09f4fde-d079-4eb4-bddc-6282b6f61939",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "GRF Pharmacy sales (858110)",
      "receivableActivityID": 200022,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Grenfell Multi-Purpose Service",
      "page": "Misc",
      "ID": "245737db-82b0-44fb-bae6-fb9059eedc4a",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CO",
      "priority": null,
      "source": "stafflink",
      "activityName": "GRF Pharmacy sales - HFO",
      "receivableActivityID": 200023,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments - HFO",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Trangie Multi-Purpose Service",
      "page": "Misc",
      "ID": "445108dd-63fb-4b12-9ce2-997cf7246782",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "MW",
      "priority": null,
      "source": "stafflink",
      "activityName": "TRG Meals on wheels",
      "receivableActivityID": 200026,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals on wheels",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Trangie Multi-Purpose Service",
      "page": "Misc",
      "ID": "60ed3acf-2294-4c21-84f8-c5b3e452aa4b",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "TRG Medical records (no subpoena)",
      "receivableActivityID": 200027,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Trangie Multi-Purpose Service",
      "page": "Misc",
      "ID": "65ac2913-ea04-446b-8e2e-3c064e7c6ecd",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "TRG Medical records (subpoena)",
      "receivableActivityID": 200028,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Trangie Multi-Purpose Service",
      "page": "Misc",
      "ID": "b8c78616-1fea-4a15-a3b9-54a34e2a7fe4",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "TRG Pharmacy sales (840110)",
      "receivableActivityID": 200029,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Trangie Multi-Purpose Service",
      "page": "Misc",
      "ID": "b233a503-d5c3-4f83-9e5b-7b09a9d3c323",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CO",
      "priority": null,
      "source": "stafflink",
      "activityName": "TRG Pharmacy sales - HFO",
      "receivableActivityID": 200030,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments - HFO",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Trangie Multi-Purpose Service",
      "page": "Misc",
      "ID": "ede38069-93f4-49f4-ba48-195d25994ccf",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "TRG Equipment sales - sched 3 medical equip",
      "receivableActivityID": 200031,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "386cec9c-c3c3-4871-9cfa-7bb458eeb547",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training such as antenatal classes and diabetes courses.",
      "prefix": "ED",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Antenatal classes",
      "receivableActivityID": 206009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Education/Training",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "84b736df-cf74-4cc4-9f4e-28c146c80c2c",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AM",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Boarder - maternity",
      "receivableActivityID": 206010,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Maternity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "f22676df-3b64-4146-8a44-d2545fe0917c",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Equip sales - sched 3 (ED/A&E)",
      "receivableActivityID": 206012,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "d2e4edc7-a511-4999-bcc3-4564728993cf",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for TV Rental",
      "prefix": "TV",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Commissions - TV hire",
      "receivableActivityID": 207009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "TV Rental",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "b233ecba-9747-4f7b-958f-f6eddd95bdd4",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "VR",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Vaccination records",
      "receivableActivityID": 207010,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Vaccination Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "7fc05964-f925-49b9-91b1-23c3acb79d25",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Medical records (no subpoena)",
      "receivableActivityID": 207011,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "1177ad36-1df4-42a2-810e-8ab4eb7a473a",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CH",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Pharmacy sales (829251)",
      "receivableActivityID": 207012,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments - HCD",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "fe6ae076-af4c-47ed-89e1-14351a806193",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Medical records (subpoena)",
      "receivableActivityID": 207013,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "67c28123-9874-4262-888b-d9f651a7ce61",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Pharmacy sales (829250)",
      "receivableActivityID": 207015,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "05704094-9c78-431f-ab72-6a2b6e14342c",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Plaster/cast tape (Physio)",
      "receivableActivityID": 207016,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "1db42053-56c2-4f44-8a03-628f5afe840d",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Physio sales - sched 3 medical equip",
      "receivableActivityID": 207017,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "4ee1332d-b2d3-4a92-951b-bf917f3020b3",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "MT",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Tuberculin skin test (TST/Mantoux)",
      "receivableActivityID": 207019,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Mantoux Screening Test",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Collarenebri Multi-Purpose Service",
      "page": "Misc",
      "ID": "d82eeb0c-1dd5-4c28-ba76-d6dc8f9e2de2",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLB Crutches - sale",
      "receivableActivityID": 207020,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Collarenebri Multi-Purpose Service",
      "page": "Misc",
      "ID": "3721fad2-cee8-4eaa-88f3-84c53d126ce5",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "MW",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLB Meals on wheels",
      "receivableActivityID": 207023,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals on wheels",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Collarenebri Multi-Purpose Service",
      "page": "Misc",
      "ID": "1ae5bd5d-ac57-4721-8495-32782cf7c6d3",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLB Medical records (no subpoena)",
      "receivableActivityID": 207024,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Collarenebri Multi-Purpose Service",
      "page": "Misc",
      "ID": "18cbf55d-3f94-4e07-83cb-42447e9ac59b",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLB Medical records (subpoena)",
      "receivableActivityID": 207025,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Collarenebri Multi-Purpose Service",
      "page": "Misc",
      "ID": "6ff3afed-996c-44c6-b4f6-081868967eda",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLB Pharmacy sales (810110)",
      "receivableActivityID": 207026,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Collarenebri Multi-Purpose Service",
      "page": "Misc",
      "ID": "3c15e109-7381-4022-9852-9b1d48675aa5",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLB Physio sales - sched 3 medical equip",
      "receivableActivityID": 207028,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Collarenebri Multi-Purpose Service",
      "page": "Misc",
      "ID": "c5787801-68ab-42ea-96aa-90423c545e79",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "RH",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLB Room hire",
      "receivableActivityID": 207029,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Room Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Collarenebri Multi-Purpose Service",
      "page": "Misc",
      "ID": "43f30830-579a-4023-b7fe-5c6bebfc2e81",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLB Sale old wares",
      "receivableActivityID": 207030,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Collarenebri Multi-Purpose Service",
      "page": "Misc",
      "ID": "3e564816-2004-410a-918a-8de06a576cc1",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLB Staff meals",
      "receivableActivityID": 207031,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Wellington Hospital, Bindawalla",
      "page": "Misc",
      "ID": "7ce39cce-ac8f-417b-860c-f1e333dc88a6",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "WLG Staff meals",
      "receivableActivityID": 207034,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coonabarabran District Hospital",
      "page": "Misc",
      "ID": "3b87fc80-325c-4adf-b6a8-d1f7a6982173",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EF",
      "priority": null,
      "source": "stafflink",
      "activityName": "CBB Plaster/cast (Physio)",
      "receivableActivityID": 207037,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Fracture Clinic",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coolah Multi-Purpose Service",
      "page": "Misc",
      "ID": "d6430e98-b980-403f-bc92-e8691410f7b2",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLH Crutches - sale",
      "receivableActivityID": 207038,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coolah Multi-Purpose Service",
      "page": "Misc",
      "ID": "3e660cd2-111e-407f-bd04-502de45e239b",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLH Meals",
      "receivableActivityID": 207041,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coolah Multi-Purpose Service",
      "page": "Misc",
      "ID": "ffc205ac-51e9-4519-a8ed-b06eb4da1bbf",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLH Pharmacy sales (818110)",
      "receivableActivityID": 207042,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coolah Multi-Purpose Service",
      "page": "Misc",
      "ID": "3df82e51-4b13-401a-84d2-aac2713abea4",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CO",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLH Pharmacy sales - HFO",
      "receivableActivityID": 207043,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments - HFO",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coolah Multi-Purpose Service",
      "page": "Misc",
      "ID": "1c321cb8-306f-4725-8d28-e9799fde6442",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLH Staff accommodation",
      "receivableActivityID": 207044,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coolah Multi-Purpose Service",
      "page": "Misc",
      "ID": "088d6972-05c2-4cbb-bf3e-18efd7814092",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLH Equipment hire - sched 3 medical equip",
      "receivableActivityID": 207045,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coolah Multi-Purpose Service",
      "page": "Misc",
      "ID": "d49dabd5-8c35-4cae-acbf-627a9981b7ad",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLH Equipment sales - sched 3 medical equip",
      "receivableActivityID": 207046,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Gilgandra Multi-Purpose Service",
      "page": "Misc",
      "ID": "d66359ea-9610-45b9-9645-a46e13d72896",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIL Staff accommodation",
      "receivableActivityID": 207050,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Mudgee District Hospital",
      "page": "Misc",
      "ID": "a973a103-bd3d-4b47-8140-8202aa36a809",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "MDG Equip sales - sched 3 (A&E)",
      "receivableActivityID": 207054,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Mudgee District Hospital",
      "page": "Misc",
      "ID": "e9c89609-e6f8-453d-8adf-f83abfd5f523",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "MDG Equip sales - sched 3 (Physio)",
      "receivableActivityID": 207055,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Mudgee District Hospital",
      "page": "Misc",
      "ID": "bebd7ffe-204f-45fb-91d4-10d2e06fa321",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "MDG Medical records (no subpoena)",
      "receivableActivityID": 207057,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Mudgee District Hospital",
      "page": "Misc",
      "ID": "a3a5e41e-3c13-4076-8425-e88b8ea41f40",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "MDG Medical records (subpoena)",
      "receivableActivityID": 207058,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Mudgee District Hospital",
      "page": "Misc",
      "ID": "9912a4a0-a16c-4005-b9b3-2dab0b6f8eb4",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "MDG Pharmacy sales (823250)",
      "receivableActivityID": 207061,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Oberon Multi-Purpose Service",
      "page": "Misc",
      "ID": "577cd479-ab33-4cc8-b0a5-ebcde3891b96",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "OBN Staff accomm",
      "receivableActivityID": 227012,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Trangie Multi-Purpose Service",
      "page": "Misc",
      "ID": "af4f4b19-5422-448f-b4cc-bbb377900ee4",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "TRG Staff meals",
      "receivableActivityID": 227013,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Trangie Multi-Purpose Service",
      "page": "Misc",
      "ID": "51dd7ef6-03ab-40c7-8cfb-d8077f9e0b11",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DM",
      "priority": null,
      "source": "stafflink",
      "activityName": "TRG Day care meals",
      "receivableActivityID": 227014,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Day Care Meals",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Menindee Health Service",
      "page": "Misc",
      "ID": "3762938b-a5ff-49c8-9a9c-c1bddfd217bc",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "MHS Gosling Creek accomm",
      "receivableActivityID": 227019,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Lightning Ridge Multi-Purpose Service",
      "page": "Misc",
      "ID": "2b1aaf6f-c0e3-40e8-8eb4-a539897f8930",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EM",
      "priority": null,
      "source": "stafflink",
      "activityName": "LRH Dressings - sale",
      "receivableActivityID": 244009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Medical Ward",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Blayney Multi-Purpose Service",
      "page": "Misc",
      "ID": "2049fc89-3543-456e-9d1b-85b5acdc1966",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "BLY Crutches sale",
      "receivableActivityID": 249016,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Brewarrina Multi-Purpose Service",
      "page": "Misc",
      "ID": "81dd4d8f-ab8d-4d77-80bf-3105a9da4ba0",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRW Meals - staff/visitors",
      "receivableActivityID": 249021,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Lightning Ridge Multi-Purpose Service",
      "page": "Misc",
      "ID": "767daedb-2b27-4076-938d-967685123fdc",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "LRH Physio sales",
      "receivableActivityID": 249022,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dubbo Health Service",
      "page": "Misc",
      "ID": "7e709876-da03-4dbd-a60a-c1841c02bb58",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBO Pharmacy sales - HFO (819137)",
      "receivableActivityID": 251010,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dubbo Health Service",
      "page": "Misc",
      "ID": "22ae1ed2-fcd8-42bf-ba75-767518d8173d",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBO Cast (Physio O/P)",
      "receivableActivityID": 251011,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dubbo Health Service",
      "page": "Misc",
      "ID": "e11d095b-16f5-43ab-83ed-7fa3168760d6",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SW",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBO Sale old wares",
      "receivableActivityID": 251021,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Sales of Old Wares",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dubbo Health Service",
      "page": "Misc",
      "ID": "e01df896-fb22-406a-935c-9f073b54957b",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBO Medical records (no subpoena)",
      "receivableActivityID": 251022,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dubbo Health Service",
      "page": "Misc",
      "ID": "ad01711a-6ba3-4d38-8069-087ff444afa9",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBO Medical records (subpoena)",
      "receivableActivityID": 251023,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dubbo Health Service",
      "page": "Misc",
      "ID": "cd6ca65d-9cf8-4aba-aada-65a9f9168f1b",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBO OT sales - sched 3 medical equip",
      "receivableActivityID": 252009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dubbo Health Service",
      "page": "Misc",
      "ID": "72bd9de4-0d7b-4d15-9d1a-f9c99918c110",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBO Physio O/P sales - sched 3 medical equip",
      "receivableActivityID": 252010,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dubbo Health Service",
      "page": "Misc",
      "ID": "ea47b0d9-b708-4dd5-b442-3d451338ac7d",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBO ED/A&E sales - sched 3 medical equip",
      "receivableActivityID": 252011,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dubbo Health Service",
      "page": "Misc",
      "ID": "64f28dae-1ff0-4d67-aad8-8811a9ad0f78",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBO Accommodation - Tooraweenah",
      "receivableActivityID": 252012,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dubbo Health Service",
      "page": "Misc",
      "ID": "1bddf5b0-d34f-44ce-9f43-d803102895e3",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CG",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBO Staff cafeteria",
      "receivableActivityID": 252013,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Cafeteria - Sales with GST",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bathurst Base Hospital",
      "page": "Misc",
      "ID": "c4bd81a4-1880-46e9-8eb1-b2068a9e3db3",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for use of the hydrotherapy pool including group classes, private bookings and treatments.",
      "prefix": "HY",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTH Hydrotherapy pool",
      "receivableActivityID": 252014,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Hydrotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Condobolin District Hospital",
      "page": "Misc",
      "ID": "bd54c5fc-a17b-4b28-8682-0498d60b3963",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "CND Staff accommodation",
      "receivableActivityID": 253011,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Condobolin District Hospital",
      "page": "Misc",
      "ID": "0d52f132-0d86-459e-9624-c909ce5f6d56",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "IL",
      "priority": null,
      "source": "stafflink",
      "activityName": "CND ILC meals",
      "receivableActivityID": 253012,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Independent Living Centre meals",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Condobolin District Hospital",
      "page": "Misc",
      "ID": "e925c0a9-fa6d-4a4f-9966-49f5698517ea",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "CND Medical records (no subpoena)",
      "receivableActivityID": 254009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Condobolin District Hospital",
      "page": "Misc",
      "ID": "8926f4fe-5f30-49d3-af4a-b2288e242149",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "CND Medical records (subpoena)",
      "receivableActivityID": 254010,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Condobolin District Hospital",
      "page": "Misc",
      "ID": "cfd6499a-ef85-4d7f-be8d-d4aa159309b9",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "CND Physio equipment hire",
      "receivableActivityID": 254011,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Canowindra Soldiers' Memorial Hospital",
      "page": "Misc",
      "ID": "b55281eb-a25e-4cc8-8819-0e80b68fea69",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "MC",
      "priority": null,
      "source": "stafflink",
      "activityName": "CNW Methadone",
      "receivableActivityID": 254014,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Methadone Clinic",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Canowindra Soldiers' Memorial Hospital",
      "page": "Misc",
      "ID": "cb524a8f-6af9-4425-8b9f-ff6f494cda89",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "CNW Plaster/cast (Physio)",
      "receivableActivityID": 254016,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Canowindra Soldiers' Memorial Hospital",
      "page": "Misc",
      "ID": "470d1eab-7c3e-4ade-b697-8cf355da8c27",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "CNW Physio equipment hire",
      "receivableActivityID": 254017,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Canowindra Soldiers' Memorial Hospital",
      "page": "Misc",
      "ID": "ece15522-9af9-41ac-900c-4b1c8fa873aa",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "CNW Physio sales - sched 3 medical equip",
      "receivableActivityID": 254018,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Nyngan Multi-Purpose Service",
      "page": "Misc",
      "ID": "3c15bbb0-ad57-4e7f-b65b-ac5979e6197e",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "RH",
      "priority": null,
      "source": "stafflink",
      "activityName": "NYG Room hire",
      "receivableActivityID": 254019,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Room Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Nyngan Multi-Purpose Service",
      "page": "Misc",
      "ID": "8b8e12f2-4e6c-4e91-8146-d72ce46800cc",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "NGY Physio Sales Sched3 Med Equip cc838648",
      "receivableActivityID": 254022,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Narromine District Hospital",
      "page": "Misc",
      "ID": "2e5cd5e8-2d22-4988-891f-81ffb4bd36d7",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "NRM Crutches - sale",
      "receivableActivityID": 254025,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Canowindra Soldiers' Memorial Hospital",
      "page": "Misc",
      "ID": "596beab0-d5a5-4ae4-a44c-622aa42f5f53",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "CNW Medical records (no subpoena)",
      "receivableActivityID": 254026,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Canowindra Soldiers' Memorial Hospital",
      "page": "Misc",
      "ID": "b360a049-6663-4ed4-affb-764a70272d65",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "CNW Medical records (subpoena)",
      "receivableActivityID": 254027,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bathurst Base Hospital",
      "page": "Misc",
      "ID": "8a838b51-9beb-48a1-9cb2-576e8bf257e2",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Tuberculosis (TB) chest clinic services.",
      "prefix": "TB",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTH TB Clinic",
      "receivableActivityID": 281010,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "TB Chest Clinic",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dubbo Health Service",
      "page": "Misc",
      "ID": "cc7027cd-83eb-4e1a-8ee6-caac8c7b2317",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DT",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBO Enteral Nutrition income",
      "receivableActivityID": 281011,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Dietetics / Nutritional Supplements",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "50958d68-ebe4-4362-9656-bf49eb4d3ca2",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CO",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Pharmacy sales (829137)",
      "receivableActivityID": 281012,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments - HFO",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "dcc249c3-b3cc-4e5a-baff-b8e0087151cd",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Staff accommodation",
      "receivableActivityID": 281013,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "be9d93ba-8848-4e4c-8eb3-2ca0a16715af",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AB",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Bloomfield accomm",
      "receivableActivityID": 281014,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Bloomfield",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "407b5f30-12c0-4ee0-9759-42c1877f95ef",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HO",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Equip hire - OT (sched 3 medical equip)",
      "receivableActivityID": 281015,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "a984af0c-64a1-4fe7-80c2-f6453ad49fb8",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EO",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG OT sales - OT (sched 3 medical equip)",
      "receivableActivityID": 281016,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Occupational Therapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "0ac2fdaf-5cd5-48fb-bc5a-163f699d67d9",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "KB",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG kids booklet - sale",
      "receivableActivityID": 281017,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Sales - Kids Booklet",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dubbo Health Service",
      "page": "Misc",
      "ID": "4059f9ad-7d04-4ab8-962d-4b97db381eb4",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for radiology services for outpatients",
      "prefix": "RF",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBO Radiology CD",
      "receivableActivityID": 309009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Radiology Fee",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dubbo Health Service",
      "page": "Misc",
      "ID": "d43c8ad2-f1bb-4235-921c-31fdbd596194",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AS",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBO Rent Drs Flats",
      "receivableActivityID": 309010,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Staff",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dubbo Health Service",
      "page": "Misc",
      "ID": "0bad3c15-52f7-4528-b487-ab9a323beb93",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBO Resp Equip Hire",
      "receivableActivityID": 309012,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Narromine District Hospital",
      "page": "Misc",
      "ID": "b28a4341-d928-4ebd-aeff-54c11a0fb53d",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "NRM Schedule 3 Medical Aids",
      "receivableActivityID": 309015,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Narromine District Hospital",
      "page": "Misc",
      "ID": "61f2fef5-b352-4926-a5d7-519e0a536dd7",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "NRM Meals",
      "receivableActivityID": 309016,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coonamble District Hospital",
      "page": "Misc",
      "ID": "4c105e05-5503-4fa5-b267-f7a38ead0b79",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "CNM Crutches Sales",
      "receivableActivityID": 310009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Cowra District Hospital",
      "page": "Misc",
      "ID": "8044ee46-67dc-4ac1-9cfa-4dc2cce588b4",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CWR Physio Sales",
      "receivableActivityID": 312009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dubbo Health Service",
      "page": "Misc",
      "ID": "0a3586da-9bec-4460-bed2-1f2a34ea203f",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CC",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBO Medical Council",
      "receivableActivityID": 324009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Contract Catering",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Brewarrina Multi-Purpose Service",
      "page": "Misc",
      "ID": "14ba80ef-3cc0-4594-ac72-2ef0eadab321",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRW Crutches Sales",
      "receivableActivityID": 329009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Mudgee District Hospital",
      "page": "Misc",
      "ID": "00f5d0e0-1c48-4316-b513-45760a69846b",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals and accommodation for Junior Medical Officers",
      "prefix": "MA",
      "priority": null,
      "source": "stafflink",
      "activityName": "MDG Staff Meals",
      "receivableActivityID": 336009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals and Accommodation - JMO, Nurses, Non-Patient",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Mudgee District Hospital",
      "page": "Misc",
      "ID": "1696f49d-173c-44d8-b855-503a5e2f574e",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "MDG Non-staff Meals",
      "receivableActivityID": 336010,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Peak Hill Hospital",
      "page": "Misc",
      "ID": "413e219d-4308-4f9f-afb7-3a2d167d452d",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "PKH Equip Hire",
      "receivableActivityID": 341009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Collarenebri Multi-Purpose Service",
      "page": "Misc",
      "ID": "9e1dc029-14c5-41f1-b9df-ee202a2e7836",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "RI",
      "priority": null,
      "source": "stafflink",
      "activityName": "CLB Rent",
      "receivableActivityID": 343009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Rental Income",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Brewarrina Multi-Purpose Service",
      "page": "Misc",
      "ID": "9b675ee8-5528-40a6-bfb6-b621e234effe",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "AR",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRW RENTAL INCOME",
      "receivableActivityID": 344009,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Rental",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Narromine District Hospital",
      "page": "Misc",
      "ID": "b023f716-d21b-4cbe-9c5b-bc0bc653785c",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "TS",
      "priority": null,
      "source": "stafflink",
      "activityName": "NRM Bus",
      "receivableActivityID": 372010,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Transport Services",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dubbo Health Service",
      "page": "Misc",
      "ID": "de092c10-417e-4b74-a1ae-365385a04b51",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for podiatry products such as Orthotic insoles, foot braces and supports",
      "prefix": "PO",
      "priority": null,
      "source": "stafflink",
      "activityName": "DBO Podiatry - High Risk Foot Category",
      "receivableActivityID": 394010,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Podiatry",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Coonabarabran District Hospital",
      "page": "Misc",
      "ID": "959fa50b-cf92-4846-9d3d-787eb1ad38a5",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "CBB Physio Sales",
      "receivableActivityID": 491023,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Cobar District Hospital",
      "page": "Misc",
      "ID": "8087137e-f0ec-4c0f-8a6e-8b4e9f5998e2",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "CBR Equipment - sale",
      "receivableActivityID": 491024,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Forbes District Hospital",
      "page": "Misc",
      "ID": "1f79a717-5713-41ea-833e-a101a2b80cc7",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "CH",
      "priority": null,
      "source": "stafflink",
      "activityName": "FRB Pharmacy sales - HCD",
      "receivableActivityID": 491025,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments - HCD",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Gilgandra Multi-Purpose Service",
      "page": "Misc",
      "ID": "68a50923-e860-4042-bbc7-201571e3f225",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for specialised medical equipment such as aids and equipment.",
      "prefix": "ME",
      "priority": null,
      "source": "stafflink",
      "activityName": "GIL Equipment - sale",
      "receivableActivityID": 491026,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Medical equipment sales - Schedule 3 (GST free)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Molong Multi-Purpose Service",
      "page": "Misc",
      "ID": "0792360a-2ac5-4c37-b1e7-0247da682700",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "MLG Equipment hire",
      "receivableActivityID": 491027,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Rylstone Multi-Purpose Service",
      "page": "Misc",
      "ID": "d333a465-e6f2-4305-b699-8206d269dec9",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "RYL Equipment sale",
      "receivableActivityID": 491028,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Wellington Hospital, Bindawalla",
      "page": "Misc",
      "ID": "917b4594-1980-4497-84c4-3a658376effd",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DO",
      "priority": null,
      "source": "stafflink",
      "activityName": "WLG Nursing Home Visits",
      "receivableActivityID": 491029,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Community Nursing",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bourke Multi-Purpose Service",
      "page": "Misc",
      "ID": "9a907b41-a888-46c7-9a68-90d01896c9e2",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for meals",
      "prefix": "MD",
      "priority": null,
      "source": "stafflink",
      "activityName": "BRK Meals",
      "receivableActivityID": 498066,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Meals - Staff and Visitor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Bathurst Base Hospital",
      "page": "Misc",
      "ID": "45d6979d-decb-4f74-bf5e-af3a470f23ee",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTH Equipment - sale (A&E)",
      "receivableActivityID": 498067,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Condobolin District Hospital",
      "page": "Misc",
      "ID": "4279f0c3-4856-4f48-ad6c-06e4b01304f6",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "EG",
      "priority": null,
      "source": "stafflink",
      "activityName": "CND Equipment Sales (Physio)",
      "receivableActivityID": 500026,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales - Physio (GST)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Dunedoo War Memorial Multi-Purpose Service",
      "page": "Misc",
      "ID": "5373e103-0ade-4d83-b344-4057774502eb",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "DND Equipment- sale",
      "receivableActivityID": 500027,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Nyngan Multi-Purpose Service",
      "page": "Misc",
      "ID": "0ea62212-0a22-49a8-879f-7a61974691eb",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "NYG Equipment Sales",
      "receivableActivityID": 500028,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Oberon Multi-Purpose Service",
      "page": "Misc",
      "ID": "ce4d6a9d-3b71-42d6-8650-539de02193fc",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "OBN Equipment - sales",
      "receivableActivityID": 500029,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "19afb9a5-9cdc-4466-930e-2bcea731fe99",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Gosling Creek accomm",
      "receivableActivityID": 500030,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Orange Health Service",
      "page": "Misc",
      "ID": "6a50c843-44ca-4790-8fc5-536262369374",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs",
      "prefix": "HP",
      "priority": null,
      "source": "stafflink",
      "activityName": "ORG Equip hire sched 3",
      "receivableActivityID": 500031,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Hire - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "Warren Multi-Purpose Service",
      "page": "Misc",
      "ID": "43de57f3-141f-4b11-b09f-ca5e92326df2",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "WRN Equipment - sale",
      "receivableActivityID": 500033,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western NSW Local Health District",
      "state": "NSW",
      "city": "ORANGE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "a7ff9013-8f87-4345-b4f3-b264c7922111",
      "postalCode": "2800",
      "address1": "Private Bag 6001",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "Miscellaneous",
      "receivableActivityID": 5647,
      "healthEntityCode": "WNSWLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "50 629 556 404",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "2007f20e-9f02-447a-aa83-e52b97999d7d",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase your medical report including a summary of injuries required for solicitor, insurance and personal purposes.",
      "prefix": "MR",
      "priority": null,
      "source": "stafflink",
      "activityName": "WSLHD Medical Records",
      "receivableActivityID": 415026,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Medical Records",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "5d98c12f-61c8-47aa-94a7-74f51486a6b0",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for Health Records under the compliance of subpeona.",
      "prefix": "SB",
      "priority": null,
      "source": "stafflink",
      "activityName": "WSLHD Subpoena",
      "receivableActivityID": 415027,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Subpoena, Health records management",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Auburn Hospital",
      "page": "Misc",
      "ID": "96bd0a22-f9fe-4f11-aca5-8894a1703647",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "AUB Chemist/Pharmacy Invoices",
      "receivableActivityID": 415028,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Blacktown Hospital",
      "page": "Misc",
      "ID": "422c5a93-9059-4805-975e-71cb3850c5de",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "BLK Chemist/Pharmacy Invoices",
      "receivableActivityID": 415029,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Mount Druitt Hospital",
      "page": "Misc",
      "ID": "e0d2d5ae-6d7b-4102-87cb-beb91e0c1458",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "MTD Chemist/Pharmacy Invoices",
      "receivableActivityID": 415030,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Westmead Hospital (all units)",
      "page": "Misc",
      "ID": "db6d4163-376d-4c52-9e1a-780ac1217b37",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medications received from a hospital pharmacy.",
      "prefix": "CP",
      "priority": null,
      "source": "stafflink",
      "activityName": "WMD Chemist/Pharmacy Invoices",
      "receivableActivityID": 415031,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Chemist and Pharmacy payments",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "f9a87877-acca-4158-b1cf-5ea6ceab1fed",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay to hire equipment such as support chairs, wheelchairs, oxygen cylinders etc.",
      "prefix": "EH",
      "priority": null,
      "source": "stafflink",
      "activityName": "WSLHD Equipment Hire",
      "receivableActivityID": 415032,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Equipment Hire",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "1f2e2fb1-c20f-4c9e-8c06-c927c02a366d",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "WSLHD Equipment Sales",
      "receivableActivityID": 415033,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Westmead Hospital (all units)",
      "page": "Misc",
      "ID": "32462cb6-f7c8-4977-8f0f-02a07d011349",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for use of the hydrotherapy pool including group classes, private bookings and treatments.",
      "prefix": "HY",
      "priority": null,
      "source": "stafflink",
      "activityName": "WMD Hydrotherapy Pool",
      "receivableActivityID": 415034,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Hydrotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Westmead Hospital (all units)",
      "page": "Misc",
      "ID": "b1684044-1bfd-45c4-9d30-a3a9a066464f",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for parking fees and services",
      "prefix": "PF",
      "priority": null,
      "source": "stafflink",
      "activityName": "Westmead Carparking Takings",
      "receivableActivityID": 415035,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Parking Fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Blacktown Hospital",
      "page": "Misc",
      "ID": "d6969d9f-9020-4afd-96bf-52ac5623a3d3",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for parking fees and services",
      "prefix": "PF",
      "priority": null,
      "source": "stafflink",
      "activityName": "Blacktown Carparking Takings",
      "receivableActivityID": 415036,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Parking Fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Mount Druitt Hospital",
      "page": "Misc",
      "ID": "3ca47b71-1460-4d11-bc6f-cd5a9970fcfe",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for parking fees and services",
      "prefix": "PF",
      "priority": null,
      "source": "stafflink",
      "activityName": "Mt Druitt Carparking Takings",
      "receivableActivityID": 415037,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Parking Fees",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Westmead Hospital (all units)",
      "page": "Misc",
      "ID": "58fcd0cb-9a39-45fb-8722-503f110db8a8",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for exercise classes.",
      "prefix": "EC",
      "priority": null,
      "source": "stafflink",
      "activityName": "BCI Yoga Classes",
      "receivableActivityID": 415038,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Exercise Classes",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Westmead Hospital (all units)",
      "page": "Misc",
      "ID": "3bb2d31e-9a07-45c0-b5eb-bb9a52ecb98e",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "Westmead Physio",
      "receivableActivityID": 415039,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Blacktown Hospital",
      "page": "Misc",
      "ID": "b9803b28-058b-40e7-8ad9-c157aa3c8dce",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "Blacktown Physio",
      "receivableActivityID": 415040,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Mount Druitt Hospital",
      "page": "Misc",
      "ID": "6c5cc537-42f2-4c44-842a-4d74be0e598b",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "Mt Druitt Physio",
      "receivableActivityID": 415041,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "856e9390-a790-444a-9774-42357fd2a750",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "GIPA application fee for access to information held by the Health Organisation, excluding health records managed by facilities",
      "prefix": "GF",
      "priority": null,
      "source": "stafflink",
      "activityName": "WSLHD GIPA Processing Fees",
      "receivableActivityID": 415042,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "GIPA - Government Information (Public Access)",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Westmead Hospital (all units)",
      "page": "Misc",
      "ID": "84abefb6-5ed9-4908-a4a5-a02a9066b8b4",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation for a patient, relative or contractor.",
      "prefix": "AC",
      "priority": null,
      "source": "stafflink",
      "activityName": "WMD Emergency Accom",
      "receivableActivityID": 415043,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Accommodation - Patient/relative/contractor",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Westmead Hospital (all units)",
      "page": "Misc",
      "ID": "d59b8550-803e-4b7e-8728-00dae2c476f0",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for accommodation at Casurina Lodge",
      "prefix": "CL",
      "priority": null,
      "source": "stafflink",
      "activityName": "Casuarina Lodge Accom",
      "receivableActivityID": 484014,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Accommodation - Casuarina Lodge",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Blacktown Hospital",
      "page": "Misc",
      "ID": "5e6fa49d-26aa-49fe-aded-3ce4b5831286",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay deposit for Staff ID card",
      "prefix": "CD",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTN ID Card Refundable",
      "receivableActivityID": 484015,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Staff ID Card Deposit",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Auburn Hospital",
      "page": "Misc",
      "ID": "5dc5cbc5-7bc8-48e5-8aa5-9589d1ddb546",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for medical imaging USB",
      "prefix": "MI",
      "priority": null,
      "source": "stafflink",
      "activityName": "AUB Imaging USB",
      "receivableActivityID": 484016,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Medical Imaging USB",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Blacktown Hospital",
      "page": "Misc",
      "ID": "2ae8edbf-06be-41ca-b195-882e15e50536",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for food stalls",
      "prefix": "SF",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTN Food Stalls",
      "receivableActivityID": 484017,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Sales - Food Stalls",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Mount Druitt Hospital",
      "page": "Misc",
      "ID": "7fb52847-e41f-4795-82ca-a746078cf1a4",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "SK",
      "priority": null,
      "source": "stafflink",
      "activityName": "MTD Kiosk Sales",
      "receivableActivityID": 484018,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Sales - Kiosk",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Auburn Hospital",
      "page": "Misc",
      "ID": "289713f8-c982-4bf8-ba4d-4e7a40cd5355",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EP",
      "priority": null,
      "source": "stafflink",
      "activityName": "AUB Physio",
      "receivableActivityID": 484019,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Equipment Sales - Physiotherapy",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Blacktown Hospital",
      "page": "Misc",
      "ID": "a428b263-7b55-4bd3-bdf9-6f5b097463e6",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTN A&E Sales",
      "receivableActivityID": 484020,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Auburn Hospital",
      "page": "Misc",
      "ID": "8b794308-3b9f-40a4-a908-2813667d4af2",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "AUB A&E Sales",
      "receivableActivityID": 484021,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Mount Druitt Hospital",
      "page": "Misc",
      "ID": "4dd4f184-5754-477b-9f98-b6097e09193a",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay deposit for Staff ID card",
      "prefix": "CD",
      "priority": null,
      "source": "stafflink",
      "activityName": "MTD ID Card Refundable",
      "receivableActivityID": 484022,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Staff ID Card Deposit",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Mount Druitt Hospital",
      "page": "Misc",
      "ID": "7ffe12a1-147a-4fd0-8565-08941e1b6aeb",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars, braces and breast pumps etc.",
      "prefix": "ES",
      "priority": null,
      "source": "stafflink",
      "activityName": "MTD Rehab Equip Sales",
      "receivableActivityID": 484023,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Equipment Sales",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "aeb4d4c5-5d3b-487a-9045-d68a1cc75de1",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Pay for education and training courses",
      "prefix": "CO",
      "priority": null,
      "source": "stafflink",
      "activityName": "Courses and Conferences",
      "receivableActivityID": 486013,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Course",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Westmead Hospital (all units)",
      "page": "Misc",
      "ID": "bb276a62-358e-4010-ae10-091698cb192f",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": "Purchase equipment such as crutches, walking frames, casts, collars and braces etc",
      "prefix": "EE",
      "priority": null,
      "source": "stafflink",
      "activityName": "WMD A&E Sales",
      "receivableActivityID": 498030,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Equipment Sales - Emergency",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Blacktown Hospital",
      "page": "Misc",
      "ID": "ca168477-e2f5-404a-8f28-e70a4df6cd2c",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DS",
      "priority": null,
      "source": "stafflink",
      "activityName": "BTN Dialysis Supplement",
      "receivableActivityID": 498031,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Dialysis Supplement",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "Y"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "Mount Druitt Hospital",
      "page": "Misc",
      "ID": "def3e5f7-e7d0-4944-b7fe-362c1caeafea",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "DO",
      "priority": null,
      "source": "stafflink",
      "activityName": "MTD Rehab Sales",
      "receivableActivityID": 498032,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Rehab Equipment",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "N"
    },
    {
      "status": "A",
      "country": "AU",
      "healthEntity": "Western Sydney Local Health District",
      "state": "NSW",
      "city": "WENTWORTHVILLE",
      "hospital": "All - Centralised Services",
      "page": "Misc",
      "ID": "de67c1f3-c088-4d29-9c9c-f370f5bcb85a",
      "postalCode": "2145",
      "address1": "PO Box 119",
      "address2": null,
      "address3": null,
      "address4": null,
      "activityNameDescription": null,
      "prefix": "OT",
      "priority": null,
      "source": "stafflink",
      "activityName": "Miscellaneous",
      "receivableActivityID": 61002,
      "healthEntityCode": "WSLHD",
      "activityNameDisplay": "Miscellaneous Activity",
      "category": "receivableactivities",
      "portal": "Y",
      "healthEntityABN": "48 702 394 764",
      "internalUseOnlyFlag": "Y"
    }
  ]