import clsx from 'clsx';

export function FilterContainer(props) {
  const { mobileOpen, handleToggle, ...others } = props;

  return (
    <div
      className="nsw-filters nsw-filters--fixed js-filters is-ready"
      {...others}
    >
      <div className="nsw-filters__controls nsw-filters__controls--active">
        <button onClick={handleToggle}>
          <i
            className="material-icons nsw-material-icons"
            tabIndex={-1}
            aria-hidden="true"
          >
            tune
          </i>
          <span>Filter results</span>
        </button>
      </div>
      <div
        className={clsx({
          'nsw-filters__wrapper': 1,
          'd-block': mobileOpen,
        })}
      >
        <div className="nsw-filters__back">
          <button onClick={handleToggle}>
            <i
              className="material-icons nsw-material-icons"
              tabIndex={-1}
              aria-hidden="true"
            >
              keyboard_arrow_left
            </i>
            <span>Back</span>
          </button>
        </div>
        <div className="nsw-filters__title">Filter results</div>
        <div className="nsw-filters__list">{props.children}</div>
      </div>
    </div>
  );
}

export function FilterItem(props) {
  return <div className="nsw-filters__item">{props.children}</div>;
}

export function SearchButton({ handleSearch }) {
  return (
    <div className="nsw-filters__accept">
      <button
        className="nsw-button nsw-button--dark nsw-button--full-width"
        onClick={handleSearch}
      >
        Search
      </button>
    </div>
  );
}

export function ResetButton({ handleReset }) {
  return (
    <div className="nsw-filters__cancel">
      <button type="reset" onClick={handleReset}>
        Clear all filters
      </button>
    </div>
  );
}
